import Vue from 'vue'
import Vuex from 'vuex'
import Cookies from '@/helpers/cookies'
const axios = require('axios');
import env from '@/../env.js';

Vue.use(Vuex);

const getSource = async function (sourceId) {
  const data = await axios.get(env.apiUrl + "/sources/" + sourceId);
  return data.data;
}

export default new Vuex.Store({
  state: {
    user: {},
    project: {},
    pageLoaded: false,
    events: [],
    showDownloadOptions: false,
    isFree: false,
    breadcrumbs: [],
    newBreadcrumbs: [],
    citations: [],
    citationIds: [],
    i: 0,
    cits: [],
    ipCodes: [],
    bibliographyData: [],
    bibliographyJson: [],
    certify2xUUID: '',
    isDomainValid: [],
    certPageAccess: "",
    status2xCertification: "",
    sectors: []
  },
  mutations: {
    setBibliographyData(state, data) {
      state.bibliographyJson = [];
      state.bibliographyJson = data;
    },
    setSectorsData(state, data) {
      state.sectors = data;
    },
    setCertify2xUUID(state, uuid) {
      state.certify2xUUID = uuid;
    },
    /* Set entire user object */
    setUser(state, user) {
      state.user = user;
    },
    setProject(state, data) {
      state.project = data;
      Cookies.set('project', data._id, 14);
    },
    logout() {
      // Trigger to remove the session
      localStorage.removeItem('deviceToken')
      Cookies.remove('session');
      document.location.reload();
    },
    pageLoad(state, status) {
      state.pageLoaded = status;
    },
    setBreadcrumbs(state, breadcrumbs) {
      state.breadcrumbs = breadcrumbs;
    },
     resetBreadcrumbs(state) {
      state.breadcrumbs = [];
    },
    setNewBreadcrumbs(state, data) {
      state.newBreadcrumbs = data;
    },
    downloadable(state, status) {
      state.showDownloadOptions = status;
    },
    isFreeState(state, status) {
      state.isFree = status;
    },
    resetCitations(state) {
      state.citations = [];
      state.citationIds = []
    },
    async addCitation(state, citation) {
      if (state.citationIds.indexOf(citation.placeId) == -1) {
        state.citationIds.push(citation.placeId);
        citation.rank = state.citationIds.indexOf(citation.placeId);
        const data = await getSource(citation.id);
        data.rank = citation.rank;
        data.cid = data._id;
        if (data.id != 'region' & data.id != 'income') {
          state.citations.push(data);
        }
      } else {
        citation.rank = state.citationIds.indexOf(citation.placeId)
      }
    },
    addCitationForRank(state, citation) {
      if (state.citationIds.indexOf(citation.placeId) == -1) {
        state.citationIds.push(citation.placeId);
        citation.rank = state.citationIds.indexOf(citation.placeId);
      } else {
        citation.rank = state.citationIds.indexOf(citation.placeId)
      }
    },
    addBibliographyData(state, data) {
      if (data) {
        state.bibliographyData.push(data);
      }
    },
    emptyBibliographyData(state) {
      state.bibliographyData = [];
    },
    reload(state) {
      state.pageLoaded = false;
      document.location.href = '/#/'
    },
    // This is an Async function because we always
    // want to make sure that the cookie is set before
    // continueing :)
    async setSession(state, session) {
      return new Promise(async (resolve) => {
        if (session) {
          Cookies.set('session', session, 14);
          resolve();
        } else {
          Cookies.remove('session');
          resolve();
        }
      })
    },
    /* Events & history */
    setEvents(state, events) {
      state.events = events;
    },
    addEvent(state, ev) {
      state.events.unshift(ev); // push it as first element.
    },
    async setLocalStorage(state, session) {
      return new Promise(async (resolve) => {
        if (session) {
          localStorage.setItem('deviceToken', session)
          resolve();
        }
      })
    },
    setIPCodes(state, ipCodes) {
      state.ipCodes = ipCodes;
    },
    setValidDomains(state, data) {
      state.isDomainValid = data.isDomainValid;
    },
    set2xCertificationStatus(state, data) {
      state.certPageAccess = data;
    },
    setCertPageStatus(state, status) {
      const twoxCertStatus = [
        { name: 'New Pilot Request', value: 0 },
        { name: 'Pilot Access Denied', value: 1 },
        { name: 'Pilot Access Approved', value: 2 },
        { name: 'Pilot Request Approved', value: 3 },
        { name: 'Min Survey Submitted', value: 4 },
        { name: 'Full Survey Submitted', value: 5 },
        { name: 'New Validation Request', value: 6 },
        { name: 'Validation Request Denied', value: 7 },
        { name: 'Validation Request Approved', value: 8 },
        { name: 'Validation in Process', value: 9 },
        { name: 'Validation Denies Certification', value: 10 },
        { name: 'Validation Recommends Certification', value: 11 },
        { name: 'Certification Approval in Process', value: 12 },
        { name: 'Certification Denied', value: 13 },
        { name: 'Certification Awarded', value: 14 },
      ]
      const currentCertStatus = twoxCertStatus.filter(data => data.name === status)?.[0] || [];
      state.certPageAccess = {
        isMinSurveyFill: currentCertStatus.value > 3,
        isFullSurveyFill: currentCertStatus.value > 4,
        isProceedToVerification: currentCertStatus.value >= 6,
        isUploadDocPage: currentCertStatus.value > 7,
        isVerificationPage: currentCertStatus.value > 9,
        isValidationDeniesCertification: currentCertStatus.value === 10,
        isValidationRecommendsCertification: currentCertStatus.value > 10,
        isGlobalPage: currentCertStatus.value === 14,
        isFullAssessmentPage: currentCertStatus.value > 4,
        isScoreCardPage: currentCertStatus.value === 14,
        isThemeBadgePage: currentCertStatus.value === 14,
        isSubDimensionPage: currentCertStatus.value > 4,
      };
    },
  },
  getters: {
    hasPurchasedCertificateCost(state) {
      return state?.certPageAccess?.isUploadDocPage &&
        !state?.certPageAccess?.isVerificationPage;
    }
  },
  actions: {
    async fetchIPCodes({ commit }) {
      try {
        const data = await axios.get(env.apiUrl + "/indicators/codes/getLPCodes", {
          headers: {
            'Authorization': 'Bearer ' + Cookies.get('session')
          }
        });
        commit('setIPCodes', data.data.data);
      } catch (error) {
        console.log("fetchIPCodes Error: ", error);
      }
    },
    emptyBibliographyData({ commit }) {
      commit('emptyBibliographyData');
    },
    async fetchValidDomain({ commit }) {
      try {
        const data = await axios.get(env.apiUrl + "/other/user-domain-valid", {
          headers: {
            'Authorization': 'Bearer ' + Cookies.get('session')
          }
        });
        commit('setValidDomains', data.data);
      } catch (error) {
        console.log("Error: ", error);
      }
    },
  }
});

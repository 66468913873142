<template>
  <div class="table-container">
    <table class="inner-table">
      <tr class="table-heading-row">
        <th v-for="head in heading" :key="head"> {{ head }}</th>
      </tr>
      <tr class="table-content-row" v-for="data in tableData" :key="data.rowHeading">
        <td>
          <div
            class="flex justify-between py-4 px-3 items-center"
            :class="{'font-bold ': ['Gross Profit', 'EBITDA', 'Net Profit' , 'Gross Profit (Loss)' , 'Total current assets' , 'Total Non-Current Assets'].includes(data.rowHeading),}"
          >
            {{ data.rowHeading }}
            <img v-if="data.isVisible" @click="data.isVisible = !data.isVisible"
                 class="cursor-pointer" alt="arrow-up" src="@/assets/images/arrow-up.svg" />
            <img v-if="!data.isVisible" @click="data.isVisible = !data.isVisible"
                 class="cursor-pointer" alt="arrow-down" src="@/assets/images/arrow-down.svg" />
          </div>
          <div class="difference-data py-4 px-3" v-if="data.isVisible">
            <div>Difference</div>
            <div class="mt-3">%</div>
          </div>
        </td>
        <td class="text-right" v-for="(data , index) in data.data" :key="index">
          <div class="py-4 px-3" style="height:64px">${{ data.value }}</div>
          <div class="difference-data py-4 px-3" v-if="data.isVisible">
            <div :class="data.class">+{{ data.diffPercent }}</div>
            <div class="mt-3" :class="data.class">{{ data.diffAmount }}</div>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  name: "SalesTable",
  props: {
    heading: {
      type: Array,
      required: true,
    },
    tableData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  }
};
</script>
<style lang="scss" scoped>
.table-container {
  gap: 20px;
  border-radius: 16px;
  border: 1px solid #E1E3E9;

  .inner-table {
    width: 100%;

    th, td {
      border: 1px solid #E1E3E9;
    }

    .table-heading-row {
      th {
        padding: 16px;
        gap: 32px;
        border-top: none;
        background: linear-gradient(0deg, #F4F6F9, #F4F6F9), linear-gradient(0deg, #E1E3E9, #E1E3E9);
        font-family: Arial;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: #2C2E35;
      }

      th:first-child {
        border-top-left-radius: 16px;
        border-left: none;
      }

      th:last-child {
        border-top-right-radius: 16px;
        border-right: none;
      }
    }

    .table-content-row {
      td {
        border-bottom: none;
        height: 68px;
        // padding: 16px 12px 16px 12px;
        gap: 1px;
        font-family: Arial;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        color: #2C2E35;

        .difference-data {
          background: linear-gradient(0deg, #F4F6F9, #F4F6F9), linear-gradient(0deg, #E1E3E9, #E1E3E9);
          border-top: 1px solid #E1E3E9;
        }

        img {
          width: 32px;
          height: 32px;
        }
      }

      td:first-child {
        border-left: none;
      }

      td:last-child {
        border-right: none;
      }

      .unaudit-section {
        span {
          gap: 8px;
        }
      }
    }
  }

  .red {
    color: #CB2E00;
  }

  .green {
    color: #13A094;
  }
}
</style>
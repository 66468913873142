<script>
import EquiloButton from "@/components/basics/EquiloButton.vue";

export default {
  name: "Paywall",
  data() {
    return {
      tooltipMessage: 'Are You Prepared for 2X Certification? Find Out Now with our Machine Learning Add-On!\nGathering documentation for verification can be cumbersome. Our advanced Machine Learning Add-On simplifies this task by automatically ensuring all necessary documents are ready and compliant for your certification journey. Proceed with confidence, knowing your documents meet the verification standards.',
      selectedPrices: [],
      showMLTooltip: false,
    };
  },
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
    },
    items: {
      type: Array,
      required: true
    }
  },
  emits: ["on-buy", "on-cancel"],
  components: {
    EquiloButton
  },
  computed: {
    totalPrice() {
      return this.selectedPrices.reduce((total, current) => {
        return total + current.price;
      }, 0);
    }
  },
  watch: {
    selectedPrices(newVal, oldVal) {
      let newSelectedPrices = newVal;
      const oldBasePrices = oldVal.filter((item) => item.addOnMLPriceId);

      // Check if the base prices are no longer part of the selected prices
      oldBasePrices.forEach((oldBasePrice) => {
        if (
          !newVal.find((newItem) => newItem.stripePriceId === oldBasePrice.stripePriceId) &&
          !!oldVal.find((oldItem) => oldItem.stripePriceId === oldBasePrice.stripePriceId)
        ) {
          newSelectedPrices = newVal.filter((i) => i.stripePriceId !== oldBasePrice.addOnMLPriceId);
        }
      });
      this.selectedPrices = newSelectedPrices;
    }
  },
  methods: {
    isBasePriceSelected(addOnMLPriceId) {
      return this.selectedPrices.find((price) => price.addOnMLPriceId === addOnMLPriceId);
    },
  },
  mounted() {
    // Check if the product has been purchased
    this.items.forEach((item) => {
      const price = item.prices[0];
      if (price.hasPurchased) {
        this.selectedPrices.push({
          stripePriceId: price?.stripePriceId,
          price: price?.price,
          addOnMLPriceId: price?.addOnPrice?.stripePriceId,
          basePriceId: null,
        });
      }

      // Check if the add-on product has been purchased
      const addOnPrice = price?.addOnPrice;
      if (addOnPrice?.hasPurchased) {
        this.selectedPrices.push({
          stripePriceId: addOnPrice?.stripePriceId,
          price: addOnPrice?.price,
          addOnMLPriceId: null,
          basePriceId: price?.stripePriceId,
        });
      }
    });
  }
};
</script>

<template>
  <div class="[ w-full h-full ]">
    <h3 class="font-bold text-2xl">{{ title }}</h3>
    <div class="[ w-4/5 ][ my-2 ]">
      <span>{{ subtitle }}</span>
    </div>
    <div class="[ w-full ]">
      <table class="w-full">
        <thead>
        <tr>
          <td colspan="3"></td>
          <td class="[ text-center font-bold ][ w-32 ]" colspan="1">
            Base
          </td>
          <td class="[ border-l-2 border-gray-400 p-2 ][ w-32 ]" colspan="1">
            <div class="[ flex justify-center items-center gap-2 ]">
              <span class="[ text-center font-bold ]">ML<br />Add-on</span>
              <div v-if="tooltipMessage" class="relative inline-block">
                <img
                  src="@/assets/images/dashboard/info.svg"
                  class="w-[1.25rem] h-[1.25rem] mr-[0.125rem]"
                  alt="info-icon"
                  @mouseover="showMLTooltip = true"
                  @mouseout="showMLTooltip = false"
                />
                <div
                  v-if="showMLTooltip"
                  class="[ w-32 ][ bg-blue-50 ][ p-4 ]"
                  :class="{'tooltip-visible': showMLTooltip, 'tooltip-hidden': !showMLTooltip}"
                >
                  <span class="text-sm">
                    {{ tooltipMessage }}
                  </span>
                </div>
            </div>
            </div>
          </td>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in items" :key="item.id">
          <td colspan="3" class="py-4">
            <div class="[ flex flex-col gap-1 ][ pr-8 ]">
              <h3 class="font-bold text-xl">{{ item.name }}</h3>
              <span class="[ text-sm text-pretty ]" v-html="item?.description ?? ''"></span>
            </div>
          </td>
          <td colspan="1">
            <div class="[ font-bold ][ flex items-center justify-center gap-1 ]">
              <span class="font-bold">${{ item.prices[0]?.price ?? 0 }}</span>
              <input
                type="checkbox"
                class="font-bold"
                :class="{'bg-grey-70': item.prices[0]?.hasPurchased}"
                :value="{
                  stripePriceId: item.prices[0]?.stripePriceId,
                  price: item.prices[0]?.price,
                  addOnMLPriceId: item.prices[0]?.addOnPrice?.stripePriceId,
                  basePriceId: null,
                }"
                v-model="selectedPrices"
                :disabled="item.prices[0]?.hasPurchased"
              />
            </div>
          </td>
          <td colspan="1" class="[ border-l-2 border-gray-400 p-2 ]">
            <div
              v-if="item?.prices[0]?.addOnPrice?.price"
              class="[ font-bold ][ flex items-center justify-center gap-1 ]"
            >
              <span class="font-bold">${{ item?.prices[0]?.addOnPrice?.price ?? 0 }}</span>
              <input
                type="checkbox"
                class="font-bold"
                :class="{'bg-grey-70': !isBasePriceSelected(item?.prices[0]?.addOnPrice?.stripePriceId)}"
                :value="{
                  stripePriceId: item?.prices[0]?.addOnPrice?.stripePriceId,
                  price: item?.prices[0]?.addOnPrice?.price,
                  addOnMLPriceId: null,
                  basePriceId: item.prices[0]?.stripePriceId,
                }"
                v-model="selectedPrices"
                :disabled="!isBasePriceSelected(item?.prices[0]?.addOnPrice?.stripePriceId) || item?.prices[0]?.addOnPrice?.hasPurchased"
              />
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="[ w-full ][ flex gap-4 justify-between ][ mt-8 ]">
      <div class="[ w-full ][ flex justify-between items-center ][ text-2xl font-bold ]">
        <h3>Total</h3>
        <h3>${{ totalPrice }}</h3>
      </div>
      <div class="flex gap-2">
        <EquiloButton
          :text="'Cancel'"
          :bg-color="'cancel'"
          @on-click="$emit('on-cancel')"
        />
        <EquiloButton
          :text="'Buy'"
          :disabled="selectedPrices.length === 0"
          @on-click="$emit('on-buy', selectedPrices)"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.tooltip-visible {
  width: 300px;
  display: block;
  position: absolute;
  text-align: center;
  border-radius: 5px;
  padding: 10px;
  z-index: 1;
  top: 125%;
  right: 50%;
  transform: translateX(100%);
}

input[type="checkbox"]:checked {
  border-color: transparent;
  @apply bg-orange-100;
}
</style>
import axios from "axios";

export const userLocale = navigator.language || navigator.userLanguage;

export const pause = async () => {
  return new Promise((resolve) => {
    setTimeout(resolve, 500);
  });
};

export const formatter = new Intl.NumberFormat(userLocale, {
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const fetchClientLocation =() => {
  return axios.get('https://ipapi.co/json/');
};
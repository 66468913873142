import { analytics } from "./segmentService";

class AnalyticsService {
  identify(userId, traits) {
    analytics.identify(userId, traits);
  }

  track(eventName, properties) {
    analytics.track(eventName, properties);
  }

  page(pageName, properties) {
    analytics.page(pageName, properties);
  }

  trackClick(elementName, properties = {}) {
    this.track("Element Clicked", {
      element: elementName,
      ...properties,
    });
  }
}

export const analyticsService = new AnalyticsService();

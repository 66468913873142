<template>
  <div
    data-modal="accept-terms"
    v-if="showAgreement && !user.userAgreement2XGlobal"
  >
    <div
      class="fixed top-0 left-0 w-screen h-screen z-10 bg-white"
      id="notLoaded"
      v-if="applyTermsPreloader"
    >
      <page-loader></page-loader>
    </div>
    <div class="overlay"></div>
    <div class="wr">
      <div class="quote">TERMS OF SERVICE</div>
      <div class="content-wr">
        <h1>2X Global User Agreement</h1>
        <div class="list-wrapper">
          <h2>
            PLEASE READ THESE TERMS OF SERVICE ("TERMS") CAREFULLY BEFORE USING
            THIS PLATFORM
          </h2>
          <ol>
            <li>
              <h2>DEFINITIONS</h2>
              <p class="p-indentation">
                Terms defined in the Glossary of Terms
                <a href="http://2xcertification.org/resouces/glossary"
                  >2xcertification.org/resouces/glossary</a
                >
                (either directly or by cross-reference to other sources) have
                the same meanings when they are used here unless they are
                specifically defined differently here.
              </p>
              <ol>
                <li class="p-indentation">
                  <strong>2X Certificate</strong> is the final official
                  confirmation that an entity is 2X certified. The 2X
                  Certificate is awarded by the 2X Certification Body after the
                  Third-party Verifier has satisfactorily completed the
                  verification and we are comfortable. The decision to award the
                  2X Certificate is based on the data provided by you as part of
                  the self-assessment as well as the Verification Report.
                </li>
                <li class="p-indentation">
                  <strong>2X Certification Platform ('Platform')</strong> means
                  the tech platform on which the 2X Certification process is
                  hosted. It captures all steps of the 2X Certification process.
                  The 2X Certification Platform is powered by Equilo. The
                  platform has been built specifically for the requirements of
                  the 2X Certification mechanism.
                </li>
                <li class="p-indentation">
                  <strong>Control</strong> has the meaning given in section 1124
                  of the Corporation Tax Act 2010.
                </li>
                <li class="p-indentation">
                  <strong>Group:</strong> in relation to a company, that
                  company, any subsidiary or holding company from time to time
                  of that company, and any subsidiary from time to time of a
                  holding company of that company.
                </li>
                <li class="p-indentation">
                  <strong>Intellectual Property Rights:</strong> patents,
                  utility models, rights to inventions, copyright and related
                  rights, moral rights, trademarks and service marks, business
                  names and domain names, rights in get-up and trade dress,
                  goodwill and the right to sue for passing off or unfair
                  competition, rights in designs, rights in computer software,
                  database rights, rights to use, and protect the
                  confidentiality of, confidential information (including
                  know-how and trade secrets) and all other intellectual
                  property, in each case whether registered or unregistered and
                  including all applications and rights to apply for and be
                  granted, renewals or extensions of, and rights to claim
                  priority from, such rights and all similar or equivalent
                  rights or forms of protection which subsist or will subsist
                  now or in the future in any part of the world.
                </li>
                <li class="p-indentation">
                  <strong>Third-party Verifier</strong> means a third-party who
                  conducts verification under the 2X Certification mechanism
                  that may be appointed by time to time by you, provided that
                  such third-party: is an independent professional services firm
                  and/or sustainability or gender equity consultancy firm which
                  is regularly engaged in the review, application and monitoring
                  of social and/or ESG issues, standards, processes and/or
                  methodologies; not an affiliate or otherwise connected to you;
                  and meets our assessment criteria.
                </li>
                <li class="p-indentation">
                  <strong>User</strong> means any individual or organization
                  using the Platform, including, for the avoidance of doubt, an
                  Entity.
                </li>
                <li class="p-indentation">
                  <strong>Verification Report</strong> means the report prepared
                  and provided by the Third-party Verifier in the format and to
                  the specification required by 2X Global, and upload such
                  Report to the 2X Certification Platform for review of 2X
                  Global prior to review by Entity.
                </li>
              </ol>
            </li>
            <li>
              <h2>Who we are and how to contact us</h2>
              <ol>
                <li class="pl-[62px]">
                  The Platform is operated by 2X GLOBAL LIMITED (We or Us). We
                  are registered in England and Wales under company number
                  13697512 and have our registered office at Crowe U.K. LLP, 55
                  Ludgate Hill, London, England, EC4M 7JW.
                </li>
                <li class="pl-[62px]">To contact us, please email certification@2xglobal.org.</li>
                <li class="pl-[62px]">Your Status</li>
                <li class="pl-[62px]">You may only use this if you are a Company or a Fund.</li>
              </ol>
            </li>
            <li>
              <h2>By using our Platform you accept these Terms</h2>
              <ol>
                <li class="pl-[62px]">
                  By using our Platform, you confirm that you accept these Terms
                  and that you agree to comply with them.
                </li>
                <li class="pl-[62px]">
                  Please record your acceptance by confirming in the box at the
                  bottom of this page
                </li>
                <li class="pl-[62px]">
                  If you do not agree to these Terms, you must not use our
                  Platform.
                </li>
              </ol>
            </li>
            <li>
              <h2>There are other terms that may apply to you</h2>
              <ol>
                <li class="pl-[62px]">
                  These Terms refer to the following additional terms, which
                  also apply to your use of our Platform:
                  <ol>
                    <li class="pl-[32px]">
                      Our Privacy Policy [2xcertification.org/privacy], which
                      explains how we collect, use and store your personal data.
                    </li>
                    <li class="pl-[32px]">
                      Our
                      <a href="https://app.equilo.io/#/cookies-policy"
                        >Cookie Policy</a
                      >, which sets out information about the cookies on our
                      Platform.
                    </li>
                  </ol>
                </li>
                <li class="pl-[62px]">
                  The following documents also form part of these Terms:
                  <ol class="pl-[32px]">
                    <li>FAQs: 2xcertification.org/resources/faq</li>
                    <li>
                      Glossary of Terms: 2xcertification.org/resources/glossary
                    </li>
                    <li class="pl-[32px]">User Guide: 2xcertification.org/resources/userguide</li>
                  </ol>
                </li>
                <li>
                  You will need to adopt and comply with all the above policies
                  and any other policies and procedures we notify you with from
                  time to time.
                </li>
              </ol>
            </li>
            <li>
              <h2>We may make changes to these Terms</h2>
              <ol class="pl-[62px]">
                <li>
                  We amend these Terms from time to time. Every time you wish to
                  use our Platform, please check these Terms to ensure you
                  understand the terms that apply at that time.
                </li>
              </ol>
            </li>
            <li>
              <h2>We may make changes to our Platform</h2>
              <ol>
                <li class="pl-[62px]">
                  We may update and change our Platform from time to time
                  including to reflect changes to our products, our Users' needs
                  and our business priorities.
                </li>
              </ol>
            </li>
            <li>
              <h2>We may suspend or withdraw our Platform</h2>
              <ol class="pl-[62px]">
                <li>
                  We do not guarantee that our Platform, or any content on it,
                  will always be available, error-free or be uninterrupted. We
                  may suspend or withdraw or restrict the availability of all or
                  any part of our Platform for business and operational reasons.
                  We do not make any representations as to the Platform or the
                  content disclosed as part of the 2X Certification process in
                  respect of fitness or merchantability for a particular purpose
                  and will not accept any liability arising from this failing to
                  meet any specific expectations. We will endeavour to give you
                  reasonable notice of any suspension or withdrawal.
                </li>
                <li>
                  You are also responsible for ensuring that all persons who
                  access our Platform through your internet connection are aware
                  of these Terms and other applicable terms of service, and that
                  they comply with them.
                </li>
              </ol>
            </li>

            <li>
              <h2>We may transfer this agreement to someone else</h2>
              <ol>
                <li class="pl-[62px]">
                  We may transfer our rights and obligations under these Terms
                  to another organisation. We will always tell you in writing if
                  this happens and we will ensure that the transfer will not
                  affect your rights under these Terms.
                </li>
              </ol>
            </li>

            <li>
              <h2>You must keep your account details safe</h2>
              <ol>
                <li class="pl-[62px]">
                  If you choose, or you are provided with, a user identification
                  code, password or any other piece of information as part of
                  our security procedures, you must treat such information as
                  confidential. You must not disclose it to any third party.
                </li>
                <li class="pl-[62px]">
                  We have the right to disable any user identification code or
                  password, whether chosen by you or allocated by us, at any
                  time, if in our reasonable opinion you have failed to comply
                  with any of the provisions of these Terms.
                </li>
                <li class="pl-[62px]">
                  If you know or suspect that anyone other than you knows your
                  user identification code or password, you must promptly notify
                  us at certification@2xglobal.org.
                </li>
                <li class="pl-[62px]">
                  Your Obligations
                  <ol>
                    <li>
                      You may only use the results of any 2X Certification
                      Self-Assessment for a period of 6 months.
                    </li>
                    <li>
                      From the start of October 2025, you may only take a 2X
                      Certification Self-Assessment once per annum, otherwise
                      you will have to pay an additional fee.
                    </li>
                    <li>
                      You must complete the 2X Certification Self-Assessment
                      within 60 days of registration for the 2X Certification
                      Self-Assessment.
                    </li>
                    <li>
                      You will only be provided with the 2X Certificate once you
                      have paid the appropriate fee to us. The 2X Certificate is
                      valid for two (2) years only as such period is set out on
                      the 2X Certificate time-stamp . Accordingly, you shall not
                      use the 2X Certificate or make use of the 2X Certification
                      for any Company or Fund not certified as part of the 2X
                      Certification process and agree that you will cease use or
                      reference of the then-current 2X Certificate or 2X
                      Certification or any other reference to the 2X Certificate
                      or 2X Certification upon the expiry of such two (2) year
                      period.
                    </li>
                    <li>
                      Following the expiry of the two (2) year period, you will
                      need to re-certificate your 2X Certificate. Your renewed
                      2X Certificate will be issued upon a new fee payment
                      following completion of a renewed 2X Certificate
                      Self-Asessment and Third-Party Verification. Pricing of
                      the fee payment will be displayed on the website and may
                      be updated from time to time.
                    </li>
                  </ol>
                </li>
                <li class="pl-[32px]">
                  If you want to purchase the optional Level Up package then you
                  will be required to pay an additional amount via the Platform
                  in order to access this.
                </li>
                <li class="p-indentation-minor">
                  You will not act in a manner which may negatively impact the
                  reputation, goodwill or brand of us or the 2X Certification
                  Platform. We reserve the right to revoke the 2X Certificate if
                  we become aware of any misrepresentation by you or if a
                  serious complaint is raised against you through our complaints
                  mechanism. Upon revocation of the 2X Certificate, you have no
                  further rights to refer to or represent that you hold that 2X
                  Certification.
                </li>
                <li class="p-indentation-minor">
                  You will provide us with all necessary cooperation reasonably
                  requested by us to facilitate the provision of the Platform or
                  the 2X Certification, including:
                  <ol style="text-indent: 0">
                    <li>
                      providing all data required for the provision of the 2X
                      Certification or the Platform (including for the purposes
                      of re-certification of the 2X Certification every two (2)
                      years upon request by us); and
                    </li>
                    <li>
                      providing us and our subcontractors, agents and partners
                      (including the Third-party Verifier) with all relevant
                      data, cooperation, and assistance that they may need to
                      perform their functions as part of the 2X Certification
                      process, and you hereby permit us to share any such data
                      to members of our Group and its sub-contractors, agents
                      and partners to the extent they require access to such
                      data to perform activities in connection with the 2X
                      Certification process or Platform.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <h2>You acknowledge that:</h2>
              <ol class="pl-[62px]">
                <li>
                  2X Global will not issue the 2X Certificate unless and until
                  the 2X Certification has been completed in a satisfactory
                  standard in accordance with these Terms, and the application
                  has been accepted;
                </li>
                <li>
                  The 2X Certification cannot be obtained by the User if the
                  Verification Report does not include all the information set
                  out in Minimum Requirements for Verification Report and/or is
                  not provided in the format and specification required by 2X
                  Global, and if it does not cover all matters;
                </li>
                <li>
                  2X Global reserves the right to terminate or suspend a User's
                  access to the Platform at any time for breach of these Terms
                  or for any other reason deemed appropriate by 2X Global; and
                </li>
                <li>
                  You have read and understood the 2X Certification User Guide
                  <a href="http://2xcertification.org/resources/userguide"
                    >2xcertification.org/resources/userguide</a
                  >.
                </li>
              </ol>
            </li>

            <li>
              <h2>How you may use material on our Platform</h2>
              <ol class="pl-[62px]">
                <li>
                  We are the owner or the licensee of all intellectual property
                  rights in our Platform, and in the material published on it.
                  Those works are protected by copyright laws and treaties
                  around the world. All such rights are reserved.
                </li>
                <li>
                  Our status (and that of any identified contributors) as the
                  authors of content on our Platform must always be acknowledged
                  (except where the content is user-generated).
                </li>
                <li>
                  You are permitted to download a copy of the 2X Certification
                  User Guide. You must not use any part of the content on our
                  Platform for commercial purposes without obtaining a licence
                  to do so from us or our licensors.
                </li>
                <li>
                  If you print off, copy, download, share or repost any part of
                  our Platform in breach of these Terms, your right to use our
                  Platform will cease immediately and you must, at our
                  discretion, return or destroy any copies of the materials you
                  have made (except that you are permitted to print off a copy
                  of these Terms).
                </li>
                <li>
                  All media releases, public announcements and public
                  disclosures by you of, in, or relation to, the Platform or any
                  services provided and the 2X Certification (including the
                  results of any self-assessment completed as part of the 2X
                  Certification), shall be co-ordinated with and approved by us
                  in writing prior to release (provided that we shall not
                  unreasonably withhold or delay its approval upon delivery of
                  the 2X Certification to you). The above restriction does not
                  apply to any announcement intended solely for internal
                  distribution within the Customer or required as a result of
                  any applicable laws.
                </li>
                <li>
                  In particular, you cannot refer to a 2X Certificate or make
                  any statements regarding certification unless you have been
                  issued with a 2X Certificate in accordance with the 2X
                  Certification Process.
                </li>
                <li>
                  The logo and materials contained within the Platform or that
                  we otherwise make available (including as part of the 2X
                  Certification) to you must be used in accordance with the 2X
                  Certification Usage of marketing materials and brand
                  guidelines, as outlined on the website
                  <a href="http://2xcertification.org/resources/brandguidelines"
                    >2xcertification.org/resources/brandguidelines</a
                  >. This includes not altering the logo or using it in
                  inappropriate contexts.
                </li>
              </ol>
            </li>

            <li>
              <h2>No text or data mining, or web scraping</h2>
              <ol class="pl-[62px]">
                <li>
                  You shall not conduct, facilitate, authorise or permit any
                  text or data mining or web scraping in relation to our
                  Platform or any services provided via, or in relation to, our
                  Platform for any purpose, including the development, training,
                  fine-tuning or validation of AI systems or models. This
                  includes using (or permitting, authorising or attempting the
                  use of):
                  <ol>
                    <li>
                      Any "robot", "bot", "spider", "scraper" or other automated
                      device, program, tool, algorithm, code, process or
                      methodology to access, obtain, copy, monitor or republish
                      any portion of our Platform or any data, content,
                      information or services accessed via the same.
                    </li>
                    <li>
                      Any automated analytical technique aimed at analysing text
                      and data in digital form to generate information [or
                      develop, train, fine-tune or validate AI systems or
                      models] which includes but is not limited to patterns,
                      trends and correlations.
                    </li>
                  </ol>
                </li>
                <li>
                  The provisions in this clause should be treated as an express
                  reservation of our rights in this regard, including for the
                  purposes of Article 4(3) of the Digital Copyright Directive
                  ((EU) 2019/790).
                </li>
                <li>
                  You shall not use, and we do not consent to the use of, our
                  Platform, or any data published by, or contained in, or
                  accessible via, our Platform or any services provided via, or
                  in relation to, our Platform for the purposes of developing,
                  training, fine-tuning or validating any AI system or model.
                </li>
                <li>
                  This clause will not apply insofar as (but only to the extent
                  that) we are unable to exclude or limit text or data mining or
                  web scraping activity by contract under the laws which are
                  applicable to us.
                </li>
              </ol>
            </li>
            <li>
              <h2>Uploading content to our Platform</h2>
              <ol class="pl-[62px]">
                <li>
                  Whenever you make use of a feature that allows you to upload
                  content directly to our Platform, you must comply with the
                  standards published on our website or made available from time
                  to time.
                </li>
                <li>
                  You warrant that any such contribution complies with those
                  standards, and you are liable to us and indemnify us for any
                  breach of that warranty or where such content infringes the
                  intellectual property rights of any third party, in unlawful
                  or otherwise is uploaded without you obtaining all required
                  consents and approvals. This means you will be responsible for
                  any loss or damage we suffer as a result of your breach of
                  warranty.
                </li>
                <li>
                  You own your content, but you are required to grant us a
                  limited licence to use, store and copy that content and to
                  distribute and make it available to others. The rights you
                  license to us are described in the Rights you are giving us to
                  material you upload clause in in the User Agreement found at
                  <a href="http://2xcertification.org/resources/useragreement"
                    >2xcertification.org/resources/useragreement</a
                  >.
                </li>
                <li>
                  We also have the right to disclose your identity to anyone who
                  is claiming that any content posted or uploaded by you to our
                  Platform violates their intellectual property rights or their
                  right to privacy.
                </li>
              </ol>
            </li>
            <li>
              <h2>Rights you are giving us to use material you upload</h2>
              <ol class="pl-[62px]">
                <li>
                  When you upload or post content or information to our Platform
                  or share content or information with us (including that
                  relating to you, your gender lens disclosures, your policies
                  and your financing arrangements), you grant us the following
                  rights to use that content:
                  <ol>
                    <li>
                      A worldwide, non-exclusive, royalty-free, transferable
                      licence to use, reproduce, distribute, prepare derivative
                      works of, display, and perform that user-generated content
                      in connection with the service provided by the Platform or
                      for our general business purposes (including to make
                      non-sensitive content publicly available on our website as
                      part of our scorecards, for the purposes of benchmarking,
                      comparing Entities, improving the methodology and
                      standards and understanding the demographics of Entities)
                      and across different media including to promote our
                      Platform or the service forever; and
                    </li>
                    <li>
                      A worldwide, non-exclusive, royalty-free, transferable
                      licence for other U sers, partners or advertisers to use
                      the content forever.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <h2>Do not rely on information on this Platform</h2>
              <ol class="pl-[62px]">
                <li>
                  The content on our Platform or otherwise made available to you
                  is provided for general information only. It is not intended
                  to amount to advice on which you should rely. You must obtain
                  professional or specialist advice before taking, or refraining
                  from, any action on the basis of the content on our Platform.
                  In particular, we make no representation or warranty that such
                  content is sufficient to enable you to comply with applicable
                  laws applicable to you, and any such determination must be
                  made by you in your sole discretion.
                </li>
                <li>
                  Although we make reasonable efforts to update the information
                  on our Platform, we make no representations, warranties or
                  guarantees, whether express or implied, that the content on
                  our Platform or that which we provide to you is accurate,
                  complete or up to date.
                </li>
              </ol>
            </li>
            <li>
              <h2>
                We are not responsible for websites or third parties we link to
              </h2>
              <ol class="pl-[62px]">
                <li>
                  Where our Platform contains links to other sites and resources
                  provided by third parties, these links are provided for your
                  information only. Such links should not be interpreted as
                  approval by us of those linked websites or information you may
                  obtain from them. We have no control over the contents of
                  those sites, parties or resources.
                </li>
                <li>
                  You will need to appoint a Third-party Verifier in order to
                  complete the 2X Certification and you will need to ensure that
                  they are suitably skilled, qualified and experienced and meet
                  the requirements set out at
                  <a href="http://2xcertification.org/resources/verifiers"
                    >2xcertification.org/resources/verifiers</a
                  >. You may appoint a Third-party Verifier of your choice,
                  regardless of whether they have completed the training
                  programme provided by 2X Global. Where the Third-party
                  Verifier has undergone the training, this will be indicated on
                  the 2X Certificate.
                </li>
                <li>
                  In particular, we are not responsible for the acts or
                  omissions of Third-party Verifiers. When you appoint a
                  Third-party Verifier, they act as independent third parties
                  and we take no responsibility or liability for their acts or
                  omissions.
                </li>
              </ol>
            </li>
            <li>
              <h2>We are not responsible for viruses</h2>
              <ol class="pl-[62px]">
                <li>
                  We do not guarantee that our Platform will be secure or free
                  from bugs or viruses.
                </li>
                <li>
                  You are responsible for configuring your information
                  technology, computer programs and platform to access our
                  Platform. You should use your own virus protection software.
                </li>
              </ol>
            </li>
            <li>
              <h2>
                You must not introduce viruses or act in a way that prejudices
                the Platform
              </h2>
              <ol class="pl-[62px]">
                <li>
                  You must not misuse our Platform by knowingly introducing
                  viruses, trojans, worms, logic bombs or other material that is
                  malicious or technologically harmful, or otherwise harmfully
                  interacting with our Platform or any part of it. You must not
                  attempt to gain unauthorised access to our Platform, the
                  server on which our Platform is stored or any server, computer
                  or database connected to our Platform or any other equipment
                  or network connected with our Platform. You must not interfere
                  with, damage or disrupt any software used in the provision of
                  our Platform or any equipment or network or software owned or
                  used by any third party on which this Platform relies in any
                  way. You must not attack our Platform via a denial-of-service
                  attack or a distributed denial-of-service attack. By breaching
                  this provision, you would commit a criminal offence under the
                  Computer Misuse Act 1990. We will report any such breach to
                  the relevant law enforcement authorities and we will
                  co-operate with those authorities by disclosing your identity
                  to them. In the event of such a breach, your right to use our
                  Platform will cease immediately.
                </li>
                <li>
                  You must not use the Platform in a way that:
                  <ol class="pl-[32px]">
                    <li>
                      is unlawful, harmful, threatening, defamatory, obscene,
                      infringing, harassing or racially or ethnically offensive;
                    </li>
                    <li>facilitates illegal activity;</li>
                    <li>depicts sexually explicit images;</li>
                    <li>promotes unlawful violence;</li>
                    <li>
                      is discriminatory based on race, gender, colour, religious
                      belief, sexual orientation, disability; or
                    </li>
                    <li>
                      is otherwise illegal or causes damage or injury to any
                      person or property.
                    </li>
                  </ol>
                </li>
                <li>
                  You shall not:
                  <ol class="pl-[32px]">
                    <li>
                      attempt to copy, modify, duplicate, create derivative
                      works from, frame, mirror, republish, download, display,
                      transmit, or distribute all or any portion of the Platform
                      or in any form or media or by any means;
                    </li>
                    <li>
                      attempt to de-compile, reverse compile, disassemble,
                      reverse engineer or otherwise reduce to human-perceivable
                      form all or any part of the Platform;
                    </li>
                    <li>
                      access all or any part of the Platform in order to build a
                      product or service which competes with the Platform;
                    </li>
                    <li>
                      use the Platform to provide services to third parties;
                    </li>
                    <li>
                      license, sell, rent, lease, transfer, assign, distribute,
                      display, disclose, or otherwise commercially exploit, or
                      otherwise make the Platform available to any third party
                      except as permitted under these Terms; or
                    </li>
                    <li>
                      attempt to obtain, or assist third parties in obtaining,
                      access to the Platform, other than as provided under these
                      Terms.
                    </li>
                  </ol>
                </li>
                <li>
                  Our responsibility for loss or damage suffered by you
                  <ol>
                    <li>
                      Subject to the remainder of this section our total
                      liability to you shall not exceed 50% of the amounts paid
                      by you to us in the 12 months prior to the date of any
                      claim.
                    </li>
                    <li>
                      We do not exclude or limit in any way our liability to you
                      where it would be unlawful to do so. This includes
                      liability for death or personal injury caused by our
                      negligence or the negligence of our employees, agents or
                      subcontractors and for fraud or fraudulent
                      misrepresentation.
                    </li>
                    <li>
                      We do not endorse any products, services or third parties.
                    </li>
                    <li>
                      We exclude all implied conditions, warranties,
                      representations or other terms that may apply to our
                      Platform or any content on it.
                    </li>
                    <li>
                      We will not be liable to you for any loss or damage,
                      whether in contract, tort (including negligence), breach
                      of statutory duty, or otherwise, even if foreseeable,
                      arising under or in connection with:
                      <ol class="pl-[32px]">
                        <li>use of, or inability to use, our Platform; or</li>
                        <li>
                          use of, or reliance on, any content displayed on our
                          Platform.
                        </li>
                      </ol>
                    </li>
                    <li>
                      In particular, we will not be liable for:
                      <ol class="pl-[32px]">
                        <li>loss of profits, sales, business, or revenue;</li>
                        <li>business interruption;</li>
                        <li>loss of anticipated savings;</li>
                        <li>
                          loss of business opportunity, goodwill or reputation;
                          or
                        </li>
                        <li>any indirect or consequential loss or damage.</li>
                      </ol>
                    </li>
                    <li>
                      You shall indemnify us for any fraud or misrepresentation
                      on your part in connection with these Terms or the use of
                      the Platform.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <h2>Personal Data</h2>
              <ol class="p-indentation">
                <li>
                  We and you (each, a party) shall comply with any applicable
                  law or binding regulation on data protection or data privacy
                  (Applicable Privacy Law) in relation to any processing of
                  personal data, in connection with accessing the Platform
                  (Relevant Data).
                </li>
                <li>
                  Each party acts as a separate controller of Relevant Data and
                  accordingly shall:
                  <ol style="text-indent: 0">
                    <li>
                      only process the Relevant Data as reasonably necessary to
                      perform its obligations and exercise its rights under the
                      Terms;
                    </li>
                    <li>
                      give the other party such information in relation to that
                      processing as it reasonably requests from time to time to
                      enable the other party to comply with its obligations
                      under Applicable Privacy Law;
                    </li>
                    <li>
                      notify the other party in writing if it becomes aware of,
                      or suspects the occurrence of, any personal data breach in
                      relation to those Applicable Privacy Laws in respect of
                      the Relevant Data;
                    </li>
                    <li>
                      give the other party such information, assistance and
                      co-operation as it reasonably requests to enable it to
                      mitigate any adverse consequences for data subjects
                      arising from any personal data breach notified to it;
                    </li>
                    <li>
                      notify the other party in writing if it receives any
                      communication from a data subject or competent data
                      protection authority seeking to exercise rights under, or
                      alleging or proposing to investigate an allegation of
                      breach of, Applicable Privacy Law in relation to the
                      Relevant Data.
                    </li>
                  </ol>
                </li>
                <li class="pl-[32px]">
                  To the extent that any Relevant Data is processed outside the
                  United Kingdom or European Economic Area, the terms of the
                  transfer shall be governed by a lawful data transfer mechanism
                  in accordance with Applicable Privacy Laws. In these
                  situations both parties will seek to put in place appropriate
                  safeguards to protect personal data. For the purposes of this
                  clause, unless otherwise defined, terms have the meanings
                  given to them in the General Data Protection Regulation (EU)
                  2016/679.
                </li>
              </ol>
            </li>

            <li>
              <h2>Confidentiality</h2>
              <ol class="pl-[62px]">
                <li>
                  You shall not at any time disclose to any person any
                  confidential information concerning the business, affairs,
                  customers, clients or suppliers of us which relates to us
                  except as permitted in this section.
                </li>
                <li>
                  You may disclose the confidential information that relates to
                  us:
                  <ol class="pl-[32px]">
                    <li>
                      to your employees, officers, representatives,
                      subcontractors, or advisers who need to know such
                      information for the purposes of carrying out the party's
                      obligations under the terms provided that such employees,
                      officers, representatives, contractors, subcontractors, or
                      advisers comply with this section; or
                    </li>
                    <li>
                      as may be required by law, a court of competent
                      jurisdiction or any governmental or regulatory authority
                      provided that, to the extent permitted by law you first
                      notify us and give us a reasonable opportunity to object.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <h2>Which country's laws apply to any disputes?</h2>
              <ol class="pl-[62px]">
                <li>
                  These Terms, their subject matter and their formation (and any
                  non-contractual disputes or claims) are governed by English
                  law. We and you agree to the exclusive jurisdiction of the
                  courts of England and Wales.
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
      <div class="btn-wr">
        <div class="btn white tooltip" @click="denyTerms">
          <span class="lang-tooltiptext notranslate"
            >Only by reading and accepting the agreement, you may proceed to the
            2X Certification Self-Assessment</span
          >Cancel
        </div>
        <div class="btn" @click="acceptTerms">Accept</div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Cookies from "@/helpers/cookies";
import env from "@/../env.js";

export default {
  name: "TwoXUserAgreement",
  data: function () {
    return {
      applyTermsPreloader: false,
    };
  },
  props: {
    showAgreement: Boolean,
    user: Object,
  },
  methods: {
    async acceptTerms() {
      this.applyTermsPreloader = true;
      try {
        await axios.patch(
          `${env.apiUrl}/users/update-user-agreement-2x/${this.user._id}`,
          { userAgreement2XGlobal: true },
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("session")}`,
            },
          }
        );
        this.$emit("agreement-accepted");
      } catch (error) {
        console.log(error);
      } finally {
        this.applyTermsPreloader = false;
      }
    },
    denyTerms() {
      this.$emit("agreement-denied");
    },
  },
};
</script>

<style>
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .lang-tooltiptext {
  visibility: hidden;
  width: 480px;
  background-color: #6b787f;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 20px;
  position: absolute;
  z-index: 9999;
  top: -54px;
  left: -30px;
}

.tooltip:hover .lang-tooltiptext {
  visibility: visible;
}

.tooltip .lang-tooltiptext::after {
  right: 95%;
}

.list-wrapper ol li:before {
  display: none;
}

.p-indentation {
  text-indent: -62px;
  padding-left: 62px;
}

.p-indentation-minor {
  text-indent: -32px;
  padding-left: 62px;
}

</style>



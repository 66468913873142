import BaseAPI from "@/api/index";

class ImmAPI {
  constructor() {
    this.axiosInstance = BaseAPI.getInstance();
  }

  /**
   *
   * @param year<number>
   * @param month<number>
   */
  getImmFinanceTableData(year, month) {
    return this.axiosInstance.get(`/imm/layout-data?month=${month}&year=${year}`);
  }

  /**
   *
   * @param year<number>
   * @param groupBy<'Month' | 'Quarter' | 'Year' | 'All Time'>
   */
  getActualData(year, groupBy) {
    return this.axiosInstance.get(`/imm/actual-data?year=${year}&groupBy=${groupBy}`);
  }

  getUserIMMFinancePeriods(userId, teamId) {
    return this.axiosInstance.get(`/imm/user-imm-finance-periods?userId=${userId}&teamId=${teamId}`);
  }
}

export default ImmAPI;
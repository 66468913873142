import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export default {
  async exportToPDF(element, pdfName , excludeSelectors = []) {
    try {
      // Convert SVGs to canvas elements
      this.applyExcludeStyles(element, excludeSelectors);
      await this.convertSVGsToCanvas(element);

      // Use html2canvas to capture the element as a canvas
      const canvas = await html2canvas(element, {
        scale: 2,
        logging: false,
        useCORS: true,
        allowTaint: true
      });

      // Calculate PDF dimensions
      const margin = 10; // 10mm margin
      const imgWidth = 210 - (2 * margin); // A4 width in mm minus margins
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Initialize a new PDF document
      const pdf = new jsPDF({
        orientation: 'p',
        unit: 'mm',
        format: 'a4',
        compress: true
      });

      // Set the PDF height to match the content height plus margins
      pdf.internal.pageSize.height = imgHeight + (2 * margin);
      pdf.internal.pageSize.width = 210; // A4 width

      // Convert canvas to compressed JPEG
      const imgData = canvas.toDataURL('image/jpeg', 0.7); // 0.7 is the quality (0-1)

      // Add content to the PDF (single page) with margins
      pdf.addImage(imgData, 'JPEG', margin, margin, imgWidth, imgHeight);

      // Save the PDF with the specified name
      pdf.save(`${pdfName}.pdf`);
    } catch (error) {
      console.error("Error exporting to PDF", error);
    }
  },

  async exportToFullPDF(element , pdfName ) {
    try {
      // this.applyExcludeStyles(element, excludeSelectors);
      await this.prepareSvgsForPdf();
      await this.loadImages();

      const canvas = await html2canvas(element, {
        logging: false,
        scale: 2,
        useCORS: true,
        allowTaint: true,
        windowWidth: element.scrollWidth,
        windowHeight: element.scrollHeight
      });

      const imgData = canvas.toDataURL('image/jpeg', 1.0);

      // Calculate PDF dimensions based on content
      const aspectRatio = canvas.width / canvas.height;
      let pdfWidth = 297; // A4 width in mm
      let pdfHeight = pdfWidth / aspectRatio;

      // If height exceeds 5 meters, adjust width to maintain aspect ratio
      if (pdfHeight > 5000) {
        pdfHeight = 5000;
        pdfWidth = pdfHeight * aspectRatio;
      }

      // Add margins (in mm)
      const margin = 5;
      const contentWidth = pdfWidth - (2 * margin);
      const contentHeight = pdfHeight - (2 * margin);

      const pdf = new jsPDF({
        orientation: pdfWidth > pdfHeight ? 'l' : 'p',
        unit: 'mm',
        format: [pdfWidth, pdfHeight]
      });

      // Add image with margins
      pdf.addImage(imgData, 'JPEG', margin, margin, contentWidth, contentHeight);
      pdf.save(pdfName);
    } catch (error) {
      console.error("Error generating PDF:", error);
      throw error;
    }
  },

  loadImages() {
    const images = document.querySelectorAll('img');
    return Promise.all(Array.from(images).filter(img => !img.complete).map(img => new Promise(resolve => { img.onload = img.onerror = resolve; })));
  },

  async convertSvgToDataUrl(svgElement) {
    const svgData = new XMLSerializer().serializeToString(svgElement);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.src = "data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(svgData)));
    await new Promise(resolve => img.onload = resolve);
    canvas.width = img.width;
    canvas.height = img.height;
    ctx.drawImage(img, 0, 0);
    return canvas.toDataURL("image/png");
  },

  async prepareSvgsForPdf() {
    const svgs = document.querySelectorAll('svg');
    for (const svg of svgs) {
      const dataUrl = await this.convertSvgToDataUrl(svg);
      const img = document.createElement('img');
      img.src = dataUrl;
      svg.parentNode.replaceChild(img, svg);
    }

    const svgImages = document.querySelectorAll('img[src$=".svg"]');
    for (const img of svgImages) {
      try {
        const response = await fetch(img.src);
        const svgText = await response.text();
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(svgText, 'image/svg+xml');
        const svgElement = svgDoc.documentElement;
        const dataUrl = await this.convertSvgToDataUrl(svgElement);
        img.src = dataUrl;
      } catch (error) {
        console.error("Error converting SVG image:", error);
      }
    }
  },

  async convertSVGsToCanvas(element) {
    const svgs = element.querySelectorAll('svg');
    for (let svg of svgs) {
      const svgData = new XMLSerializer().serializeToString(svg);
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      const img = new Image();
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        svg.parentNode.replaceChild(canvas, svg);
      };
      img.src = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgData)));
      await new Promise(resolve => img.onload = resolve);
    }

    const imgTags = element.querySelectorAll('img[src$=".svg"]');
    for (let img of imgTags) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      const loadedImg = new Image();
      loadedImg.crossOrigin = 'Anonymous';
      loadedImg.onload = () => {
        canvas.width = loadedImg.width;
        canvas.height = loadedImg.height;
        ctx.drawImage(loadedImg, 0, 0);
        img.parentNode.replaceChild(canvas, img);
      };
      loadedImg.src = img.src;
      await new Promise(resolve => loadedImg.onload = resolve);
    }
  },
  applyExcludeStyles(element, excludeSelectors) {
    excludeSelectors.forEach(selector => {
      const elementsToExclude = element.querySelectorAll(selector);
      elementsToExclude.forEach(el => {
        el.style.display = 'none';
      });
    });
  }
  
};

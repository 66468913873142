var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"annotatable",staticClass:"static ecosystem-landing"},[(!_vm.endofcreated)?_c('div',{key:"loading",attrs:{"id":"notLoaded"}},[_c('page-loader')],1):_vm._e(),(_vm.endofcreated && _vm.certificationScoreData)?_c('div',{staticClass:"container mx-auto pt-20 px-4 lg:px-0 pb-1"},[_c('BreadCrumbs',{staticStyle:{"margin-top":"0"}}),_c('div',{staticClass:"ContentWrapper w-12/12 mt-8"},[_c('div',{staticClass:"container mx-auto"},[_c('div',{staticClass:"container mx-auto grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 pt-6 gap-4"},[_c('div',{staticClass:"flex w-full items-center"},[_c('button',{staticClass:"focus:outline-none cursor-pointer p-4 rounded bg-[#F4F6F9] text-sm font-normal leading-none text-[#9EA4BA] flex items-center justify-between border border-[#E1E3E9] rounded-l-lg transition-all duration-300 outline-none",class:{
                'bg-[#2177C1] !text-white !font-semibold':
                  _vm.activeTab === 'Funds',
              },on:{"click":function($event){_vm.activeTab === 'Funds'
                  ? _vm.activateTab('Company')
                  : _vm.activateTab('Funds')}}},[_vm._v(" Funds/FIs ")]),_c('button',{staticClass:"focus:outline-none cursor-pointer p-4 bg-[#F4F6F9] text-sm font-normal leading-none text-[#9EA4BA] flex items-center justify-between border border-[#E1E3E9] rounded-r-lg transition-all duration-300 -ml-4 outline-none",class:{
                'bg-[#2177C1] !text-white !font-semibold':
                  _vm.activeTab === 'Company',
              },on:{"click":function($event){_vm.activeTab === 'Company'
                  ? _vm.activateTab('Funds')
                  : _vm.activateTab('Company')}}},[_vm._v(" Company ")])]),_c('div',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedOption),expression:"selectedOption"}],staticClass:"focus:outline-none cursor-pointer w-full p-4 rounded bg-white text-sm font-medium leading-none text-[#21191D] flex items-center justify-between border border-[#E1E3E9] rounded-lg",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedOption=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"disabled":"","value":""}},[_vm._v("Please select an option")]),_c('option',[_vm._v("Portfolio")]),_c('option',[_vm._v("Pipeline")]),_c('option',[_vm._v("Alumni")])])]),_c('div',[_c('div',{staticClass:"relative flex items-center w-full"},[_c('input',{staticClass:"shadow-sm block w-full sm:text-sm border h-12 rounded-lg outline-none px-12 placeholder-[#9EA4BA]",attrs:{"type":"text","name":"search","id":"search","placeholder":_vm.activeTab === 'Company'
                    ? 'Search by company name'
                    : 'Search by fund name'}}),_c('svg',{staticClass:"w-6 h-6 absolute left-0 ml-4 text-[#9EA4BA]",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke-width":"1.5","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","d":"m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"}})])])]),_c('div')]),_c('div',{staticClass:"mt-8 flex"},[_c('TwoxTimefilterVue'),_c('div',{staticClass:"DownloadBtn ml-auto"},[_c('div',{staticClass:"tooltip"},[_c('div',{on:{"click":function($event){return _vm.onExport()}}},[_vm._m(0)])])])],1),(
            _vm.spiderData && Array.isArray(_vm.spiderData) && _vm.spiderData.length > 0
          )?_c('div',{staticClass:"chart-box mt-8 p-8"},[_vm._m(1),_c('div',{staticClass:"spiderchartEligibleWrepper mt-8"},[_c('div',{staticClass:"spiderchartContent",attrs:{"id":"chart"}},[_c('div',{staticClass:"z-20 relative"},[_c('spiderChart',{staticClass:"z-10 overflow-visible",staticStyle:{"position":"relative","height":"100%","width":"100%"},attrs:{"iso":_vm.comapnyDetails[0]?.countryHq?.iso,"paddingL":40,"paddingR":40,"paddingT":40,"paddingB":40,"details":_vm.comapnyDetails[0],"countData":_vm.countData,"country":_vm.country,"info":_vm.spiderData}}),(
                    _vm.spiderSpaces &&
                    Array.isArray(_vm.spiderSpaces) &&
                    _vm.spiderSpaces.length > 0 &&
                    _vm.spiderSpaces[0]
                  )?_c('div',{staticClass:"text-center",style:('color: ' +
                    _vm.spiderSpaces[0]?.color +
                    '; position: absolute; left: 50%; top: 0%;')},[_c('img',{staticClass:"m-auto",attrs:{"src":require("@/assets/images/2x_certify/leadership.svg")}}),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.spiderSpaces[0]?.domain))])]):_vm._e(),(
                    _vm.spiderSpaces &&
                    Array.isArray(_vm.spiderSpaces) &&
                    _vm.spiderSpaces.length > 0 &&
                    _vm.spiderSpaces[1]
                  )?_c('div',{staticClass:"text-center",style:('color: ' +
                    _vm.spiderSpaces[1]?.color +
                    '; position: absolute; left: 65%; top: 6%;')},[_c('img',{staticClass:"m-auto",attrs:{"src":require("@/assets/images/2x_certify/leadership.svg")}}),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.spiderSpaces[1]?.domain))])]):_vm._e(),(
                    _vm.spiderSpaces &&
                    Array.isArray(_vm.spiderSpaces) &&
                    _vm.spiderSpaces.length > 0 &&
                    _vm.spiderSpaces[2]
                  )?_c('div',{staticClass:"text-center",style:('color: ' +
                    _vm.spiderSpaces[2]?.color +
                    '; position: absolute; right: 20%; top: 18%;')},[_c('img',{staticClass:"m-auto",attrs:{"src":require("@/assets/images/2x_certify/employment.svg")}}),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.spiderSpaces[2]?.domain))])]):_vm._e(),(
                    _vm.spiderSpaces &&
                    Array.isArray(_vm.spiderSpaces) &&
                    _vm.spiderSpaces.length > 0 &&
                    _vm.spiderSpaces[3]
                  )?_c('div',{staticClass:"text-center",style:('color: ' +
                    _vm.spiderSpaces[3]?.color +
                    '; position: absolute; right: 14%; top: 32%;')},[_c('img',{staticClass:"m-auto",attrs:{"src":require("@/assets/images/2x_certify/employment.svg")}}),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.spiderSpaces[3]?.domain))])]):_vm._e(),(
                    _vm.spiderSpaces &&
                    Array.isArray(_vm.spiderSpaces) &&
                    _vm.spiderSpaces.length > 0 &&
                    _vm.spiderSpaces[4]
                  )?_c('div',{staticClass:"text-center",style:('color: ' +
                    _vm.spiderSpaces[4]?.color +
                    '; position: absolute; right: 12%; top:49%;')},[_c('img',{staticClass:"m-auto",attrs:{"src":require("@/assets/images/2x_certify/employment.svg")}}),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.spiderSpaces[4]?.domain.substring(0, 17))),_c('br'),_vm._v(" "+_vm._s(_vm.spiderSpaces[4]?.domain.substring(17))+" ")])]):_vm._e(),(
                    _vm.spiderSpaces &&
                    Array.isArray(_vm.spiderSpaces) &&
                    _vm.spiderSpaces.length > 0 &&
                    _vm.spiderSpaces[5]
                  )?_c('div',{staticClass:"text-center",style:('color: ' +
                    _vm.spiderSpaces[5]?.color +
                    '; position: absolute; right: 15%; top: 68%;')},[_c('img',{staticClass:"m-auto",attrs:{"src":require("@/assets/images/2x_certify/employment.svg")}}),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.spiderSpaces[5]?.domain))])]):_vm._e(),(
                    _vm.spiderSpaces &&
                    Array.isArray(_vm.spiderSpaces) &&
                    _vm.spiderSpaces.length > 0 &&
                    _vm.spiderSpaces[6]
                  )?_c('div',{staticClass:"text-center",style:('color: ' +
                    _vm.spiderSpaces[6]?.color +
                    '; position: absolute; right: 20%; bottom: 20%;')},[_c('img',{staticClass:"m-auto",attrs:{"src":require("@/assets/images/2x_certify/supplychain.svg")}}),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.spiderSpaces[6]?.domain))])]):_vm._e(),(
                    _vm.spiderSpaces &&
                    Array.isArray(_vm.spiderSpaces) &&
                    _vm.spiderSpaces.length > 0 &&
                    _vm.spiderSpaces[7]
                  )?_c('div',{staticClass:"text-center",style:('color: ' +
                    _vm.spiderSpaces[7]?.color +
                    '; position: absolute; left: 58%; bottom: 5%;')},[_c('img',{staticClass:"m-auto",attrs:{"src":require("@/assets/images/2x_certify/portfolio.svg")}}),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.spiderSpaces[7]?.domain))])]):_vm._e(),(
                    _vm.spiderSpaces &&
                    Array.isArray(_vm.spiderSpaces) &&
                    _vm.spiderSpaces.length > 0 &&
                    _vm.spiderSpaces[8]
                  )?_c('div',{staticClass:"text-center",style:('color: ' +
                    _vm.spiderSpaces[8]?.color +
                    '; position: absolute; left: 35%; bottom: 5%;')},[_c('img',{staticClass:"m-auto",attrs:{"src":require("@/assets/images/2x_certify/portfolio.svg")}}),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.spiderSpaces[8]?.domain))])]):_vm._e(),(
                    _vm.spiderSpaces &&
                    Array.isArray(_vm.spiderSpaces) &&
                    _vm.spiderSpaces.length > 0 &&
                    _vm.spiderSpaces[9]
                  )?_c('div',{staticClass:"text-center",style:('color: ' +
                    _vm.spiderSpaces[9]?.color +
                    '; position: absolute; left: 20%; top: 78%;')},[_c('img',{staticClass:"m-auto",attrs:{"src":require("@/assets/images/2x_certify/products.svg")}}),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.spiderSpaces[9]?.domain))])]):_vm._e(),(
                    _vm.spiderSpaces &&
                    Array.isArray(_vm.spiderSpaces) &&
                    _vm.spiderSpaces.length > 0 &&
                    _vm.spiderSpaces[10]
                  )?_c('div',{staticClass:"text-center",style:('color: ' +
                    _vm.spiderSpaces[10]?.color +
                    '; position: absolute; left: 16%; bottom: 30%;')},[_c('img',{staticClass:"m-auto",attrs:{"src":require("@/assets/images/2x_certify/products.svg")}}),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.spiderSpaces[10]?.domain))])]):_vm._e(),(
                    _vm.spiderSpaces &&
                    Array.isArray(_vm.spiderSpaces) &&
                    _vm.spiderSpaces.length > 0 &&
                    _vm.spiderSpaces[11]
                  )?_c('div',{staticClass:"text-center",style:('color: ' +
                    _vm.spiderSpaces[11]?.color +
                    '; position: absolute; left: 14%; top: 50%;')},[_c('img',{staticClass:"m-auto",attrs:{"src":require("@/assets/images/2x_certify/products.svg")}}),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.spiderSpaces[11]?.domain))])]):_vm._e(),(
                    _vm.spiderSpaces &&
                    Array.isArray(_vm.spiderSpaces) &&
                    _vm.spiderSpaces.length > 0 &&
                    _vm.spiderSpaces[12]
                  )?_c('div',{staticClass:"text-center",style:('color: ' +
                    _vm.spiderSpaces[12]?.color +
                    '; position: absolute; left: 15%; top: 30%;')},[_c('img',{staticClass:"m-auto",attrs:{"src":require("@/assets/images/2x_certify/entrepreneurship.svg")}}),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.spiderSpaces[12]?.domain))])]):_vm._e(),(
                    _vm.spiderSpaces &&
                    Array.isArray(_vm.spiderSpaces) &&
                    _vm.spiderSpaces.length > 0 &&
                    _vm.spiderSpaces[13]
                  )?_c('div',{staticClass:"text-center",style:('color: ' +
                    _vm.spiderSpaces[13]?.color +
                    '; position: absolute; left: 20%; top: 17%;')},[_c('img',{staticClass:"m-auto",attrs:{"src":require("@/assets/images/2x_certify/entrepreneurship.svg")}}),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.spiderSpaces[13]?.domain))])]):_vm._e(),(
                    _vm.spiderSpaces &&
                    Array.isArray(_vm.spiderSpaces) &&
                    _vm.spiderSpaces.length > 0 &&
                    _vm.spiderSpaces[14]
                  )?_c('div',{staticClass:"text-center",style:('color: ' +
                    _vm.spiderSpaces[14]?.color +
                    '; position: absolute; left: 28%; top: 6%;')},[_c('img',{staticClass:"m-auto",attrs:{"src":require("@/assets/images/2x_certify/entrepreneurship.svg")}}),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.spiderSpaces[14]?.domain))])]):_vm._e()],1)])])]):_vm._e(),_c('div'),_vm._l((_vm.structuredData),function(dimension,index){return _c('div',{key:index,staticClass:"chart-box mt-8 py-8",style:({ display: dimension.show ? 'block' : 'none' })},[_c('div',{staticClass:"flex justify-between px-8"},[_c('h3',{staticClass:"text-2xl tracking-tighter font-bold"},[_vm._v(" "+_vm._s(dimension.title)+" ")]),(dimension.isActive)?_c('img',{staticClass:"cursor-pointer",attrs:{"src":require("@/assets/images/arrow-up.svg")},on:{"click":function($event){dimension.isActive = !dimension.isActive}}}):_vm._e(),(!dimension.isActive)?_c('img',{staticClass:"cursor-pointer",attrs:{"src":require("@/assets/images/arrow-down.svg")},on:{"click":function($event){dimension.isActive = !dimension.isActive}}}):_vm._e()]),(dimension.isActive)?_c('div',{staticClass:"flex flex-col"},_vm._l((dimension.indicators),function(indicator,j){return _c('div',{key:j,staticClass:"CriteriaTitleText p-8 flex flex-col justify-between px-8",staticStyle:{"border-bottom":"1px solid #ccc"},style:({ display: indicator.hasValues ? 'block' : 'none' })},[(
                  dimension?.triangle &&
                  Array.isArray(dimension?.triangle) &&
                  dimension?.triangle.length
                )?_c('div',{staticClass:"flex"},[_c('triangle-chart',{staticClass:"z-10 overflow-visible",staticStyle:{"height":"100%","width":"100%"},attrs:{"iso":_vm.comapnyDetails[0]?.countryHq?.iso,"paddingL":40,"paddingR":40,"paddingT":40,"paddingB":40,"details":_vm.comapnyDetails[0],"countData":_vm.countData,"country":_vm.country,"info":dimension?.triangle}})],1):_vm._e(),_c('div',{staticClass:"text-black-90 font-arial tracking-normal text-[20px] font-bold leading-[28px] flex justify-between w-full"},[_c('p',[_vm._v(_vm._s(indicator.title))]),(indicator.isActive)?_c('img',{staticClass:"cursor-pointer",attrs:{"src":require("@/assets/images/arrow-up.svg")},on:{"click":function($event){indicator.isActive = !indicator.isActive}}}):_vm._e(),(!indicator.isActive)?_c('img',{staticClass:"cursor-pointer",attrs:{"src":require("@/assets/images/arrow-down.svg")},on:{"click":function($event){indicator.isActive = !indicator.isActive}}}):_vm._e()]),(!indicator.isActive)?_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"flex w-full items-center justify-end my-[.8rem]"},[_c('img',{staticClass:"cursor-pointer",attrs:{"src":require("@/assets/images/2x_certify/Button.svg")},on:{"click":function($event){_vm.$router.push({
                        path: `/2X-Ecosystem/2X-Certification/sub-dimension?q=${encodeURIComponent(
                          indicator.title
                        )}`,
                      })}}})]),_c('div',{staticClass:"flex flex-wrap gap-4 grid-cols-2"},_vm._l((indicator?.values),function(item,k){return _c('dynamic-render',{key:k,attrs:{"type":item.chartType,"info":item}})}),1)]):_vm._e()])}),0):_vm._e()])}),_c('div',{staticClass:"mt-4"},[_c('twox-portfolio-grid-table')],1)],2)]),_c('br')],1):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"cursor":"pointer","margin-top":"3px"}},[_c('img',{attrs:{"src":require("../../assets/images/DownloadBtn.png"),"alt":""}}),_vm._v(" Download XLSX ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"items-center"},[_c('div',[_c('h3',{staticClass:"text-2xl tracking-tighter font-bold"},[_vm._v(" 2X Assessment Results ")])]),_c('div',{staticClass:"mt-8 flex gap-6"},[_c('div',{staticClass:"flex gap-1 text-sm font-normal items-center"},[_c('div',{staticStyle:{"width":"24px","height":"4px","background":"#e8ad17"}}),_vm._v(" 2024 ")])])])
}]

export { render, staticRenderFns }
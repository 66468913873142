<template>
  <div
    class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 transition-all ease-in-out duration-300"
  >
    <div
      class="bg-white z-[100] rounded-lg shadow-2xl w-full max-w-4xl max-h-[90vh] overflow-hidden flex flex-col transition-transform transform scale-95"
    >
      <!-- Modal Header -->
      <div
        class="p-6 bg-gradient-to-r from-gray-100 to-gray-200 border-b border-gray-300 flex justify-between items-center rounded-t-lg"
      >
        <h2 class="text-2xl font-semibold text-gray-900 tracking-tight">
          Review Your Responses
        </h2>
        <button
          @click="closeModal"
          class="text-gray-500 hover:text-gray-800 transition-colors ease-in-out duration-300"
        >
          <svg
            class="w-7 h-7"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
      </div>

      <div v-if="isLoading" class="flex items-center justify-center h-[71vh]">
        <div class="flex space-x-2 animate-pulse">
          <div class="w-3 h-3 bg-[#0077C8] rounded-full"></div>
          <div class="w-3 h-3 bg-[#0077C8] rounded-full"></div>
          <div class="w-3 h-3 bg-[#0077C8] rounded-full"></div>
        </div>
      </div>
      <div v-else class="overflow-y-auto flex-grow p-8 bg-gray-50">
        <div
          v-for="(module, moduleIndex) in groupedResponses"
          :key="moduleIndex"
          class="mb-8"
        >
          <div class="flex justify-between items-center mb-6">
            <h3 class="text-2xl font-bold text-gray-900 tracking-wide pl-3">
              {{ surveyMap[module.surveyId] }}
            </h3>
            <EquiloButton
              text="EDIT"
              bgColor="primary"
              classes="[ h-9 ][ px-6 font-semibold text-sm ][ hover:text-black hover:bg-transparent ][ transition-all duration-300 ease-in-out ]"
              @on-click="editModule(module.surveyId)"
            />
          </div>
          <div
            v-for="(response, responseIndex) in module.surveyResponse"
            :key="responseIndex"
            class="mb-6 bg-white shadow-sm p-5 rounded-lg border border-gray-200 transition-shadow hover:shadow-md"
          >
            <p class="font-medium text-lg text-gray-900 mb-3">
              {{ response.title }}
            </p>
            <div v-if="Array.isArray(response.answer)" class="pl-6">
              <ul class="list-disc text-gray-700 space-y-1">
                <li
                  v-for="(item, itemIndex) in response.answer"
                  :key="itemIndex"
                  class="text-gray-700"
                >
                  {{ item }}
                </li>
              </ul>
            </div>
            <p v-else class="text-gray-700 pl-6">{{ response.answer }}</p>
          </div>
        </div>
      </div>

      <div
        class="p-6 bg-gradient-to-r from-gray-100 to-gray-200 border-t border-gray-300 rounded-b-2xl"
      >
        <EquiloButton
          text="Submit Responses"
          bgColor="primary"
          classes="[ w-full h-14 ][ font-semibold text-lg ][ hover:text-black hover:bg-transparent ][ transition-all duration-300 ease-in-out ]"
          @on-click="submitAssessment()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import apiService from "@/helpers/apiServices.js";
import EquiloButton from "@/components/basics/EquiloButton.vue";
export default {
  name: "ReviewSurveyModules",
  props: {
    uuid: String,
  },
  components: {
    EquiloButton,
  },
  data() {
    return {
      groupedResponses: [],
      surveyMap: {
        "2x-intake": "General Intake",
        "2x-governance": "Governance & Accountability",
        "2x-entrepreneurship": "Entrepreneurship & Ownership",
        "2x-lead": "Leadership",
        "2x-employment": "Employment",
        "2x-supply-chain": "Supply Chain",
        "2x-products-services": "Products & Services",
        "2x-commitment": "Additional 2X Commitment(s)",
        "2x-portfolio": "Portfolio",
        "2x-esg": "Basic 2X ESG",
      },
      isLoading: true,
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    editModule(moduleId) {
      this.$emit("edit-module", moduleId);
    },
    submitAssessment() {
      this.$emit("submit-assessment");
    },
  },
  async mounted() {
    try {
      const response = await apiService.fetchAnsweredSurveyModules(
        "2x",
        this.uuid
      );
      this.groupedResponses = response.data.data.toReversed();
      this.isLoading = false;
    } catch (error) {
      console.error("Error: ", error);
    } finally {
      this.isLoading = false;
    }
  },
};
</script>

<template>
  <div class="flex flex-col mx-auto w-11/12 mt-12 mb-12">
    <a-steps
      progress-dot
      :current="currentStep"
      size="small"
      @change="handleStepChange"
    >
      <a-step
        :class="{'pointer-events-none' : $store.state.certPageAccess.isMinSurveyFill}"
        title="Step 1"
        description="2X Challenge Self-Assessment"
        v-if="
          !$store.state.certPageAccess.isFullSurveyFill
        "
      />
      <a-step
        title="Step 2"
        description="2X Challenge Self-Assessment Results"
        v-if="
          !$store.state.certPageAccess.isFullSurveyFill
        "
      />
      <a-step
        :class="{'pointer-events-none' : $store.state.certPageAccess.isFullSurveyFill}"
        :title="$store.state.certPageAccess.isFullSurveyFill ? 'Step 1' : 'Step 3'"
        description="2X Certification Self-Assessment"
      />
      <a-step
        :class="{'pointer-events-none' : !$store.state.certPageAccess.isFullSurveyFill}"
        :title="$store.state.certPageAccess.isFullSurveyFill ? 'Step 2' : 'Step 4'"
        description="Indicative Results"
      />
      <a-step
        :class="{'pointer-events-none' : $store.state.certPageAccess.isGlobalPage}"
        :title="$store.state.certPageAccess.isFullSurveyFill ? 'Step 3' : 'Step 5'"
        v-if="$store.state.certPageAccess.isVerificationPage"
        description="Third Party Verification"
      />
      <a-step
        :class="{'pointer-events-none' : !($store.getters.hasPurchasedCertificateCost)}"
        :title="$store.state.certPageAccess.isFullSurveyFill ? 'Step 3' : 'Step 5'"
        v-else
        description="Upload Documents"
      />
      <a-step
        :class="{'pointer-events-none' : !$store.state.certPageAccess.isGlobalPage}"
        :title="$store.state.certPageAccess.isFullSurveyFill ? 'Step 4' : 'Step 6'"
        description="2X Global Certification"
      />
      <a-step
        :class="{'pointer-events-none' : !$store.state.certPageAccess.isGlobalPage}"
        :title="$store.state.certPageAccess.isFullSurveyFill ? 'Step 5' : 'Step 7'"
        description="Take Action"
      />
    </a-steps>
  </div>
</template>

<script>
import { useUserStore } from "@/stores/user";

export default {
  name: "StepsComponentTwox",
  setup() {
    const userStore = useUserStore();
    return { userStore };
  },
  props: {
    currentStep: Number,
    userStatus: String,
    isAllFullSurveysCompleted: Boolean,
  },
  data: function () {
    return {
      isFullVerify: ["Validation in Process", "Full Survey Submitted"],
    };
  },
  methods: {
    handleStepChange(step) {
      if (
        this.$store.state.certPageAccess.isFullSurveyFill
      ) {
        switch (step) {
          case 0:
            if (this.$store.state.certPageAccess.isFullSurveyFill) break;
            this.$router.push({ path: "/2X-Challenge/2X-Challenge-Self-Assessment" });
            break;
          case 1:
            if (!this.$store.state.certPageAccess.isFullSurveyFill) break;
            this.$router.push({
              path: "/2X-Ecosystem/2X-Certification/IndicativeResults",
            });
            break;
          case 2:
            if (this.$store.state.certPageAccess.isGlobalPage) break;
            if (this.$store.state.certPageAccess.isVerificationPage) {
              this.$router.push({
                path: "/2X-Ecosystem/2X-Certification/Verification",
              });
            } else {
              this.$router.push({
                path: "/2X-Ecosystem/2X-Certification/UploadDocuments",
              });
            }
            break;
          case 3:
          if (!this.$store.state.certPageAccess.isGlobalPage) break;
            this.$router.push({
              path: "/2X-Ecosystem/2X-Certification/GlobalCertification",
            });
            break;
          case 4:
          if (!this.$store.state.certPageAccess.isScoreCardPage) break;
            this.$router.push({
              path: "/2X-Ecosystem/2X-Certification/score-card",
            });
            break;
          default:
            break;
        }
      } else {
        switch (step) {
          case 0:
            if (this.$store.state.certPageAccess.isMinSurveyFill) break;
            this.$router.push({ path: "/2X-Challenge/2X-Challenge-Self-Assessment" });
            break;
          case 1:
            if (!this.$store.state.certPageAccess.isMinSurveyFill) break;
            this.$router.push({
              path: "/2X-Ecosystem/2X-Certification/IndicativeResults",
            });
            break;
          case 2:
            if (this.$store.state.certPageAccess.isFullSurveyFill) break;
            this.$router.push({ path: "/2X-Ecosystem/2X-Certification/2X-Certification-Self-Assessment" });
            break;
          case 3:
            if (!this.$store.state.certPageAccess.isFullSurveyFill) break;
            this.$router.push({
              path: "/2X-Ecosystem/2X-Certification/IndicativeResults",
            });
            break;
          case 4:
            if (!this.$store.state.certPageAccess.isUploadDocPage) break;
            if (this.$store.state.certPageAccess.isVerificationPage) {
              this.$router.push({
                path: "/2X-Ecosystem/2X-Certification/Verification",
              });
            } else {
              this.$router.push({
                path: "/2X-Ecosystem/2X-Certification/UploadDocuments",
              });
            }
            break;
          case 5:
            if (!this.$store.state.certPageAccess.isGlobalPage) break;
            this.$router.push({
              path: "/2X-Ecosystem/2X-Certification/GlobalCertification",
            });
            break;
          case 6:
            if (!this.$store.state.certPageAccess.isScoreCardPage) break;
            this.$router.push({
              path: "/2X-Ecosystem/2X-Certification/score-card",
            });
            break;

          default:
            break;
        }
      }
    },
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"text-black-90 font-arial tracking-normal text-[20px] font-bold leading-[28px] flex w-full"},[_c('div',{staticClass:"flex flex-1 w-full items-center justify-between mr-5"},[_c('h4',{staticClass:"text-xl pb-6"},[_vm._v(" "+_vm._s(_vm.indicator.title == "General" ? "General Information" : _vm.indicator.title)+" ")])]),(_vm.indicator.title.toLowerCase().trim() != 'general')?_c('router-link',{staticClass:"pr-5 ml-auto",attrs:{"to":!_vm.query
          ? `${_vm.subDimensionPath}?q=${encodeURIComponent(
              _vm.indicator.title
            )}&fromVerifier=true`
          : `${_vm.subDimensionPath}?q=${encodeURIComponent(
              _vm.indicator.title
            )}&teamId=${_vm.query}&entity=${_vm.entityChosen}&fromVerifier=true`}},[_c('img',{staticClass:"cursor-pointer",attrs:{"src":require("@/assets/images/2x_certify/Button.svg")}})]):_vm._e()],1),_c('div',{staticClass:"flex flex-col w-full"},[_c('div',{staticClass:"flex flex-wrap w-full grid-cols-2"},[(_vm.indicator.values && _vm.indicator.values.length > 0)?_c('div',_vm._l((_vm.groups),function(group,index){return _c('div',{key:index},[(group.type == 'Donut chart')?_c('div',[_c('DonutIndicatorView',{attrs:{"values":group.values},on:{"openEditModal":_vm.openEditModal,"setVerifiedStatus":_vm.setVerifiedStatus}})],1):(group.type == 'Text')?_c('div',[_c('OpenTextView',{attrs:{"values":group.values},on:{"openEditModal":_vm.openEditModal,"setVerifiedStatus":_vm.setVerifiedStatus}})],1):(group.type == 'CustomTable')?_c('div',[_c('CustomTableView',{attrs:{"values":group.values},on:{"openEditModal":_vm.openEditModal,"setVerifiedStatus":_vm.setVerifiedStatus}})],1):(group.type == 'List')?_c('div',[_c('ListIndicatorView',{attrs:{"values":group.values},on:{"openEditModal":_vm.openEditModal,"setVerifiedStatus":_vm.setVerifiedStatus}})],1):(group.type == 'Clustered')?_c('div',_vm._l((group.values),function(value,k){return _c('div',{key:k},[_c('ClusteredChart',{attrs:{"info":value,"certificationScoreData":_vm.certificationScoreData}})],1)}),0):_vm._e(),(group.type === 'Number' || group.type === 'Bar graph')?_c('div',{staticClass:"w-full flex justify-between"},_vm._l((group.values),function(value,k){return _c('div',{key:k},[_c('dynamic-render',{attrs:{"type":value.chartType,"info":value,"certificationScoreData":_vm.certificationScoreData}}),_c('div',{staticClass:"flex gap-2 items-start"},[(
                    _vm.$route.path.includes('/admin') &&
                    value?.surveyQType != 'Calculated'
                  )?_c('div',{staticClass:"flex items-center gap-3 justify-end"},[_c('img',{staticClass:"h-6 w-6 cursor-pointer",attrs:{"src":require("@/assets/images/edit-dark.svg"),"alt":"edit-icon"},on:{"click":function($event){return _vm.openEditModal(value)}}}),_c('verification-dropdown',{attrs:{"value":value}}),_c('MLVerifierValue',{attrs:{"value":value,"teamId":_vm.$route.query?.teamId}})],1):_vm._e()])],1)}),0):_vm._e()])}),0):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
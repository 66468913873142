<template>
  <div data-modal="accept-terms" v-if="openCookiePermission">
    <div class="overlay"></div>
    <div class="wr">
      <div class="px-[60px] py-5"><CookiePolicyContent /></div>
      <div class="btn-wr">
        <div class="btn cookie-tooltip m-auto" @click="acceptTerms">
          <span class="lang-tooltiptext notranslate"
            >Only by accepting the Cookie Policy, you may proceed to Equilo's
            web page</span
          >
          Accept
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "@/helpers/cookies";
import CookiePolicyContent from "./CookiePolicyContent.vue";

export default {
  name: "CookiePolicyPopUp",
  data: function () {
    return {
      openCookiePermission: false,
    };
  },
  props: {
    user: Object,
  },
  components: {
    CookiePolicyContent,
  },
  methods: {
    async acceptTerms() {
      Cookies.set(`equilo-cookie-consent-${this.user._id}`, true, 180);
      this.openCookiePermission = false;
    },
    denyTerms() {
      this.$emit("cookie-policy-rejected");
    },
  },
  watch: {
    user(newValue) {
      if (newValue && !Cookies.get(`equilo-cookie-consent-${newValue._id}`)) {
        this.openCookiePermission = true;
      }
    },
  },
};
</script>

<style>
.cookie-tooltip {
  position: relative;
  display: inline-block;
}

.cookie-tooltip .lang-tooltiptext {
  visibility: hidden;
  width: 360px;
  background-color: #6b787f;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 20px;
  position: absolute;
  z-index: 9999;
  top: -54px;
  left: -100px;
}

.cookie-tooltip:hover .lang-tooltiptext {
  visibility: visible;
}

.cookie-tooltip .lang-tooltiptext::after {
  right: 95%;
}
</style>

<template>
  <div class="static ecosystem-landing">
    <div class="container mx-auto px-4 lg:px-0 pb-32">
      <!--Logo-->
      <div class="PoweredbyTitle_logo">
        <div class="PoweredbyContentWrepper">
          <img
            src="@/assets/images/2X-Global_logo_transparent.svg"
            alt="background-image"
            style="height: 50px"
          />
        </div>
        <div style="display: flex; justify-content: flex-end">
          <div class="Poweredby_logoImg mt-2">
            <div class="powered-text flex">
              <span>Powered by:</span>
            </div>
            <img src="@/assets/images/logo-navy.png" alt="background-image" />
          </div>
        </div>
      </div>
      <!-- Content Wrepper -->
      <div class="ContentWrapper w-12/12">
        <div class="container mx-auto">
          <steps-component-twox
            :currentStep="['Validation in Process', 'Full Survey Submitted'].includes(user.status2xCertification) ? 2 : current_step"
            :userStatus="user.status2xCertification"
          />
          <!-- <div class="flex flex-col mx-auto w-11/12 mt-12 mb-12">
            <a-steps
              progress-dot
              :current="this.current_step"
              size="small"
              @change="progressClick"
            >
              <a-step
                title="Step 1"
                description="2X Challenge Self-Assessment"
              />
              <a-step
                title="Step 2"
                description="2X Challenge Self-Assessment Results"
              />
              <a-step
                title="Step 3"
                description="2X Certification Self-Assessment"
              />
              <a-step
                title="Step 4"
                description="2X Certification Self-Assessment Indicative Results "
              />
              <a-step title="Step 5" description="Third Party Verification" />
              <a-step title="Step 6" description="2X Global Certification" />
              <a-step title="Step 7" description="Take Action" />
            </a-steps>
          </div> -->
        </div>
        <!-- start validation rejection in progress div -->
        <div id="rejection-validation-section" class="mb-10">
          <div class="container-div flex items-center mb-3">
            <div class="flex">
              <h1 class="text-6xl tracking-tighter font-medium print:text-7xl">
                <span style="font-family: Work Sans"
                  >Verification in Progress</span
                >
              </h1>
            </div>
            <button
              class="rejection-validation-button flex gap-2 items-center font-bold"
              v-if="user?.status2xCertification == 'Validation Denies Certification'"
            >
              <img src="@/assets/images/2x_certify/validation-close.svg" />
              <span>Revisions required</span>
            </button>
            <button
              class="validation-button flex gap-2 items-center font-bold"
              v-if="user?.status2xCertification == 'Validation in Process'"
            >
              <img src="@/assets/images/2x_certify/in-review-eye.svg" />
              <span>Verification process: 5 days</span>
            </button>
            <button
              class="approved-validation-button flex gap-2 items-center font-bold"
              v-if="user?.status2xCertification == 'Validation Recommends Certification'"
            >
              <img src="@/assets/images/2x_certify/check.svg" />
              <span>Approved</span>
            </button>
          </div>
          <div class="congrats-box p-8 text-center" v-if="user?.status2xCertification == 'Validation Denies Certification'">
            <div class="rejection-three-columns-grid">
              <img
                v-if="
                  documentStatus == 'Rejected'
                "
                class="mx-auto mb-3"
                src="@/assets/images/2x_certify/Sad.svg"
              />
              <img
                v-if="documentStatus == 'Approved'"
                class="mx-auto mb-3"
                src="@/assets/images/happy-smile.svg"
              />
              <div class="rejection-title mt-3 mb-5">
                Unfortunately, the documents you uploaded did not meet the
                requirements to verify your assessment results. Here is the
                feedback from the 2X Global Accredited Certification Partner to
                correct this:
              </div>
              <div class="rejection-box mt-3 mb-5">
                <p
                  v-for="(list, i) in filelist"
                  :key="list.name"
                  class="mb-2"
                  v-show="list.status == 'Rejected'"
                >
                  {{ i + 1 }}.Document {{ list.name }}: The provided document
                  XYZ did not meet the required format specifications. We kindly
                  request you to review the document and resubmit it in the
                  correct format as outlined in our verify guidelines.
                </p>
                <p
                  v-for="(list, i) in filelist"
                  :key="list.name"
                  class="mb-2"
                  v-show="documentStatus == 'In Review'"
                >
                  {{ i + 1 }}.Document {{ list.name }}: In Review
                </p>
              </div>
              <span
                class="rejection-title mt-3 mb-5"
                v-if="documentStatus == 'Rejected'"
              >
                Actions to take:
              </span>
              <div
                class="rejection-box mt-3 mb-5"
                v-if="documentStatus == 'Rejected'"
              >
                <p>Change the documents</p>
              </div>
            </div>
          </div>
        </div>
        <!-- end validation rejection in progress div -->

        <!-- start Verification in Progress div -->
        <div id="assessment-section" class="mb-10">
          <!-- <div class="container-div flex items-center mb-3">
            <div class="flex">
              <h1>
                <span>Verification in Progress</span>
              </h1>
            </div>
            <button class="validation-button flex gap-2 items-center font-bold">
              <img src="@/assets/images/2x_certify/validation-eye.svg" />
              <span>Validation process: 5 days</span>
            </button>
          </div> -->
          <div class="congrats-box p-5 text-center">
            <div class="three-columns-grid">
              <div>
                <img
                  class="validation-img-width"
                  src="@/assets/images/right-validation-Icons.svg"
                />
              </div>
              <div>
                <h3 class="tracking-tighter font-bold">
                  See results of your 2X Challenge Self-Assessment
                </h3>

                <p style="color: #717686" class="mt-3 mb-5">
                  Curious to see your self-assessment results? View your instant
                  results to see which 2X Criteria Thresholds you met and areas
                  of improvement. Prior to verification and potential
                  Certification in review, we encourage you to review your
                  results and gaps, and take a look at resources including
                  Gender Smart Tools, Technical Service Provider Directory, and
                  a GESI Contextual Analysis for country and sector.
                </p>
                <button
                  class="container certification-button font-bold flex justify-center items-center gap-3 mt-3 px-3 py-2"
                  @click="$router.push({path: '/2X-Ecosystem/2X-Certification/IndicativeResults'})"
                >
                  <span class="pr-1">View</span>
                </button>
              </div>
              <div>
                <div>
                  <img
                    class="validation-img-width"
                    src="@/assets/images/left-validation-icons.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end Verification in Progress div -->

        <!-- start 2X Criteria Thresholds div -->
        <div id="threshold-section" class="mb-10">
          <div class="container items-center mb-3">
            <div class="flex items-center">
              <h2 class="mr-4">
                <span>2X Criteria Thresholds</span>
              </h2>
              <router-link
                style="width: max-content; color: white !important"
                to="/2X-Ecosystem/Thresholds"
                class="container certification-button font-bold gap-3 px-3 py-2"
              >
                <span class="pr-1">View</span>
              </router-link>
            </div>
          </div>
          <!-- <div class="text-center">
            <div class="five-columns-grid">
              <div class="five-grid-child-div">
                <div class="flex section-min-height">
                  <img src="@/assets/images/2x_certify/entrepreneurship.svg" />
                  <span class="threshold-title"
                    >Ownership & Entrepreneurship</span
                  >
                </div>
                <p class="threshold-description">
                  <span
                    style="font-size: 20px; color: #19486A"
                    class="font-bold"
                    >51%</span
                  >
                  of
                  <span class="font-bold" style="color: #19486A"
                    >shares owned by women</span
                  >
                  OR it was
                  <span class="font-bold" style="color: #19486A"
                    >founded by a woman</span
                  >
                  who remains an active owner
                </p>
              </div>
              <div class="five-grid-child-div">
                <div class="flex section-min-height">
                  <img src="@/assets/images/2x_certify/products.svg" />
                  <span class="threshold-title">Products & Services</span>
                </div>
                <p class="threshold-description">
                  <span class="font-bold" style="color: #19486A">At least</span
                  ><span
                    style="font-size: 20px; color: #19486A"
                    class="font-bold"
                  >
                    1
                  </span>
                  <span class="font-bold" style="color: #19486A"
                    >practice
                  </span>
                  place that result in products or services that specifically
                  benefit women
                </p>
              </div>
              <div class="five-grid-child-div">
                <div class="flex section-min-height">
                  <img src="@/assets/images/2x_certify/employment.svg" />
                  <span class="threshold-title">Employment</span>
                </div>
                <p class="threshold-description">
                  <span
                    style="font-size: 20px; color: #19486A"
                    class="font-bold"
                  >
                    45%
                  </span>
                  <span class="font-bold" style="color: #19486A"
                    >women in the workforce OR at least one quality
                    workforce</span
                  >
                  indicator in place beyond what is legally required
                </p>
              </div>
              <div class="five-grid-child-div">
                <div class="flex section-min-height">
                  <img src="@/assets/images/2x_certify/leadership.svg" />
                  <span class="threshold-title">Leadership</span>
                </div>
                <p class="threshold-description">
                  <span
                    style="font-size: 20px; color: #19486A"
                    class="font-bold"
                    >35%
                  </span>
                  <span class="font-bold" style="color: #19486A"
                    >senior managers are women</span
                  >
                  OR
                  <span class="font-bold" style="color: #19486A"
                    >35% of board members are women</span
                  >
                </p>
              </div>
              <div class="five-grid-child-div">
                <div class="flex section-min-height">
                  <img src="@/assets/images/2x_certify/managementsystems.svg" />
                  <span class="threshold-title">Governance Dimension</span>
                </div>
                <p class="threshold-description">
                  <span class="font-bold" style="color: #19486A">At least </span
                  ><span
                    style="font-size: 20px; color: #19486A"
                    class="font-bold"
                    >1</span
                  >
                  practice that supports good governance for gender equality
                  must be in place.
                </p>
              </div>
              <div class="five-grid-child-div">
                <div class="flex section-min-height">
                  <img src="@/assets/images/2x_certify/supplychain.svg" />
                  <span class="threshold-title">Supply chain</span>
                </div>
                <p class="threshold-description">
                  <span
                    style="font-size: 20px; color: #19486A"
                    class="font-bold"
                    >45%</span
                  >
                  <span class="font-bold" style="color: #19486A"
                    >of suppliers are women owned
                  </span>
                  at least
                  <span
                    style="font-size: 20px; color: #19486A"
                    class="font-bold"
                    >30%</span
                  >
                  of suppliers have at least one quality workforce indicator in
                  place beyond what is legally required.
                </p>
              </div>
              <div class="five-grid-child-div">
                <div class="flex section-min-height">
                  <img src="@/assets/images/2x_certify/portfolio.svg" />
                  <span class="threshold-title">Portfolio</span>
                </div>
                <p class="threshold-description">
                  At least
                  <span
                    style="font-size: 20px; color: #19486A"
                    class="font-bold"
                  >
                    30%</span
                  >
                  of investees meet at least one direct 2X Criteria OR, in the
                  case of a new fund, commits
                  <span class="font-bold" style="color: #19486A"
                    >30% of funding
                  </span>
                  be directed to investees that meet at least one 2X Direct
                  Criteria
                </p>
              </div>
            </div>
          </div> -->
        </div>
        <!-- end 2X Criteria Thresholds div -->

        <!-- start upload documets div -->
        <div id="upload-documets-section" class="">
          <div class="container-div flex items-center mb-3">
            <div class="flex">
              <h1>
                <span>Uploaded documents</span>
              </h1>
            </div>
            <div
              @click="documentsModal = true"
              class="flex gap-2 items-center font-bold cursor-pointer"
              style="color: #3F7E44"
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.16675 4.83342H9.83342V6.50008H8.16675V4.83342ZM8.16675 8.16675H9.83342V13.1667H8.16675V8.16675ZM9.00008 0.666748C4.40008 0.666748 0.666748 4.40008 0.666748 9.00008C0.666748 13.6001 4.40008 17.3334 9.00008 17.3334C13.6001 17.3334 17.3334 13.6001 17.3334 9.00008C17.3334 4.40008 13.6001 0.666748 9.00008 0.666748ZM9.00008 15.6667C5.32508 15.6667 2.33341 12.6751 2.33341 9.00008C2.33341 5.32508 5.32508 2.33341 9.00008 2.33341C12.6751 2.33341 15.6667 5.32508 15.6667 9.00008C15.6667 12.6751 12.6751 15.6667 9.00008 15.6667Z"
                  fill="#3F7E44"
                />
              </svg>
              <span>What documents should I upload?</span>
            </div>
          </div>
          <!-- start rejection upload documents section -->
          <div class="validation-congrats-box p-5 mb-5 text-center" id="app">
            <div class="flex w-full items-center justify-center text-center">
              <div>
                <input
                  type="file"
                  multiple
                  name="fields[assetsFieldHandle][]"
                  id="assetsFieldHandle"
                  class="w-px h-px opacity-0 overflow-hidden absolute"
                  ref="file"
                  @change="onChange"
                />
                <label for="assetsFieldHandle" class="block cursor-pointer">
                  <div class="center-button">
                    <span
                      class="upload-documents-button px-3 py-2 gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center"
                    >
                      Choose files
                    </span>
                  </div>

                  <p class="center-span">Drag one or more files here</p>
                </label>
              </div>
            </div>
          </div>
          <!-- end rejection upload documents section -->

          <div
            class="relative overflow-x-auto document-upload-table-section"
            v-if="filelist.length"
            v-cloak
          >
            <!-- start in-review table section -->
            <!-- <table
                  class="w-full text-sm text-left text-gray-500 dark:text-gray-400"
                >
                  <thead
                    class="bg-grey text-gray-700 bg-gray-100 dark:bg-gray-700 dark:text-gray-400 text-base"
                  >
                    <tr class="border">
                      <th scope="col" class="px-6 py-3">Document</th>
                      <th scope="col" class="px-6 py-3">Date uploaded</th>
                      <th scope="col" class="px-6 py-3">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="border dark:bg-gray-800 dark:border-gray-700 text-base"
                    >
                      <td
                        scope="row"
                        class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                        style="
                          text-decoration: underline;
                          text-decoration-thickness: 2px;
                          text-decoration-color: #19486A;
                        "
                      >
                        Letter_of_approval.docx
                      </td>
                      <td
                        class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        22 June, 2023
                      </td>
                      <td class="px-6 py-4">
                        <button
                          class="table-validation-button flex gap-2 items-center font-bold"
                        >
                          <img
                            src="@/assets/images/2x_certify/validation-eye.svg"
                          />
                          <span>In review</span>
                        </button>
                      </td>
                    </tr>
                    <tr
                      class="border dark:bg-gray-800 dark:border-gray-700 text-base"
                    >
                      <td
                        scope="row"
                        class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                        style="
                          text-decoration: underline;
                          text-decoration-thickness: 2px;
                          text-decoration-color: #19486A;
                        "
                      >
                        Letter_of_approval.docx
                      </td>
                      <td
                        class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        10 June, 2023
                      </td>
                      <td class="px-6 py-4">
                        <button
                          class="table-validation-button flex gap-2 items-center font-bold"
                        >
                          <img
                            src="@/assets/images/2x_certify/validation-eye.svg"
                          />
                          <span>In review</span>
                        </button>
                      </td>
                    </tr>
                    <tr
                      class="border dark:bg-gray-800 dark:border-gray-700 text-base"
                    >
                      <td
                        scope="row"
                        class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                        style="
                          text-decoration: underline;
                          text-decoration-thickness: 2px;
                          text-decoration-color: #19486A;
                        "
                      >
                        Letter_of_approval.docx
                      </td>
                      <td
                        class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        15 June, 2023
                      </td>
                      <td class="px-6 py-4">
                        <button
                          class="table-validation-button flex gap-2 items-center font-bold"
                        >
                          <img
                            src="@/assets/images/2x_certify/validation-eye.svg"
                          />
                          <span>In review</span>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table> -->
            <!-- end in-review table section -->

            <!-- start rejection table section -->
            <table
              class="w-full text-sm text-left text-gray-500 dark:text-gray-400"
            >
              <thead
                class="text-gray-700 bg-gray-100 dark:bg-gray-700 dark:text-gray-400 text-base"
              >
                <tr class="table-heading-row">
                  <th scope="col" class="px-6 py-3">Document</th>
                  <th scope="col" class="px-6 py-3">Date uploaded</th>
                  <th scope="col" class="px-6 py-3">Status</th>
                  <th scope="col" class="px-6 py-3">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="table-content-row dark:bg-gray-800 text-base"
                  v-for="(file, i) in filelist"
                  :key="i"
                >
                  <td
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    style="
                      text-decoration: underline;
                      text-decoration-thickness: 2px;
                      text-decoration-color: #19486A;
                    "
                  >
                    {{ file.name }}
                  </td>
                  <td
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {{ moment(file?.createdAt).format("DD MMMM,  YYYY") }}
                  </td>
                  <td class="px-6 py-4">
                    <button
                      class="approved-validation-button flex gap-2 items-center font-bold"
                      :style="
                        file.status == 'Approved'
                          ? 'background: #4dc9be'
                          : file.status == 'Rejected'
                          ? 'background: #FF3A21'
                          : 'background: #FCC30B'
                      "
                    >
                      <img
                        v-if="file.status == 'Approved'"
                        src="@/assets/images/2x_certify/check.svg"
                      />
                      <img
                        v-if="file.status == 'Rejected'"
                        src="@/assets/images/2x_certify/validation-close.svg"
                      />
                      <img
                        v-if="file.status == 'In Review'"
                        src="@/assets/images/2x_certify/in-review-eye.svg"
                      />
                      <span>{{ file.status }}</span>
                    </button>
                  </td>
                  <td class="px-6 py-4">
                    <div
                      class="flex gap-2 items-center font-bold cursor-pointer"
                      style="color: #FF3A21"
                      @click="removeDocument(file._id)"
                    >
                      <img
                        src="@/assets/images/remove-icon.svg"
                        alt="background-image"
                        style="width: 24px; padding: 2px"
                      />
                      <span>Remove</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- end rejection table section -->
          </div>

          <button
            class="save-upload-doc flex gap-2 items-center font-bold"
            v-if="saveDocuments"
          >
            <img src="@/assets/images/2x_certify/filled.svg" />
            <span
              style="
                font-family: Arial;
                font-weight: 400;
                font-size: 16px;
                color: #19486A;
              "
              >Your uploads have been saved</span
            >
          </button>
        </div>
        <!-- end upload documets div -->
      </div>

      <br />
    </div>

    <AssessmentResults class="mb-5" :hideResults="true" />

    <hr />
    <div class="container mx-auto flex justify-end py-9">
      <div class="flex">
        <button
          @click="saveDocumentsFiles()"
          class="save-documets-button mr-2 px-3 py-2 gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center"
        >
          <span class="pr-1">Save to continue later</span>
        </button>
        <button
          :style="
            filelist.length ? 'background: #19486A' : 'background: #e1e3e9'
          "
          class="send-documets-button px-3 py-2 gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center"
          @click="sendValidationRequest()"
        >
          <span class="pr-1">Send documents for verification</span>
        </button>
      </div>
    </div>
    <!-- certification partner Modal -->
    <AccreditedCertificationPartnerMOdal
      v-if="certificationpartnerModal"
      @close="closeCertificationModal"
    />
    <div class="fixed z-50 inset-0 overflow-y-auto" v-if="documentsModal">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity cursor-pointer">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
        >&#8203;
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style="max-width: 660px; width: 100%"
        >
          <span
            @click="closeDocumentsModal()"
            class="close z-10"
            style="right: 16px; top: 5px"
            >&times;</span
          >
          <div class="Modal_Readmorestyle" style="display: block">
            <div class="ModalInnerStyle">
              <h2 style="font-weight: 600; text-align: left; line-height: 32px">
                Documents
              </h2>
              <div>
                <p class="content">
                  Please upload any documentation that provides evidence of any
                  of your responses, including all responses to which you
                  responded "Yes", affirmed, or attested to during the 2X basic
                  ESG screen. These documents will be used by 2X Global's
                  accredited certification partner to review and verify your
                  assessment results. The more complete your documentation is,
                  the quicker the verification and review process will be. The
                  accredited certification partner will follow-up as needed.
                </p>
                <div
                  class="congrats-box p-5 mb-5 text-center mt-5"
                  id="app"
                  @dragover="dragover"
                  @drop="drop"
                >
                  <div
                    class="flex w-full items-center justify-center text-center"
                  >
                    <div>
                      <input
                        type="file"
                        multiple
                        name="fields[assetsFieldHandle][]"
                        id="assetsFieldHandle"
                        class="w-px h-px opacity-0 overflow-hidden absolute"
                        @change="onChange"
                        ref="file"
                      />
                      <label
                        for="assetsFieldHandle"
                        class="block cursor-pointer"
                      >
                        <div class="center-button">
                          <span
                            class="upload-documents-button px-3 py-2 gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center"
                          >
                            Choose files
                          </span>
                        </div>

                        <p class="center-span-modal">
                          Drag one or more files here
                        </p>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex mt-10 justify-between">
                <button
                  @click="closeDocumentsModal()"
                  class="modal-link-button px-3 py-2 text-xs gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center mr-2"
                >
                  Cancel
                </button>
                <button
                  @click="closeDocumentsModal()"
                  class="modal-send-button px-3 py-2 text-xs gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center mr-2"
                >
                  Upload
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Validation request sent prompt -->
    <button
      v-if="validationRequestSent"
      class="verification-request-sent validation-request-button flex gap-2 items-center font-bold"
    >
      <img src="@/assets/images/2x_certify/filled.svg" />
      <span
        style="
          font-family: Arial;
          font-weight: 400;
          font-size: 16px;
          color: #19486A;
        "
        >Verification request sent!</span
      >
    </button>
  </div>
</template>
<script>
import Cookies from "@/helpers/cookies";
import auth from "@/helpers/auth";
import axios from "axios";
import env from "@/../env";
import AccreditedCertificationPartnerMOdal from "@/components/AccreditedCertificationPartnerMOdal.vue";
import StepsComponentTwox from '../../components/StepsComponentTwox.vue';
import apiService from '@/helpers/apiServices.js';
import AssessmentResults from "../thirdPartyVerifier2X/assessmentResults.vue";

export default {
  name: "twoxCertifyVerification",
  data: function () {
    return {
      current_step: 3,
      user: [],
      certificationpartnerModal: false,
      saveDocuments: false,
      documentsModal: false,
      delimiters: ["${", "}"],
      filelist: [],
      removeDocuments: false,
      sendRequest: false,
      uploadedFileNames: [],
      documentStatus: "",
      validationRequestSent: false,
    };
  },
  components: {
    AccreditedCertificationPartnerMOdal,
    StepsComponentTwox,
    AssessmentResults
  },
  methods: {
    sendValidationRequest() {
      axios
        .post(
          env.apiUrl + `/certify2x/2x-certification-validation-request`,
          { validationInProcess: true },
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        )
        .then(async () => {
          this.validationRequestSent = true;
          setTimeout(() => {
            this.validationRequestSent = false;
          }, 3000);
        });
    },
    getAllDocuments() {
      apiService.fetchUploadDocumentsData(this.$route?.query?.teamId ? this.$route.query?.teamId : this.user?.organization?._id)
        .then(response => {
          this.filelist = response.data.data;
        });
    },
    removeDocument(id) {
      axios
        .delete(env.apiUrl + `/certify2x/verification-documents/${id}`, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        })
        .then(async () => {
          await this.getAllDocuments();
          await this.removeDocumentsFiles();
        });
    },
    addDocuments(data) {
      let body = {
        documents: data,
      };
      axios
        .post(env.apiUrl + `/certify2x/verification-documents`, body, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        })
        .then(async () => {
          await this.getAllDocuments();
        });
    },
    removeDocumentsFiles() {
      this.removeDocuments = true;
      setTimeout(() => {
        this.removeDocuments = false;
      }, 3000);
    },
    generateDocumentPresignURL() {
      let body = {
        files: this.uploadedFileNames,
        urlType: "upload",
        module: "CERTIFICATION_2X_VERIFICATION_DOCS",
      };
      axios
        .post(env.apiUrl + `/other/presignedUrl`, body, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        })
        .then(async (res) => {
          const fileRes = res.data.data;
          if (fileRes.length) {
            const fileData = [];
            fileRes.map((item) => {
              fileData.push({
                key: item.key,
                name: item.fileName,
              });
              axios
                .put(item.url, {
                  headers: {
                    Authorization: "Bearer " + Cookies.get("session"),
                  },
                })
                .then(async () => {});
            });
            this.addDocuments(fileData);
          }
        });
    },
    saveDocumentsFiles() {
      this.saveDocuments = true;
      setTimeout(() => {
        this.saveDocuments = false;
      }, 1000);
    },
    onChange() {
      let files = [];
      this.uploadedFileNames = [];
      files.push(...this.$refs.file.files);
      files.map((item) => {
        this.uploadedFileNames.push(item.name);
      });
      this.generateDocumentPresignURL();
    },
    remove(i) {
      this.filelist.splice(i, 1);
    },
    dragover(event) {
      event.preventDefault();
    },
    dragleave(event) {
      console.log(event);
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange();
    },
    closeDocumentsModal() {
      this.documentsModal = false;
    },
    openCertificationModal() {
      this.certificationpartnerModal = true;
    },
    closeCertificationModal() {
      this.certificationpartnerModal = false;
    },
    progressClick(step) {
      switch (step) {
        case 0:
          this.$router.push({ path: "/2X-Challenge/2X-Challenge-Self-Assessment" });
          break;
        case 1:
          this.$router.push({
            path: "/2X-Ecosystem/2X-Certification/IndicativeResults",
          });
          break;
        case 2:
          this.$router.push({ path: "/2X-Challenge/2X-Challenge-Self-Assessment" });
          break;
        case 3:
          this.$router.push({
            path: "/2X-Ecosystem/2X-Certification/IndicativeResults",
          });
          break;
        case 4:
          if(this.user.status2xCertification == 'Validation in Process') {
            this.$router.push({
              path: "/2X-Ecosystem/2X-Certification/UploadDocuments",
            });
          } else {
            this.$router.push({
              path: "/2X-Ecosystem/2X-Certification/Verification",
            });
          }
          break;
        case 5:
          if (
            this.validate2xStatus(
              this.user?.status2xCertification,
              "Validation in Process"
            )
          )
            break;
          else {
            this.$router.push({
              path: "/2X-Ecosystem/2X-Certification/GlobalCertification",
            });
            break;
          }
        case 6:
          if (
            this.validate2xStatus(
              this.user?.status2xCertification,
              "Validation in Process"
            )
          )
            break;
          else {
            this.$router.push({
              path: "/2X-Ecosystem/2X-Certification/score-card",
            });
            break;
          }
        default:
          break;
      }
    },
    validate2xStatus(userStatus, status) {
      return userStatus === status;
    },
  },
  async mounted() {
    this.$store.commit("resetCitations");
    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });
    await this.$store.commit("setCertPageStatus", this.user?.status2xCertification);
    if(!this.$store.state.certPageAccess.isVerificationPage) {
      this.$router.push({
        path: "/2X-Ecosystem/2X-Certification/IndicativeResults",
      });
    }
    if(this.$store.state.certPageAccess.isGlobalPage) {
      this.$router.push({
        path: "/2X-Ecosystem/2X-Certification/GlobalCertification",
      });
    }
    await this.getAllDocuments();
    this.filelist.forEach((file) => {
      if (file.status == "In Review") {
        this.documentStatus = "In Review";
      } else if (file.status == "Rejected") {
        this.documentStatus = "Rejected";
      } else if (file.status == "Approved") {
        this.documentStatus = "Approved";
      }
    });

    this.$store.commit("downloadable", false);
    this.$store.commit("isFreeState", true);
    this.$store.commit("setNewBreadcrumbs", [
      // { name: "Dashboard", url: "/" },
      { name: "2X Certification", url: "/2X-Ecosystem/2X-Certification" },
      { name: "Get Your 2X Certification", url: "/2X-Ecosystem/2X-Certification/2X-Certification-Self-Assessment" },
    ]);
  },
};
</script>

<style lang="scss" scoped>
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  top: -8px;
  position: relative;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.verification-request-sent {
  background: #e5fafa;
  padding: 16px;
  border-radius: 12px;
  float: right;
  margin-top: -200px;
  margin-right: 42%;
}
.save-upload-doc {
  background: #e5fafa;
  padding: 16px;
  border-radius: 12px;
  float: right;
  margin-top: 70px;
  margin-right: 8%;
}
.previous-button {
  font-weight: bold;
  font-size: 12px;
  color: #19486A;
  border-style: solid;
  border-color: #19486A;
  border-width: 1px;
  padding: 12px 24px 12px 24px;
}
.send-documets-button {
  font-weight: bold;
  font-size: 16px;
  color: white;
  border-style: solid;
  border-width: 1px;
  padding: 12px 24px 12px 24px;
}
.save-documets-button {
  font-weight: bold;
  font-size: 16px;
  color: #19486A;
  border-style: solid;
  border-color: #19486A;
  border-width: 1px;
  padding: 12px 24px 12px 24px;
}
.rejection-three-columns-grid {
  padding-left: 343px;
  padding-right: 343px;
  text-align: center;
}
.rejection-title {
  font-family: Arial;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #19486A;
}
.rejection-box {
  background: #dee0e3;
  text-align: left;
  color: #19486A;
  font-family: Arial;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 16px;
}
.three-columns-grid {
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr 3fr 1fr;
}

/* columns */
.three-columns-grid > * {
  padding: 1rem;
}
.five-columns-grid {
  display: grid;
  grid-gap: 20px;
  grid-auto-flow: dense;
  grid-template-columns: repeat(5, 1fr);
  .five-grid-child-div {
    border: 1px solid #ccc;
    border-radius: 20px;
    padding: 20px;
    .section-min-height {
      min-height: 50px;
      align-items: center;
    }
  }
  .threshold-title {
    font-family: Arial;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #19486A;
  }
  .threshold-description {
    font-family: Arial;
    font-size: 14px;
    line-height: 20px;
    margin-top: 15px;
    text-align: left;
  }
}

.validation-img-width {
  max-width: fit-content;
  margin-top: 5px;
}
.center-span {
  position: relative;
  top: 35px;
  line-height: 20px;
  font-family: arial;
  color: #19486A;
  right: 20px;
}
.center-button {
  position: relative;
  top: 27px;
}
.ContentWrapper {
  margin-top: 10px;
  .rejection-validation-button {
    color: white;
    background: #FF3A21;
    border-radius: 28px;
    padding: 12px 16px 12px 16px;
  }
  .validation-button {
    color: white;
    background: #FCC30B;
    border-radius: 28px;
    padding: 12px 16px 12px 16px;
  }
  .approved-validation-button {
    color: white;
    background: #4dc9be;
    border-radius: 28px;
    padding: 6px 12px 6px 12px;
  }
  .rejected-validation-button {
    color: white;
    background: #FF3A21;
    border-radius: 28px;
    padding: 6px 12px 6px 12px;
  }
  .table-validation-button {
    color: white;
    background: #FCC30B;
    border-radius: 28px;
    padding: 6px 12px 6px 12px;
  }
  .validation-button:focus {
    outline: none;
  }
  .table-validation-button:focus {
    outline: none;
  }
  .certification-button {
    color: white;
    background: #19486A;
    border-radius: 8px;
  }
  .certification-partner-button {
    font-weight: bold;
    font-size: 16px;
    color: #19486A;
    border-style: solid;
    border-color: #19486A;
    border-width: 1px;
    padding: 12px 24px 12px 24px;
    float: right;
  }
  .congrats-box {
    border: 1px solid #ccc;
    border-radius: 15px;
    padding-right: 50px;
    padding-left: 50px;
    height: auto;
    background: none;
  }
  .upload-documents-button {
    font-weight: bold;
    color: white;
    background: #3F7E44;
    width: 150px;
    padding: 8px 16px 8px 16px;
  }
  .validation-congrats-box {
    border: 1px solid #3F7E44;
    border-radius: 16px;
    padding: 32px, 0px, 32px, 0px;
    background: #eaf6ff;
    border-style: dashed;
    height: 160px;
  }
  .document-upload-table-section {
    border-radius: 16px;
    border: 1px solid #e1e3e9;
    table {
      th,
      td {
        border: 1px solid #e1e3e9;
      }
      .table-heading-row {
        th {
          gap: 32px;
          border-top: none;
          background: linear-gradient(0deg, #f4f6f9, #f4f6f9),
            linear-gradient(0deg, #e1e3e9, #e1e3e9);
          font-family: Arial;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
          color: #19486A;
        }
        th:first-child {
          border-top-left-radius: 16px;
          border-left: none;
        }
        th:last-child {
          border-top-right-radius: 16px;
          border-right: none;
        }
      }
      .table-content-row {
        img {
          width: 24px;
          height: 24px;
        }
        td {
          border-bottom: none;
          height: 68px;
          padding: 22px 24px;
          gap: 1px;
          font-family: Arial;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          color: #19486A;
        }
        td:first-child {
          border-left: none;
        }
        td:last-child {
          border-right: none;
        }
      }
    }
  }
}

h1 {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 45px;
  line-height: 40px;
  color: #19486A;
}
h2 {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #19486A;
}
h3 {
  font-family: Arial;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #19486A;
}

.bg-grey {
  background: #e1e3e9;
}
.container-div {
  display: flex;
  justify-content: space-between;
}
.ecosystem-landing {
  background-color: #f4f1ed;
  color: #21191d !important;
  font-family: Work Sans !important;
  padding-top: 7em;

  .breadcrumbs-main-container {
    margin-top: 100px;
  }

  .PoweredbyTitle_logo {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .PoweredbyTitleWrepper {
      max-width: 847px;
      widows: 100%;
      display: flex;

      // align-items: center;
      img {
        width: 100px;
        height: 100px !important;
        object-fit: contain;
        display: none;
      }
    }

    .Poweredby_logoImg {
      display: flex;
      justify-content: flex-end;

      .powered-text {
        span {
          font-size: 14px;
          line-height: 20px;
          margin-right: 35px;
          font-weight: 400;
          color: #19486A;
        }
      }

      img {
        width: 100px;
        height: 33px;
        object-fit: contain;
        margin-right: 35px;
        max-width: 100px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
.Modal_Readmorestyle {
  padding: 30px 0 10px 30px;
  position: relative;
  display: flex;
  .ModalInnerStyle {
    padding-right: 20px;
    .space-between {
      justify-content: space-between;
      border-bottom: 1px solid #e1e3e9;
      padding: 24px 0px 24px 0px;
    }
    p {
      font-weight: 400;
      font-family: Arial;
      color: #19486A;
      font-size: 16px;
      line-height: 24px;
    }
    h1 {
      font-weight: bold;
      line-height: 16px;
      color: #1b1b1b;
      margin-bottom: 5px;
      text-align: center;
      padding-bottom: 30px;
    }
    h2 {
      font-weight: bold;
      font-size: 24px;
      line-height: 16px;
      color: #19486A;
      margin-bottom: 5px;
      text-align: center;
      padding-bottom: 30px;
    }

    .modal-send-button {
      font-weight: bold;
      color: white;
      background-color: #19486A;
      border-radius: 8px;
      border-width: 1px;
      font-size: 16px;
      width: 250px;
    }
    .modal-link-button {
      font-weight: bold;
      color: #19486A;
      border-style: solid;
      border-color: #19486A;
      border-width: 1px;
      padding: 12px 24px 12px 24px;
      width: 250px;
      font-size: 16px;
    }
    .certification-partner-button {
      font-weight: bold;
      font-size: 16px;
      color: #19486A;
      border-style: solid;
      border-color: #19486A;
      border-width: 1px;
      float: right;
      padding: 12px 24px 12px 24px;
    }
    .upload-documents-button {
      font-weight: bold;
      color: white;
      background: #3F7E44;
      padding: 8px 16px 8px 16px;
    }
    .center-span-modal {
      position: relative;
      top: 35px;
      line-height: 20px;
      font-family: arial;
      color: #19486A;
    }
  }
}
</style>

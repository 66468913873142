<template>
  <div class="static ecosystem-landing">
    <div class="container mx-auto -mt-20 px-6">
      <div class="PoweredbyTitle_logo">
        <div class="PoweredbyContentWrepper">
          <img
            src="@/assets/images/2X-Challenge-logo.png"
            alt="background-image"
            style="height: 50px"
          />
        </div>
        <div style="display: flex; justify-content: flex-end">
          <div class="Poweredby_logoImg mt-2">
            <div class="powered-text flex">
              <span>Powered by:</span>
            </div>
            <img src="@/assets/images/logo-navy.png" alt="background-image" />
          </div>
        </div>
      </div>
      <div class="fixed z-50 inset-0 overflow-y-auto" v-if="is2xBetaModal">
        <div
          class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        >
          <div class="fixed inset-0 transition-opacity cursor-pointer">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen cursor-pointer"
          ></span
          >&#8203;
          <div
            class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
            style="max-width: 624px"
          >
            <div class="Modal_Readmorestyle py-4 px-6">
              <div class="ModalInnerStyle">
                <div class="mt-5">
                  <div>
                    This platform is a Beta version that is built to test
                    content.There are known technical bugs that are currently
                    being addressed. We appreciate your patience and
                    understanding for this. For technical issues that prevent
                    you from proceeding with testing, please reach out to the
                    technical team by clicking
                    <a
                      href=" https://airtable.com/embed/appxe5pWrRZuSKxdS/shrvvhKBs0eAcICnu?backgroundColor=red"
                      target="_blank"
                      download="filename.extension"
                      class="text-blue-600 font-normal rounded border-blue-400"
                    >
                      "Support"
                    </a>
                  </div>
                </div>
                <button
                  @click="is2xBetaModal = false"
                  class="flex justify-center m-auto mt-4 bg-[#A21942] px-4 py-2 rounded-lg text-white font-semibold"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="PoweredbyTitle_logo bg-image" style="padding: 0">
        <div class="PoweredbyContentWrepper bg-text-style">
          <div class="PoweredbyTitleWrepper mt-5">
            <h1
              class="text-6xl tracking-tighter font-medium print:text-7xl"
              style="max-width: 375px; color: #fff"
            >
              <span>Welcome to the 2X Challenge</span>
            </h1>
          </div>
          <div
            class="flex flex-col mt-10 mx-auto items-center justify-center"
            style="max-width: 845px"
          >
            <p
              class="text-lg leading-7 font-medium gap-10 text-justify leading-7"
              style="color: #fff"
            >
              Thank you for your participation in the 2X Challenge for the
              period 2024-2026. We're excited to collaborate with you to unlock
              gender-smart capital at scale!
            </p>
          </div>
        </div>
      </div>

      <!-- Selection Box Wrepper -->

      <div class="ContentWrapper w-12/12">
        <!-- CERTIFICATION PROCESS -->
        <div>
          <h1
            class="text-4xl tracking-tighter font-medium print:text-7xl mt-12 mb-4"
            style="max-width: 500px"
          >
            <span>2X Challenge deal qualification</span>
          </h1>
          <div class="flex gap-2">
            <div class="flex flex-col mt-10 w-2/4">
              <p
                class="text-med leading-7 font-semibold gap-10 text-justify leading-7 font-medium w-3/4"
              >
                <span style="cursor: default; color: #21191d">
                  On this page, you will find the tools needed to submit your 2X
                  Criteria aligned investment to qualify under the 2X Challenge.
                  Please note that in order for deals to be 2X Challenge
                  qualified, they will have to meet the 2X Criteria, of which
                  further guidance can be found in the 2X Criteria Reference
                  Guide
                  <a
                    class="font-bold"
                    href="https://www.2xchallenge.org/2xcriteria"
                    target="_blank"
                    style="color: #0077c8"
                    >here</a
                  >.
                </span>
                <br /><br />
              </p>
              <div class="flex flex-col">
                <p
                  class="text-med leading-7 font-semibold gap-10 text-justify leading-7 font-medium w-3/4"
                  style="cursor: default; color: #21191d"
                >
                  Are you interested in discovering where your entity stands
                  against global gender-smart industry benchmarks? Dive into the
                  2X Criteria Thresholds tool to unveil which thresholds your
                  entity meets!
                </p>
                <router-link
                  to="/2X-Ecosystem/Thresholds"
                  class="flex gap-2 items-center text-xs font-bold cursor-pointer mt-5"
                  style="color: #2177c1"
                  @click="see2XThresholdCriteria()"
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.16675 4.83342H9.83342V6.50008H8.16675V4.83342ZM8.16675 8.16675H9.83342V13.1667H8.16675V8.16675ZM9.00008 0.666748C4.40008 0.666748 0.666748 4.40008 0.666748 9.00008C0.666748 13.6001 4.40008 17.3334 9.00008 17.3334C13.6001 17.3334 17.3334 13.6001 17.3334 9.00008C17.3334 4.40008 13.6001 0.666748 9.00008 0.666748ZM9.00008 15.6667C5.32508 15.6667 2.33341 12.6751 2.33341 9.00008C2.33341 5.32508 5.32508 2.33341 9.00008 2.33341C12.6751 2.33341 15.6667 5.32508 15.6667 9.00008C15.6667 12.6751 12.6751 15.6667 9.00008 15.6667Z"
                      fill="#2177C1"
                    />
                  </svg>
                  <span class="flex items-center justify-center h-full">
                    2X Criteria Thresholds</span
                  >
                </router-link>
                <div class="mt-8">
                  <a
                    href="https://storage.equilo.io/api-assets/2X_Challenge_%20Self_Assessment_Questionnaire_DRAFT.pdf"
                    download="Certification_Detailed_Questions.pdf"
                    class="text-xl tracking-tighter font-medium rounded-lg border-2 px-4 py-3"
                    target="_blank"
                  >
                    <span class="pr-1">Download questions in advance</span>
                  </a>
                </div>
              </div>
            </div>
            <div class="progress-steps-section w-2/4">
              <div class="outer">
                <div class="progress">
                  <div class="right">
                    <div>
                      <span class="font-bold"
                        >Complete the investor profile</span
                      >
                      <br />
                      <span class="font-normal"
                        >Complete your profile as an investor once to provide
                        information required for the 2X Challenge as an
                        organization.</span
                      >
                      <br />
                      <button
                        v-track-click="{ elementName: 'Investor Profile' }"
                        class="certification-button px-3 py-2 mt-5 text-xs gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center"
                        @click="
                          user?.membership2xData
                            ? $router.push({
                                path: `/2X-Ecosystem/2XC-Membership/${user.organization._id}`,
                              })
                            : $router.push({
                                path: '/2X-Ecosystem/create2Xprofile',
                              })
                        "
                      >
                        <span class="">Investor Profile</span>
                        <!-- <svg width="16" height="8" viewBox="0 0 16 8" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12.01 3H0V5H12.01V8L16 4L12.01 0V3Z" fill="white" />
                                                </svg> -->
                      </button>
                    </div>
                    <div>
                      <span class="font-bold">Submit deal(s) to qualify </span>
                      <br />
                      <span class="font-normal"
                        >You have two options to submit a deal to qualify for
                        the 2X Challenge. You may either bulk upload multiple
                        deals in Excel, or you may submit individual deal
                        information.</span
                      >
                      <br />
                      <button
                        :class="{ 'pointer-events-none': currentStep < 2 }"
                        v-track-click="{ elementName: 'Submit a deal' }"
                        class="certification-button px-3 py-2 mt-5 text-xs gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center"
                        @click="submitADeal()"
                      >
                        <span class="">Submit a deal</span>
                      </button>
                    </div>
                    <div>
                      <span class="font-bold"
                        >2X Challenge Self-Assessment
                      </span>
                      <br />
                      <span class="font-normal">
                        Navigate the 2X Challenge Self-Assessment modules
                        organised by each of the 2X Criteria to complete this
                        assessment.
                      </span>
                      <br />
                      <button
                        v-track-click="{
                          elementName: '2X Challenge Self-Assessment',
                        }"
                        class="certification-button px-3 py-2 mt-5 text-xs gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center"
                        @click="navigateModule('2X-Challenge-Self-Assessment')"
                      >
                        <span class="">2X Challenge Self-Assessment</span>
                      </button>
                    </div>
                    <div>
                      <span class="font-bold"
                        >View Published 2X Challenge Deals</span
                      >
                      <br />
                      <span class="font-normal"
                        >View the publicly disclosed database of 2024-2026 2X
                        Challenge Deals and the 2X Challenge investor
                        disclosures.</span
                      >
                      <br />
                      <span class="flex gap-5">
                        <button
                          v-track-click="{
                            elementName: '2X Challenge Deal Directory',
                          }"
                          @click="$router.push({ path: '/challenge-deals' })"
                          class="certification-button mt-5 px-3 py-2 text-xs gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center"
                        >
                          <span class="" style=""
                            >2X Challenge Deal Directory</span
                          >
                        </button>
                        <button
                          v-track-click="{
                            elementName: '2X Challenge Investor Disclosures',
                          }"
                          @click="
                            $router.push({ path: '/investor-disclosures' })
                          "
                          class="certification-button mt-5 px-3 py-2 text-xs gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center"
                        >
                          <span class="" style=""
                            >2X Challenge Investor Disclosures</span
                          >
                        </button>
                      </span>
                    </div>
                    <div class="mb-4">
                      <span class="font-bold"
                        >View Pipeline of Potential 2X Matches</span
                      >
                      <br />
                      <span class="font-normal"
                        >Check out the potential pipeline of 2X matches and
                        likeminded co-investors with intelligent matching based
                        on your investor profile interests.</span
                      >
                      <br />
                      <button
                        v-track-click="{ elementName: '2X Matchmaking' }"
                        class="certification-button disabled:cursor-default tooltip px-3 py-2 mt-5 text-xs gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center"
                        :disabled="!(user.sophisticatedInvestor && (user.access2x  || $store.state.isDomainValid))"
                      >
                        <span  
                          @click="redirectTo2xMatchMaking()">
                          2X Matchmaking
                        </span>
                        <span v-if="!(user.sophisticatedInvestor && (user.access2x  || $store.state.isDomainValid))" class="twox-matchmaking-hover-text cursor-default" @click.stop="">
                            <span>You do not have access to this premium 2X Matchmaking platform feature. To access this you must either be a 2X Global Member and sophisticated investor, or you must be an Equilo Premium subscriber and sophisticated investor, accelerator, or network. Interested in this access? </span>
                            <button v-if="!is2xMatchAccessRequestSent" class="certification-button px-4 py-2 rounded-md my-3" @click.stop="sendRequestForMatchmakingAccess()">Give me 2X Matchmaking Access</button>
                            <button v-else class="certification-button px-3 py-2 rounded-md my-3 cursor-default">Your Request is being processed</button>
                          </span>
                      </button>
                    </div>
                    <div :class="{ current: currentStep == 5 }">
                      <span class="font-bold mt-3"
                        >Continue with 2X Certification (optional)</span
                      >
                      <br />
                      <span class="font-normal"
                        >Are you ready to certify an investee that is a fund,
                        FI, or company? Learn about the 2X Certification process
                        and invite investees to get certified.</span
                      >
                      <br />
                      <button
                        :class="{ 'pointer-events-none': currentStep < 5 }"
                        v-track-click="{ elementName: '2X Certification' }"
                        class="certification-button px-3 py-2 mt-5 text-xs gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center"
                        @click="viewCertifyPermission()"
                      >
                        <span class="">2X Certification</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Submit deal Modal -->
    <div class="fixed z-50 inset-0 overflow-y-auto" v-if="isSubmitDealModal">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity cursor-pointer">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen cursor-pointer"
        ></span
        >&#8203;
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style="max-width: 624px"
        >
          <div
            class="close cursor-pointer"
            @click="isSubmitDealModal = false"
            style="right: 8px"
          >
            &times;
          </div>
          <div class="Modal_Readmorestyle p-6 pr-3">
            <div class="ModalInnerStyle">
              <div class="heading-section flex justify-center items-center">
                <h1
                  class="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  Submit a Deal
                </h1>
              </div>
              <div class="mt-7">
                For a single deal, complete the deal information and invite the
                investee to submit their 2X Criteria information. The deal will
                be reviewed and published to the Deal Directory.
              </div>
              <button
                class="cancel-button flex justify-center m-auto mt-4"
                @click="$router.push({ path: '/submit-deal' })"
              >
                Submit one deal for qualification
              </button>
              <div class="mt-5">
                <div>
                  For multiple deals, download an Excel template, complete the
                  deal information and the investee 2X criteria information, and
                  upload it. It will be reviewed and published to the Deal
                  Directory.
                </div>
                <button
                  class="cancel-button flex justify-center m-auto mt-4"
                  @click="
                    (isDocumentsModal = true), (isSubmitDealModal = false)
                  "
                >
                  Bulk upload multiple deals
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--what documents upload modal -->
    <div class="fixed z-50 inset-0 overflow-y-auto" v-if="isDocumentsModal">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity cursor-pointer">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
        >&#8203;
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style="max-width: 800px; width: 100%"
        >
          <span
            @click="(isDocumentsModal = false), resetUploadMeta()"
            class="close z-10"
            style="right: 16px; top: 5px"
            >&times;</span
          >
          <div class="Modal_Readmorestyle what-modal" style="display: block">
            <div class="ModalInnerStyle pr-6">
              <h2
                class="text-2xl mb-6"
                style="font-weight: 600; text-align: left; line-height: 32px"
              >
                Documents
              </h2>
              <div>
                <!-- <p class="content text-base">
                                    <a href="https://storage.equilo.io/api-assets/2024_2X_Challenge_Bulk_Upload_Reporting_Template.xlsx"
                                    download="filename.extension"
                                    class="text-blue-600 font-semibold border-blue-500">
                                    Please download
                                </a>
                                    this 2X Challenge Deals Bulk Upload file in Excel, complete all data
                                    related to deal qualification and the 2X Criteria minimum requirements specific to
                                    each deal. Upload the completed file for submission to 2X Global. Once reviewed by
                                    2X Global it will appear in the 2X Challenge Deal Directory.
                                </p> -->
                <p class="content text-base mb-2">
                  1.
                  <a
                    href="https://storage.equilo.io/api-assets/2024_2X_Challenge_Bulk_Upload_Reporting_Template.xlsx"
                    download="filename.extension"
                    class="text-blue-600 font-semibold border-blue-500"
                  >
                    Please download
                  </a>
                  <span>
                    the 2X Challenge Bulk Upload excel file, complete the
                    required data fields for each deal, and upload the completed
                    file.</span
                  >
                </p>
                <p class="content text-base mb-2">
                  2. Please download the
                  <a
                    href="https://storage.equilo.io/api-assets/2X_Global_Data_Sharing_Agreement.pdf"
                    download="filename.extension"
                    target="_blank"
                    class="text-blue-600 font-semibold border-blue-500 -mr-1"
                  >
                    Data Sharing Agreement </a
                  ><span
                    >, ensure it is signed by EACH investee, and upload the
                    signed file(s).</span
                  >
                </p>
                <p class="content text-base mb-2">
                  3. Once reviewed and qualified by 2X Global it will appear in
                  the 2X Challenge Deal Directory.
                </p>
                <div
                  class="congrats-box-doc p-5 mb-5 text-center mt-5"
                  id="app"
                  @dragover="dragover"
                  @drop="drop"
                >
                  <div
                    class="flex w-full items-center justify-center text-center"
                  >
                    <div>
                      <input
                        type="file"
                        id="assetsFieldHandle"
                        class="w-px h-px opacity-0 overflow-hidden absolute"
                        multiple
                        @change="handleFileChange($event)"
                        ref="file"
                        accept=".xls,.xlsx,.pdf"
                      />
                      <label
                        for="assetsFieldHandle"
                        class="block cursor-pointer"
                      >
                        <div class="center-button">
                          <span
                            class="upload-documents-button px-3 py-2 gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center"
                          >
                            Choose files
                          </span>
                        </div>

                        <p class="center-span-modal">
                          Drag the completed file here
                        </p>
                      </label>
                    </div>
                  </div>
                </div>
                <div>
                  <h2 class="text-gray-700 text-xl font-semibold truncate">
                    Selected File(s)
                  </h2>
                  <div
                    class="container mx-auto grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 pt-2 gap-4"
                  >
                    <div>
                      <div
                        v-if="xlsFilelist.length > 0"
                        class="flex flex-col h-48 overflow-y-auto"
                      >
                        <div
                          v-for="(file, index) in xlsFilelist"
                          :key="index"
                          class="p-1 my-2 border-b flex justify-between items-center"
                        >
                          <h3
                            class="text-gray-700 text-sm font-medium truncate"
                          >
                            {{ file.name }}
                          </h3>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6 text-red-500 hover:text-red-700 cursor-pointer mr-6"
                            @click="removeSelectedFile(index)"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                            />
                          </svg>
                        </div>
                      </div>
                      <div
                        v-else
                        class="text-gray-500 text-sm font-medium py-1"
                      >
                        Select the Bulk Upload excel file.
                      </div>
                    </div>
                    <div>
                      <div
                        v-if="agreementFilelist.length > 0"
                        class="flex flex-col h-48 overflow-y-auto"
                      >
                        <div
                          v-for="(file, index) in agreementFilelist"
                          :key="index"
                          class="p-1 my-2 border-b flex justify-between items-center"
                        >
                          <h3
                            class="text-gray-700 text-sm font-medium truncate"
                          >
                            {{ file.name }}
                          </h3>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6 text-red-500 hover:text-red-700 cursor-pointer mr-6"
                            @click="removeAgreementFile(index)"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                            />
                          </svg>
                        </div>
                      </div>
                      <div
                        v-else
                        class="text-gray-500 text-sm font-medium py-1"
                      >
                        Select the Data Sharing Agreement.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex mt-10 justify-between">
                <button
                  @click="isDocumentsModal = false"
                  class="modal-link-button px-3 py-2 text-xs gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center mr-2"
                >
                  Cancel
                </button>
                <button
                  @click="generateDocumentPresignURL()"
                  :disabled="!isUploaded || isDocumentUploading"
                  :class="{
                    'bg-gray-400 cursor-not-allowed text-gray-900':
                      !isUploaded || isDocumentUploading,
                    'bg-[#3F7E44] text-white':
                      isUploaded && !isDocumentUploading,
                  }"
                  class="modal-send-button px-3 py-2 text-xs gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center mr-2"
                >
                  <p class="flex items-center" v-if="isDocumentUploading">
                    Uploading...
                    <span>
                      <svg
                        aria-hidden="true"
                        class="ml-2 w-6 h-6 text-white animate-spin dark:text-gray-600 fill-blue-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                    </span>
                  </p>
                  <p v-else>Upload</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Notify about Complete Investor Profile modal -->
    <div
      class="fixed z-50 inset-0 overflow-y-auto"
      v-if="completeInvestorModal"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity cursor-pointer">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
        >&#8203;
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style="max-width: 630px; width: 100%"
        >
          <span
            class="close cursor-pointer"
            @click="completeInvestorModal = false"
            style="right: 8px"
            >&times;</span
          >
          <div class="Modal_Readmorestyle what-modal pr-2 justify-center">
            <div class="ModalInnerStyle">
              <p class="font-semibold">
                Complete your Investor Profile to proceed
              </p>
              <button
                style="background-color: #19486a; color: white"
                class="text-white px-3 py-2 text-lg gap-1 font-bold rounded-lg text-white transition duration-200 shadow flex flex-row items-center justify-center m-auto mt-2"
                @click="
                  user?.membership2xData
                    ? $router.push({
                        path: `/2X-Ecosystem/2XC-Membership/${user.organization._id}`,
                      })
                    : $router.push({ path: '/2X-Ecosystem/create2Xprofile' })
                "
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Request permission from 2X Global -->
    <div class="fixed z-50 inset-0 overflow-y-auto" v-if="requestTwoxGlobal">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity cursor-pointer">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
        >&#8203;
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style="max-width: 630px; width: 100%"
        >
          <span
            class="close cursor-pointer"
            @click="requestTwoxGlobal = false"
            style="right: 8px; top: -4px"
            >&times;</span
          >
          <div class="Modal_Readmorestyle what-modal pr-2">
            <div class="ModalInnerStyle">
              <p class="font-semibold">
                You have to be approved by 2X Global to officially participate
                in the 2X Challenge. To request permission from 2X Global please
                contact them
                <a
                  class="font-bold"
                  href="https://www.2xglobal.org/membership"
                  target="_blank"
                  style="color: #0077c8"
                  >here</a
                >.
              </p>
              <button
                style="background-color: #ff3a21; color: white"
                class="text-white px-3 py-2 text-lg gap-1 font-bold rounded-lg text-white transition duration-200 shadow flex flex-row items-center justify-center m-auto mt-2"
                @click="requestTwoxGlobal = false"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Prompt for documents Uploaded -->
    <div class="fixed z-50 inset-0" v-if="isDocumentUploaded">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <button
          v-if="true"
          class="save-upload-doc flex gap-2 items-center font-bold"
        >
          <img src="@/assets/images/2x_certify/filled.svg" />
          <span
            style="
              font-family: Arial;
              font-weight: 400;
              font-size: 16px;
              color: #2c2e35;
            "
            >Document uploaded successfully!</span
          >
        </button>
      </div>
    </div>
    <!-- Pilot Request Modal Start -->
    <div class="fixed z-50 inset-0 overflow-y-auto" v-if="pilotRequestModal">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity cursor-pointer">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
        >&#8203;
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style="max-width: 630px; width: 100%"
        >
          <span
            class="close z-10"
            @click="closeRequestModal()"
            style="right: 8px"
            >&times;</span
          >
          <div
            class="Modal_Readmorestyle what-modal"
            style="display: block; padding-right: 24px"
          >
            <div class="ModalInnerStyle">
              <p style="font-weight: 500; text-align: center">
                Want to pilot the 2X Certification platform?
              </p>
              <p style="font-weight: 500; text-align: center">
                Send 2X Global a request
              </p>
              <button
                style="background-color: #ff3a21; color: white"
                class="text-white px-3 py-2 text-lg gap-1 font-bold rounded-lg text-white transition duration-200 shadow flex flex-row items-center justify-center m-auto mt-2"
                @click="sendRequestToPilot()"
              >
                Send request to pilot
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Pilot Request End Modal -->
  </div>
</template>

<script>
import auth from "@/helpers/auth";
import env from "@/../env";
const axios = require("axios");
import Cookies from "@/helpers/cookies";
import apiService from "@/helpers/apiServices.js";

export default {
  name: "twoXChallenge",
  data: function () {
    return {
      user: [],
      isSubmitDealModal: false,
      currentStep: 5,
      isDocumentsModal: false,
      completeInvestorModal: false,
      requestTwoxGlobal: false,
      filelist: [],
      xlsFilelist: [],
      agreementFilelist: [],
      uploadedFileNames: [],
      isFileUpload: false,
      file: null,
      uploadProgress: 0,
      isDocumentUploaded: false,
      pilotRequestModal: false,
      is2xBetaModal: false,
      isDocumentUploading: false,
      isDocumentValidate: true,
      isAgreementpdfFile: [],
      dataSharingPDF:
        "https://storage.equilo.io/api-assets/2X_Global_Data_Sharing_Agreement.pdf",
      isUploaded: false,
      certificationSurveyData: [],
      entityChosen: "",
      isAllMinReqSurveysCompleted: false,
      isFullSurveyStarted: false,
      isRepeat: null,
      is2xMatchAccessRequestSent: false,
    };
  },
  methods: {
    isFullSurveyCompleted(surveyUrl) {
      return this?.certificationSurveyData?.some(
        (survey) =>
          survey.surveyId === surveyUrl &&
          survey?.metadata?.fullAssess &&
          survey.isComplete
      );
    },
    viewCertifyPermission() {
      // if (!this.user.status2xCertification ||
      //     this.user?.status2xCertification == "New Pilot Request" ||
      //     this.user?.status2xCertification == "Pilot Access Denied") {
      //     this.pilotRequestModal = true;
      // } else {
      this.$router.push("/2X-Ecosystem/2X-Certification");
      // }
    },
    sendRequestToPilot() {
      return axios
        .post(
          env.apiUrl + "/certify2x/2x-certification-validation-request",
          {
            pilot: true,
          },
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        )
        .then((res) => {
          this.pilotRequestModal = false;
          if (res) {
            this.$swal.fire({
              icon: "success",
              text: res.data.data,
              showConfirmButton: true,
            });
          }
        })
        .catch((err) => {
          this.pilotRequestModal = false;
          this.$swal.fire({
            icon: "error",
            text: err.response.data.message,
            showConfirmButton: true,
          });
        });
    },
    async closeRequestModal() {
      this.pilotRequestModal = false;
    },
    submitADeal() {
      if (
        this.user?.membership2xData ||
        this.$store.state.isDomainValid ||
        this.user.twoXCoInvestorPrivileges
      ) {
        this.isSubmitDealModal = true;
      } else {
        this.requestTwoxGlobal = true;
      }
    },
    dragover(event) {
      event.preventDefault();
    },
    dragleave(event) {
      console.log(event);
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.handleFileChange();
    },
    handleFileChange(event) {
      this.file = event?.currentTarget?.files?.[0];
      const files = this.$refs.file.files;
      const xlsFiles = [];
      const pdfFiles = [];

      for (let i = 0; i < files.length; i++) {
        const fileExtension = files[i].name.split(".").pop().toLowerCase();
        if (fileExtension === "pdf") {
          if (!this.uploadedFileNames.includes(files[i].name)) {
            this.uploadedFileNames.push(files[i].name);
            pdfFiles.push(files[i]);
          }
        } else if (fileExtension === "xls" || fileExtension === "xlsx") {
          if (!this.uploadedFileNames.includes(files[i].name)) {
            this.uploadedFileNames.push(files[i].name);
            if (this.xlsFilelist.length > 0) {
              this.uploadedFileNames = this.uploadedFileNames.filter(
                (name) =>
                  !["xls", "xlsx"].includes(name.split(".").pop().toLowerCase())
              );
              this.xlsFilelist = [];
            }
            xlsFiles.push(files[i]);
          }
        } else {
          this.$swal.fire({
            icon: "error",
            text: `Unsupported file type: ${files[i].name}. Please upload files with .xls, .xlsx, or .pdf extensions.`,
            showConfirmButton: true,
          });
        }
      }

      this.xlsFilelist.push(...xlsFiles);
      this.agreementFilelist.push(...pdfFiles);
      this.filelist = [...this.agreementFilelist, ...this.xlsFilelist];
      this.uploadedFileNames = this.filelist.map((item) => item.name);
      if (this.xlsFilelist.length && this.agreementFilelist.length) {
        this.isUploaded = true;
      } else {
        this.isUploaded = false;
      }
    },
    generateDocumentPresignURL() {
      let body = {
        files: this.uploadedFileNames,
        urlType: "upload",
        module: "2X_CHALLANGE_EXCEL",
      };

      if (this.uploadedFileNames.length > 0) {
        this.isDocumentUploading = true;
        axios
          .post(env.apiUrl + `/other/presignedUrl`, body, {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(async (res) => {
            const fileRes = res.data.data;
            if (fileRes.length) {
              await this.uploadFile(fileRes);
            }
            this.resetUploadMeta();
          });
      }
    },
    async uploadFile(dataArray) {
      try {
        const pdfDataArray = dataArray.filter((data) =>
          this.agreementFilelist.some(
            (file) =>
              file.name === data.fileName && file.type === "application/pdf"
          )
        );
        const xlsDataArray = dataArray.filter((data) =>
          this.xlsFilelist.some((file) => file.name === data.fileName)
        );

        const pdfUploadPromises = pdfDataArray.map(async (data) => {
          const preSignedURL = data.url;
          const pdfFiles = this.agreementFilelist.find(
            (file) => file.name === data.fileName
          );

          if (pdfFiles) {
            const uploadResponsePdf = await axios.put(preSignedURL, pdfFiles, {
              headers: {
                "Content-Type": pdfFiles.type,
                "Access-Control-Allow-Origin": "*",
              },
              onUploadProgress: (progressEvent) => {
                this.uploadProgress = Math.round(
                  (progressEvent.loaded / progressEvent.total) * 100
                );
              },
            });

            if (uploadResponsePdf.status === 200) {
              return { key: data.key, name: pdfFiles.name };
            } else {
              throw new Error(
                `PDF File upload failed for ${pdfFiles.name}: ${uploadResponsePdf.statusText}`
              );
            }
          }
        });

        const pdfResults = await Promise.all(pdfUploadPromises);

        const xlsUploadPromises = xlsDataArray.map(async (data) => {
          const preSignedURL = data.url;
          const xlsFilefile = this.xlsFilelist.find(
            (file) => file.name === data.fileName
          );

          if (xlsFilefile) {
            const uploadResponseXls = await axios.put(
              preSignedURL,
              xlsFilefile,
              {
                headers: {
                  "Content-Type": xlsFilefile.type,
                  "Access-Control-Allow-Origin": "*",
                },
                onUploadProgress: (progressEvent) => {
                  this.uploadProgress = Math.round(
                    (progressEvent.loaded / progressEvent.total) * 100
                  );
                },
              }
            );

            if (uploadResponseXls.status === 200) {
              return { key: data.key, name: xlsFilefile.name };
            } else {
              throw new Error(
                `XLS File upload failed for ${xlsFilefile.name}: ${uploadResponseXls.statusText}`
              );
            }
          }
        });

        const xlsResults = await Promise.all(xlsUploadPromises);
        const combinedResults = [...pdfResults, ...xlsResults];
        this.addDocuments(combinedResults);
        this.resetUploadMeta();
      } catch (error) {
        console.error("An error occurred during file upload:", error.message);
      }
    },
    addDocuments(data) {
      let body = {
        documents: data,
      };
      axios
        .post(env.apiUrl + `/twox-challenge/documents`, body, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        })
        .then(async () => {
          this.isDocumentUploaded = true;
          this.$swal.fire({
            icon: "success",
            text: "Your submission has been successfully received. 2X Global will review your submission and you will be notified once accepted and published.",
            showConfirmButton: true,
          });
          this.isDocumentUploading = false;
          setTimeout(() => {
            this.isDocumentUploaded = false;
          }, 3000);
          this.resetUploadMeta();
        });
    },
    resetUploadMeta() {
      this.filelist = [];
      this.xlsFilelist = [];
      this.uploadedFileNames = [];
      this.isDocumentsModal = false;
      this.isSubmitDealModal = false;
      this.isAgreementpdfFile = [];
      (this.file = null), (this.agreementFilelist = []);
    },
    downloadFile() {
      const url =
        "https://docs.google.com/spreadsheets/d/1pqD7xpqQxAfnmZHft4eVYlb7PxrIIGio/edit?usp=sharing&ouid=102611669229592619545&rtpof=true&sd=true";
      window.open(url, "_blank");
    },
    removeSelectedFile(idx) {
      this.uploadedFileNames.splice(idx, 1);
      this.xlsFilelist.splice(idx, 1);
      this.filelist.splice(idx, 1);
      if (this.xlsFilelist.length && this.agreementFilelist.length) {
        this.isUploaded = true;
      } else {
        this.isUploaded = false;
      }
    },
    removeAgreementFile(idx) {
      this.uploadedFileNames.splice(idx, 1);
      this.agreementFilelist.splice(idx, 1);
      this.filelist.splice(idx, 1);
      if (this.xlsFilelist.length && this.agreementFilelist.length) {
        this.isUploaded = true;
      } else {
        this.isUploaded = false;
      }
    },
    see2XThresholdCriteria() {
      let routeData = this.$router.resolve({
        path: "/2X-Ecosystem/Thresholds",
      });
      window.open(routeData.href, "_blank");
    },
    mouseOver: function (index) {
      this.active = index;
    },
    navigateModule(val) {
      const baseRoute = "/2X-Challenge/";
      const routes = {
        "2X-Challenge-Self-Assessment": {
          condition: this.isRepeat,
        },
      };

      console.log(routes);

      const route = routes[val];
      if (route) {
        const path = baseRoute + val;
        const query = route.condition ? { twoxRepeatAsses: "true" } : undefined;

        this.$router.push({ path, query });
      }
    },
    sendRequestForMatchmakingAccess() {
      apiService.sendRequestForMatchmakingAccess()
        .then(() => {
          localStorage.setItem('2x-match-access-request-sent', true);
          this.is2xMatchAccessRequestSent = true;
          this.$swal.fire({
            icon: "success",
            text: "Your request has been sent!",
            showConfirmButton: true,
          });
        })
        .catch((err) => {
          this.$swal.fire({
            icon: "error",
            text: err.response.data.error || "Something went wrong!",
            showConfirmButton: true,
          });
        });
    },
    redirectTo2xMatchMaking() {
      if ((this.user.sophisticatedInvestor && (this.user.access2x  || this.$store.state.isDomainValid))) {
        this.$router.push({ path: '/2X-Ecosystem/2X-Matchmaking' })
      }
    }
  },
  async mounted() {
    if (!localStorage.getItem("is2XChallengeVisited")) {
      this.is2xBetaModal = true;
      localStorage.setItem("is2XChallengeVisited", "true");
    }
    this.is2xMatchAccessRequestSent = localStorage.getItem('2x-match-access-request-sent') === 'true' ? true : false;
    this.$store.commit("resetCitations");
    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });
    await this.$store.dispatch("fetchValidDomain");
    if (this.user?.surveyData) {
      await apiService.fetch2xUserSurvey("2x").then(async (response) => {
        this.certificationSurveyData = await response.data.data;
      });
    }
    if (this.certificationSurveyData) {
      this.certificationSurveyData.forEach((data) => {
        if (data.surveyId == "2x-intake") {
          this.entityChosen = data.tag;
        }
      });
    }
    if (this?.$store?.state?.certPageAccess) {
      this.isRepeat =
        this.user?.status2xCertification == "Min Survey Submitted" &&
        !this.$store?.state?.certPageAccess?.isUploadDocPage;
    }

    // Check assess is completed or not
    let minReqSurveyCount = 0;
    this.certificationSurveyData.forEach((data) => {
      if (
        (data?.metadata?.minReq && data.isComplete) ||
        this.isFullSurveyCompleted("2x-intake") ||
        this.user?.status2xCertification == "Min Survey Submitted"
      ) {
        minReqSurveyCount++;
      }
    });
    if (
      (this.entityChosen == "fund-manager" ||
        this.entityChosen == "financial-institution" ||
        this.entityChosen == "Institutional investor") &&
      minReqSurveyCount == 10
    ) {
      this.isAllMinReqSurveysCompleted = true;
    }
    if (
      (this.entityChosen == "sme" ||
        this.entityChosen == "corporate" ||
        this.entityChosen == "start-up") &&
      minReqSurveyCount == 9
    ) {
      this.isAllMinReqSurveysCompleted = true;
    }
    this.isFullSurveyStarted =
      (this.isFullSurveyCompleted("2x-intake") ||
        this.certificationSurveyData.filter(
          (e) => e.surveyId === "2x-intake"
        )?.[0]?.metadata?.fullAssess) &&
      this.isAllMinReqSurveysCompleted;
    this.$store.commit("downloadable", false);
    this.$store.commit("isFreeState", true);
    this.$store.commit("setBreadcrumbs", [
      { name: "2X Challenge", url: "/2X-Challenge" },
    ]);
    if (this.$route.query.open) {
      this.isSubmitDealModal = true;
    }
    if (this.xlsFilelist.length && this.agreementFilelist.length) {
      this.isUploaded = true;
    } else {
      this.isUploaded = false;
    }
  },
  beforeUnmount() {
    this.resetUploadMeta();
  },
};
</script>

<style lang="scss" scoped>
.ContentWrapper {
  margin-top: 10px;

  .progress-steps-section {
    color: #21191d;

    .outer {
      min-width: 20vw;
      flex: 1;
    }

    .progress {
      $gap: 20px;
      $line-height: 20px;
      $bullet-radius: 5px;
      $line-thick: 5px;
      $strip-color: #666461;
      $next-color: #c2beba;
      $current-color: #666461;
      $prev-color: #666461;

      display: inline-flex;
      height: 100%;
      padding: 5vh 10%;

      > div {
        display: flex;
        flex-direction: column;
        color: $prev-color;

        &.left {
          padding-right: $gap;
          text-align: right;

          // Line
          div {
            &:last-of-type:after {
              display: none;
            }

            &:after {
              content: "";
              background: fade_out($strip-color, 0.9); //rgba(0, 0, 0, 0.6);
              border-radius: 2px;
              position: absolute;
              right: -$gap;
              top: $line-height/2;
              height: 101%;
              width: 1px;
              transform: translateX(50%);
            }
          }
        }

        &.right {
          padding-left: $gap;

          div {
            span {
              color: #21191d;
            }

            button {
              span {
                color: #fff;
              }
            }

            &.prev {
              &:after {
                transition: none;
              }
            }

            &.current {
              color: $current-color;
              font-weight: bold;
              z-index: 1;

              &:before {
                background: $current-color;
                padding: 15px;
                transition: all 0.2s 0.15s cubic-bezier(0.175, 0.885, 0.32, 2);
                z-index: 1;
              }

              &:last-child:after {
                height: 0%;
                transition: height 0.2s ease-out;
              }

              &:after {
                content: "";
                background: $next-color; //rgba(0, 0, 0, 0.6);
                border-radius: 2px;
                position: absolute;
                left: -40px;
                top: $line-height/2;
                height: 101%;
                width: $line-thick;
                transform: translateX(-50%);
                transition: height 0.2s ease;
              }

              ~ div {
                color: $next-color;

                &:before {
                  background: $next-color;
                  padding: 15px;
                }

                &:after {
                  content: "";
                  background: $next-color; //rgba(0, 0, 0, 0.6);
                  border-radius: 2px;
                  position: absolute;
                  left: -40px;
                  top: $line-height/2;
                  height: 101%;
                  width: $line-thick;
                  transform: translateX(-50%);
                  transition: height 0.2s ease;
                }

                &:last-child:after {
                  height: 0%;
                  transition: none;
                }
              }
            }

            // Dot
            &:before {
              content: "";
              background: $strip-color;
              padding: 15px;
              border-radius: 50%;
              position: absolute;
              left: -40px;
              top: $line-height/2;
              transform: translateX(-50%) translateY(-50%);
              transition: padding 0.2s ease;
            }

            // Line
            &:after {
              content: "";
              background: $strip-color; //rgba(0, 0, 0, 0.6);
              border-radius: 2px;
              position: absolute;
              left: -40px;
              top: $line-height/2;
              height: 101%;
              width: $line-thick;
              transform: translateX(-50%);
              transition: height 0.2s ease;
            }
          }
        }

        div {
          flex: 1;
          //outline: 1px solid rgba(0, 0, 0, 0.1);
          position: relative;
          line-height: $line-height;
          cursor: default;
          min-height: 150px;

          &:last-of-type {
            flex: 0;
          }
        }
      }
    }

    .done.current {
      color: #62af0b !important;

      &:before {
        background: #62af0b !important;
      }
    }
  }

  .standard-button {
    font-weight: bold;
    font-size: 12px;
    color: #19486a;
    border-style: solid;
    border-color: #19486a;
    border-width: 1px;
  }

  .certification-button {
    font-weight: 500;
    color: white;
    background: #19486a;
    font-size: 16px;
  }
}

.asset_text {
  font-size: 12px;
  font-weight: bold;
  color: #1f1747;
}

.grey-out {
  pointer-events: none;
  opacity: 0.7;
}

router-link {
  cursor: pointer;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 45px;
  line-height: 45px;
  color: #000;
}

.ecosystem-landing {
  background-color: #f4f1ed;
  color: #21191d !important;
  font-family: Work Sans;

  .save-upload-doc {
    background: #e5fafa;
    padding: 16px;
    border-radius: 12px;
    position: relative;
    left: 44%;
    margin-top: 50rem;
  }

  .PoweredbyTitle_logo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 170px;
    padding: 170px 0 0;
    margin-top: 20px;

    .bg-text-style {
      margin-left: 9rem;
      position: relative;
      top: 20rem;
      color: #f4f1ed;
      width: 570px;
    }

    .PoweredbyTitleWrepper {
      max-width: 847px;
      display: flex;

      // align-items: center;
      img {
        width: 100px;
        height: 100px !important;
        object-fit: contain;
        display: none;
      }
    }

    .Poweredby_logoImg {
      display: flex;
      justify-content: flex-end;

      .powered-text {
        span {
          font-size: 14px;
          line-height: 20px;
          margin-right: 35px;
          font-weight: 400;
          color: #19486a;
        }
      }

      img {
        width: 100px;
        height: 33px;
        object-fit: contain;
        margin-right: 35px;
        max-width: 100px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .bg-image {
    background-image: linear-gradient(
        201deg,
        rgba(0, 0, 0, 0.1) 55%,
        rgba(221, 166, 58, 0.8) 100%
      ),
      url("./../../assets/images/2x-challenge-bg.png");
    height: 700px;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
  }

  .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    top: 0;
    position: relative;
  }

  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }

  .Modal_Readmorestyle {
    position: relative;
    display: flex;

    .ModalInnerStyle {
      background: #fff;

      .heading-section {
        gap: 28px;
      }

      h1 {
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        color: #dda63a;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
      }

      .textarea-section {
        width: 552px;
        gap: 12px;

        textarea {
          margin: 0;
          min-width: 576px;
          min-height: 120px;
          padding: 12px;
          border-radius: 8px;
          border: 1px solid #e1e3e9;
          gap: 16px;
        }

        textarea::placeholder {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
        }
      }

      .cancel-button {
        width: auto;
        height: 40px;
        padding: 12px 24px 12px 24px;
        border-radius: 8px;
        gap: 8px;
        background: #dda63a;
        border: 1px solid #dda63a;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;
      }

      .save-button {
        width: 280px;
        height: 40px;
        padding: 12px 24px 12px 24px;
        border-radius: 8px;
        gap: 8px;
        background: #191b45;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;
      }

      .congrats-box-doc {
        border: 1px solid #2177c1;
        border-radius: 16px;
        padding: 32px, 0px, 32px, 0px;
        background: #eaf6ff;
        border-style: dashed;
        height: 160px;
      }
    }
  }

  .what-modal {
    padding: 24px 0 24px 24px;

    .content {
    }

    .modal-link-button {
      font-weight: bold;
      color: #19486a;
      border-style: solid;
      border-color: #19486a;
      border-width: 1px;
      padding: 12px 24px 12px 24px;
      width: 250px;
      font-size: 16px;
    }

    .modal-send-button {
      font-weight: bold;
      color: white;
      border-radius: 8px;
      border-width: 1px;
      font-size: 16px;
      width: 250px;
    }

    .center-span-modal {
      position: relative;
      top: 35px;
      line-height: 20px;
      font-family: arial;
      color: #191b45;
    }

    .upload-documents-button {
      font-weight: bold;
      color: white;
      background: #2177c1;
      // width: 150px;
      padding: 8px 16px 8px 16px;
    }

    .center-button {
      position: relative;
      top: 27px;
    }
  }
}

.pointer-events-none {
  pointer-events: none;
  opacity: 0.7;
}

input {
  margin: 6px;
}

#SPAN_1:hover {
  color: #40a9ff;
}

.tooltip .twox-matchmaking-hover-text {
  font-family: "Montserrat", arial;
  padding: 10px 15px;
  font-size: 15px;
  letter-spacing: normal;
  visibility: hidden;
  width: 450px !important;
  height: fit-content !important;
  background-color: rgb(32, 23, 71) !important;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 9999;
  left: 100%;
  top: -30px;
}

.tooltip:hover .twox-matchmaking-hover-text {
  visibility: visible;
}
.tooltip .twox-matchmaking-hover-text::after {
  right: 95%;
}
</style>

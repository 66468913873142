<template>
    <div class="static assementPageStyle">
        <transition name="fadeIn" mode="out-in">
            <div id="notLoaded" v-if="!endofcreated" key="loading">
                <page-loader></page-loader>
            </div>
            <div id="loaded" class="container" key="load"
                v-if="endofcreated && roiPillarData && roiPillarData != '' && roiScoreData && roiScoreData != ''">
                <div class="PoweredbyTitle_logo" style="align-items: start">
                    <div class="PoweredbyTitleWrepper">
                        <div @click="backToAssess">
                            <img style="width: 25px; height: auto !important" class="cursor-pointer"
                                src="@/assets/images/arrow_forward_black.svg" />
                        </div>
                        <div @click="backToAssess">
                            <h2 class="
                                                text-medium
                                                ml-1
                                                cursor-pointer
                                                tracking-tighter
                                                font-medium
                                                print:text-xl
                                              ">
                                Back to Gender ROI™ Assessment
                            </h2>
                        </div>
                    </div>
                    <div class="powerd_invite-wrapper">
                        <div class="powerdby_logo_wrapper">
                            <div class="Poweredby_logoImg">
                                <span class="powerd-logo">Powered by:</span>
                                <img src="@/assets/images/roi/sweef-capital.png" alt="background-image"
                                    style="height: 50px" />
                                <img src="@/assets/images/logo-navy.png" alt="background-image" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="PoweredbyTitle_logo" style="padding-top: 0">
                    <div class="PoweredbyTitleWrepper">
                        <img v-if="investorData.logo && investorData.logo[0] && investorData.logo[0].url"
                            class="h-16 print:h-auto print:w-64" :src="investorData.logo[0].url" style="height: 10rem" />
                        <div>
                            <p class="text-3xl text-black ml-10 tracking-tighter font-bold print:text-7xl">{{
                                investorData.organizationName
                            }}'s Gender ROI™
                                Parameter
                                Assessment</p>
                            <h1 class="text-6xl ml-10 tracking-tighter font-medium print:text-7xl">
                                {{ roiPageIntro.name }}
                            </h1>
                        </div>
                    </div>
                </div>
                <transition name="fadeIn" mode="out-in">
                    <div id="loaded" key="content">
                        <!-- Assement Page Wrepper -->
                        <div class="AssementPageWrepper">
                            <div class="most_recent_message_section" id="mostRecentMessageSection" v-if="roiPageIntro.mostRecentSurveyData">
                                <span class="close" @click="closeMostRecentSurveyMessageSection()" style="top: -35px !important;right: -18px !important;">&times;</span>
                                <span class="">This is the most recently calculated Gender ROI™ Score based on a fully completed assessment. A new assessment is currently in progress, currently it is <b>{{ roiPageIntro.percentage | mathOnlyRound }}%</b>.</span>
                            </div>
                            <div class="OverviewBlock clear">
                                <div class="OverviewBlock_left_side mr-5">
                                    <div class="OverviewTitle">
                                        <h2 style="width: 200px">Overview</h2>
                                        <span></span>
                                    </div>
                                    <div class="equal_strength">

                                        <item :text="roiPageIntro.introText"
                                            class="overview_intro_text gap-10 text-justify print:leading-relaxed print:text-sm leading-7">
                                        </item>
                                    </div>
                                    <div class="pillar_domain flex">
                                        <div class="pillar_container">
                                            <h2>Pillar</h2>
                                            <div>
                                                <div class="flex items-center mb-4" style="height: 50px;"
                                                    v-for="(pillar, pillarIndex) in roiPageIntro.pillar"
                                                    :key="'pillar' + pillarIndex">
                                                    <img v-if="pillar == 'Resilience'" class="mr-3"
                                                        src="../../assets/images/roi/resilience.svg" />
                                                    <img v-if="pillar == 'Opportunity'" class="mr-3"
                                                        src="../../assets/images/roi/opportunity.svg" />
                                                    <img v-if="pillar == 'Inclusion'" class="mr-3"
                                                        src="../../assets/images/roi/inclusion.svg" />
                                                    <span>
                                                        {{ pillar }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="domain_container">
                                            <h2>Domain</h2>
                                            <div>
                                                <div class="flex items-center mb-4" style="height: 50px;"
                                                    v-for="(domain, domainIndex) in roiPageIntro.domain"
                                                    :key="'domain' + domainIndex">
                                                    <img v-if="domain == 'Society'" class="mr-3" style="width: 40px;"
                                                        src="../../assets/images/society.svg" />
                                                    <img v-if="domain == 'Leadership'" class="mr-3" style="width: 40px;"
                                                        src="../../assets/images/leadership.svg" />
                                                    <img v-if="domain == 'Workforce'" class="mr-3" style="width: 40px;"
                                                        src="../../assets/images/workforce.svg" />
                                                    <img v-if="domain == 'Value Chain'" class="mr-3" style="width: 40px;"
                                                        src="../../assets/images/value_chain.svg" />
                                                    <span>{{ domain }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="OverviewBlock_right_side ml-5">
                                    <div class="gender_roi_score" style="border-radius: 0;padding: 0;margin-bottom: 0">
                                        <!-- If subpagetitle is not Women's turnover ratio -->
                                        <div
                                            style=" background: white; border-radius: 10px;padding: 30px;margin-bottom: 2rem;padding-bottom: 0;"
                                            v-for="(score, scoreIndex) in roiScoreData" :key="'score4' + scoreIndex"
                                            v-show="score.dimension && score.domain && score.pillar && score.subpageTitle != `Women's turnover ratio`">
                                            <h1
                                                class="capitalize text-3xl text-black tracking-tighter font-bold print:text-7xl">
                                                {{ score?.parameterPageChartTitles }}</h1>
                                            <PillarDomainDimensionSection :score="score" :getScoredataLength="getScoredataLength"></PillarDomainDimensionSection>
                                            <div class="flex mb-10" v-if="score?.subpageData?.[0]?.fieldTypeForFrontendCharts == 'Women Percentage'">
                                                <div class="left_side flex">
                                                    <div class="orange-dot mr-5"></div>
                                                    <span class="font-bold">{{ financialYear[0] }}</span>
                                                </div>
                                                <div class="flex dot_with_text ml-5" v-if="financialYear[1]">
                                                    <div class="blue-dot mr-5"></div>
                                                    <span class="font-bold">{{ financialYear[1] }}</span>
                                                </div>
                                            </div>
                                            <PeopleGraphicChart v-if="score?.subpageData?.[0]?.fieldTypeForFrontendCharts == 'Women Percentage'" :score="score" :getScoredataLength="getScoredataLength"></PeopleGraphicChart>
                                            <div class="dimension_inner_section mb-8"
                                                style="padding: 2rem 0;">
                                                <TwoValuesChart
                                                    v-if="score?.subpageData?.[0]?.fieldTypeForFrontendCharts == 'Total Number'"
                                                    :score="score"
                                                    :getScoredataLength="getScoredataLength"
                                                    :roiPageIntro="roiPageIntro"
                                                    :financialYear="financialYear" >
                                                </TwoValuesChart>
                                                <MenWomenBarChart
                                                    v-if="score?.subpageData?.[0]?.fieldTypeForFrontendCharts == 'Total Number of Men'"
                                                    :score="score"
                                                    :getScoredataLength="getScoredataLength"
                                                    :roiPageIntro="roiPageIntro"
                                                    :financialYear="financialYear">
                                                </MenWomenBarChart>
                                                <RatioBarChart
                                                    v-if="score?.subpageData?.[0]?.fieldTypeForFrontendCharts == 'Ratio'"
                                                    :score="score"
                                                    :getScoredataLength="getScoredataLength"
                                                    :roiPageIntro="roiPageIntro"
                                                    :financialYear="financialYear">
                                                </RatioBarChart>
                                                <ScaleZeroThreeChart
                                                    v-if="score?.subpageData?.[0]?.fieldTypeForFrontendCharts == 'Scale 0-3'"
                                                    :score="score"
                                                    :getScoredataLength="getScoredataLength"
                                                    :roiPageIntro="roiPageIntro"
                                                    :financialYear="financialYear">
                                                </ScaleZeroThreeChart>
                                                <StringsChart
                                                    v-if="score?.subpageData?.[0]?.chartType == 'String'"
                                                    :score="score"
                                                    :getScoredataLength="getScoredataLength"
                                                    :roiPageIntro="roiPageIntro"
                                                    :financialYear="financialYear">
                                                </StringsChart>
                                                <ArrayChart
                                                    v-if="score?.subpageData?.[0]?.chartType == 'Array'"
                                                    :score="score"
                                                    :getScoredataLength="getScoredataLength"
                                                    :roiPageIntro="roiPageIntro"
                                                    :financialYear="financialYear">
                                                </ArrayChart>
                                            </div>
                                        </div>
                                        <!-- If subpagetitle is Women's turnover ratio -->
                                        <div
                                            style=" background: white; border-radius: 10px;padding: 30px;margin-bottom: 2rem;padding-bottom: 0;"
                                            v-for="(score, scoreIndex) in roiScoreData" :key="'score6' + scoreIndex"
                                            v-show="score.dimension && score.domain && score.pillar && score.subpageTitle == `Women's turnover ratio` && score.subpageData.length > 6">
                                            <SingularTurnoverSection
                                            :roiScoreData="roiScoreData"
                                            :score="score"
                                            :financialYear="financialYear"
                                            :getScoredataLength="getScoredataLength"
                                            :roiPageIntro="roiPageIntro">
                                            </SingularTurnoverSection>
                                        </div>
                                    </div>
                                </div>
                                <div class="OverviewBlock_right_side ml-5 mt-5">
                                    <div class="flex spacebetween">
                                        <div @click="backToAssess"
                                             class="prev-parameter text-white px-3 py-2 text-xs gap-1 rounded-lg text-white transition duration-200 shadow border flex flex-row items-center justify-center">
                                            <button class="capitalize text-white text-sm font-medium font-semibold">Back to Gender ROI™ Assessment</button>
                                        </div>
                                        <div @click="gotoNextParameter()"
                                            class="next-parameter text-white px-3 py-2 text-xs gap-1 rounded-lg text-white transition duration-200 shadow border flex flex-row items-center justify-center"
                                            v-if="disabledNextClassIs">
                                            <button class="capitalize text-white text-sm font-medium font-semibold">Next Parameter</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
            <div v-else key="else_div">
                <div class="flex flex-row justify-center items-center mt-3 ml-20">
                    <div class="flex flex-col item-center justify-center">
                        <div class="flex flex-row items-center justify-center print:flex-col">
                            <img class="h-16 print:h-auto print:w-64" src="@/assets/images/Writing-removebg.png" alt="" />
                            <h1 class="
                      text-6xl
                      tracking-tighter
                      font-medium
                      text-center
                      print:text-7xl
                      ml-5
                    ">
                                No Data Found
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        <!-- Learn more About Modal -->
        <div class="fixed z-10 inset-0" v-if="learnMoreAboutModal">
            <div class="
              flex
              items-end
              justify-center
              min-h-screen
              pt-4
              px-4
              pb-20
              text-center
              sm:block sm:p-0
            ">
                <div class="fixed inset-0 transition-opacity cursor-pointer" @click="closePopup()">
                    <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>

                <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
                <div class="
                inline-block
                align-bottom
                bg-white
                rounded-lg
                text-left
                shadow-xl
                transform
                transition-all
                sm:my-8 sm:align-middle
              " role="dialog" aria-modal="true" aria-labelledby="modal-headline"
                    style="width: 40% !important; margin-top: 6rem">
                    <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4"
                        style="box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);border-radius: 8px;">
                        <div class="sm:flex sm:items-start" style="height: auto">
                            <div class="
                      mt-3
                      text-center
                      sm:mt-0 sm:ml-4 sm:text-left
                      learn_more_modal_text_container
                    ">
                                <div class="flex">
                                    <h3 class="text-lg leading-6 font-bold text-gray-900" id="modal-headline"
                                        style="width: 360px;">
                                        About the Gender ROI™ Assessment
                                    </h3>
                                    <span class="close" @click="closePopup">&times;</span>
                                </div>
                                <div class="mt-5 overflow-y-auto learn_more_modal_text" id="style-1">
                                    <p>
                                        The Sweef Capital Gender ROI™️ (Gender ROI™️) is a
                                        diagnostic measurement and management tool that is designed
                                        to support entrepreneurs, investment and development finance
                                        practitioners to assess the gender equality and diversity
                                        performance of companies, informing both practice and
                                        performance in addressing inequalities, while highlighting
                                        significant opportunities to improve outcomes for women and
                                        girls.
                                    </p>
                                    <br />
                                    <p>
                                        The tool comprises of 24 quantitative and qualitative
                                        indicators applied across the intersectionality of the
                                        enterprise dimensions of Leadership, Workforce, Value
                                        Chains, as well as through Society and Outcome Pillars of
                                        Resilience, Opportunity, and Inclusion. The assessment
                                        identifies gaps, determining appropriate actions and
                                        interventions applied at the enterprise level which are
                                        focused on delivering outcomes that support women’s economic
                                        empowerment.
                                    </p>
                                    <br />
                                    <p>
                                        By applying different levers of change across the enterprise
                                        dimensions targeting women’s economic and social roles,
                                        contributions, and constraints and vulnerabilities, women’s
                                        economic empowerment is demonstrated through 3 Outcome
                                        Pillars - Resilience, Opportunities and Inclusion.
                                    </p>
                                    <br />
                                    <p>
                                        These Outcome Pillars are goalposts for women’s economic
                                        empowerment which involve addressing the need for women’s
                                        participation in gainful employment and to be in a position
                                        of influence and decision making, as well as ensuring equal
                                        access to opportunities for women’s participation and
                                        advancement, and safeguarding women’s well-being through
                                        safety nets and protection, thereby addressing gender norms
                                        and biases. These are also the key tenets in building strong
                                        and resilient companies to meet the challenges the dynamic
                                        business environment of today. Each stakeholder has a role
                                        to play; they are mutually beneficial and should be seen as
                                        a collective engine of sustainable and inclusive
                                        development.
                                    </p>
                                    <br />
                                    <p>
                                        To calculate Gender ROI™ scores across these 24 indicators,
                                        there are 16 discrete survey modules that ask questions
                                        across a range of topics. These surveys may be taken all at
                                        once, or over a discrete period of time, filled by one or
                                        more people as appropriate.
                                    </p>
                                    <br />
                                    <p>
                                        This tool is digitized via the Equilo platform, with the
                                        following benefits for users coming soon (data will be
                                        collected now, with the automated benefits below in beta
                                        testing and coming soon!):
                                    </p>
                                    <br />
                                    <ul style="list-style-type: disc">
                                        <li>
                                            Eases data collection and reporting, with multiple survey
                                            modules that can be completed by multiple people within an
                                            organization at any time.
                                        </li>
                                        <li>
                                            Automatically analyzes the reported data, displaying
                                            results instantly on a personalized dashboard. Users need
                                            to have an account created at app.equilo.io to access
                                            their results.
                                        </li>
                                        <li>
                                            Easily share dashboard and summary results or downloadable
                                            .CSV files with others.
                                        </li>
                                        <li>
                                            View organizational results alongside a contextual
                                            market-level analysis to better understand, contextualize,
                                            and address gaps.
                                        </li>
                                        <li>
                                            Data can be automatically aggregated for investors and
                                            financial actors at portfolio levels when enterprises
                                            complete this assessment across the portfolio.
                                        </li>
                                        <li>
                                            Receive intelligently automated action plan based on
                                            identified gaps.
                                        </li>
                                    </ul>
                                    <br />
                                    <p>
                                        Module 1 must be completed first, but all other modules may
                                        be completed in any order. Each survey module may be
                                        completed by the same or a different person. The Assessment
                                        is associated with the main user's email ID. This person may
                                        choose, for each module, to complete the survey, or may
                                        enter an email address to send a request to another person
                                        to complete the specific module.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Learn more About Modal -->

        <!-- Complete Survey Modal -->
        <div class="fixed z-10 inset-0" v-if="completeSurveyModal">
            <div class="
              flex
              items-end
              justify-center
              min-h-screen
              pt-4
              px-4
              pb-20
              text-center
              sm:block sm:p-0
            ">
                <div class="fixed inset-0 transition-opacity cursor-pointer" @click="closePopup()">
                    <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>

                <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
                <div class="
                inline-block
                align-bottom
                bg-white
                rounded-lg
                text-left
                shadow-xl
                transform
                transition-all
                sm:my-8 sm:align-middle
              " role="dialog" aria-modal="true" aria-labelledby="modal-headline"
                    style="width: 35% !important; margin-top: 6rem">
                    <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4"
                        style="box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);border-radius: 8px;">
                        <div class="sm:flex sm:items-start" style="height: auto">
                            <div class="
                      mt-3
                      text-center
                      sm:mt-0 sm:ml-4 sm:text-left
                      learn_more_modal_text_container
                    " style="width: 100%">
                                <div class="flex">
                                    <h1 class="text-lg leading-6 font-bold text-gray-900" id="modal-headline"
                                        style="width: 100%">
                                        Who will complete this Survey?
                                    </h1>
                                    <span class="close" @click="closePopup">&times;</span>
                                </div>
                                <div class="mt-5 overflow-y-auto learn_more_modal_text"
                                    style="height: auto; color: black; font-weight: 500">
                                    <input class="mr-4" type="radio" name="shared" id="myself"
                                        @change="isMyselfChecked()" />Complete this module myself
                                    <br />
                                    <input class="mr-4" type="radio" name="shared" id="other"
                                        @change="isMyselfChecked()" />Request a team member to complete this module
                                </div>
                                <div class="InviteBtn mt-5 mb-4" v-if="!isShareVisible">
                                    <div>
                                        <button class="text-white text-sm font-medium" @click="fillMyself" :class="
                                            isMyselfRadioChecked
                                                ? ''
                                                : 'pointer_event_none opacity_light'
                                        ">
                                            Launch
                                        </button>
                                    </div>
                                </div>
                                <div>
                                    <div class="EmailInvitemember mt-5 mb-4 flex" v-if="isShareVisible">
                                        <div class="EmailInput">
                                            <input type="email" placeholder="Enter Email Address"
                                                v-model="invitedUserEmail" />
                                        </div>
                                        <div class="InviteBtn tooltip">
                                            <div @click="onInviteClick">
                                                <button class="text-white text-sm font-medium">
                                                    Share
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Complete Survey Modal -->
        <!-- Most Recent Modal -->
        <div class="fixed z-50 inset-0 overflow-y-auto" v-if="mostRecentModal">
            <div class="
                      flex
                      items-end
                      justify-center
                      min-h-screen
                      pt-4
                      px-4
                      pb-20
                      text-center
                      sm:block sm:p-0
                    ">
              <div class="fixed inset-0 transition-opacity cursor-pointer">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
              </div>
              <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
              <div class="
                        inline-block
                        align-bottom
                        bg-white
                        rounded-lg
                        text-left
                        overflow-hidden
                        shadow-xl
                        transform
                        transition-all
                        sm:my-8 sm:align-middle
                      " role="dialog" aria-modal="true" aria-labelledby="modal-headline" style="max-width: 630px; width: 100%">
                <span class="close cursor-pointer" @click="mostRecentModal = false"  style="right: 8px;">&times;</span>
                <div class="Modal_Readmorestyle">
                  <div class="ModalInnerStyle">
                    <p style="font-weight: 600;text-align: center;">
                      You are viewing results from the most recently completed assessment. A repeat assessment is currently in progress, and
                      the dashboard will be updated with the results once the current assessment is 100% complete.
                    </p>
                    <button style="background-color: #fe5000; color: white;margin-bottom: 0;" class="
                              text-white
                              px-3
                              py-2
                              text-xs
                              gap-1
                              font-bold
                              rounded-lg
                              text-white
                              transition
                              duration-200
                              shadow
                              flex flex-row
                              items-center
                              justify-center
                              mr-4
                              ml-4
                            " @click="mostRecentModal = false">
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
</template>
<script>
import env from "@/../env";
import axios from "axios";
import Cookies from "@/helpers/cookies";
import pageLoader from "@/components/page-loader.vue";
import * as XLSX from "xlsx/xlsx.mjs";
import item from "@/components/text.vue";
import apiService from "@/helpers/apiServices.js";
import PillarDomainDimensionSection from "@/components/RoiGgsfCharts/PillarDomainDimensionSection.vue";
import PeopleGraphicChart from "@/components/RoiGgsfCharts/PeopleGraphicChart.vue";
import TwoValuesChart from "@/components/RoiGgsfCharts/TwoValuesChart.vue";
import SingularTurnoverSection from "@/components/RoiGgsfCharts/singularRoiTurnoverPillarSection.vue";
import MenWomenBarChart from "@/components/RoiGgsfCharts/MenWomenBarChart.vue";
import RatioBarChart from "@/components/RoiGgsfCharts/RatioBarChart.vue";
import ScaleZeroThreeChart from "@/components/RoiGgsfCharts/ScaleZeroThreeChart.vue";
import StringsChart from "@/components/RoiGgsfCharts/StringsChart.vue";
import ArrayChart from "@/components/RoiGgsfCharts/ArrayChart.vue";

export default {
    name: "roiPillar",
    data: function () {
        return {
            percentValue: "80%",
            roiPillarData: [],
            endofcreated: false,
            invitedUserEmail: "",
            learnMoreAboutModal: false,
            selectedSurvey: [],
            getScoredataLength: 0,
            completeSurveyModal: false,
            isShareVisible: false,
            isMyselfRadioChecked: false,
            isOtherRadioChecked: false,
            options: {
                text: {
                    color: "#0077C8",
                    shadowEnable: false,
                    shadowColor: "#000000",
                    fontSize: 14,
                    fontFamily: "Helvetica",
                    dynamicPosition: false,
                    hideText: false,
                },
                progress: {
                    color: "#0077C8",
                    backgroundColor: "#D0D0D0",
                    inverted: false,
                },
                layout: {
                    height: 40,
                    width: 50,
                    verticalTextAlign: 20,
                    horizontalTextAlign: 10,
                    zeroOffset: 0,
                    strokeWidth: 15,
                    progressPadding: 0,
                    type: "circle",
                },
            },
            json_data: [],
            csvData: [],
            roiPageIntro: [],
            roiScoreData: [],
            roiScoreData1: [],
            investorData: [],
            womenMiddleManagement: "Women's middle management representation",
            womenTurnOverRatio: "Women's turnover ratio",
            womenBusinessOwnership: "Women's business ownership",
            financialYear: [],
            notSingularHovertext: [
                'Not doing this in any way',
                'Informal, ad-hoc, or in draft form',
                'Formally adopted and in some level of implementation',
                'Fully operationalized and systematically implemented and practiced'
            ],
            mostRecentModal: false,
            roiData: [],
            currentIndex: 0,
            disabledNextClassIs:true,
            listOfParameters: [],
            thresholds: [],
            thresholdsData: [],
            workforceThreshold: "",
            srManagerThreshold: "",
            boardThreshold: "",
        };
    },
    components: {
        pageLoader,
        item,
        PillarDomainDimensionSection,
        PeopleGraphicChart,
        TwoValuesChart,
        SingularTurnoverSection,
        MenWomenBarChart,
        RatioBarChart,
        ScaleZeroThreeChart,
        StringsChart,
        ArrayChart,
    },
    filters: {
        textWithCommaRemoveOther(value) {
            value.forEach((data, d) => {
                if (data == 'Other') {
                    value.splice(d, 1);
                }
            })
            var data = Object.values(value).join(", ");
            return data;
        },

        removeHiddenFieldText(value) {
            if (value.includes('Formula (Hidden) Field:')) {
                return value.split('Formula (Hidden) Field:')[1];
            } else {
                return value;
            }
        },

        removePerchantageSign(str) {
            if (str?.startsWith('%')) {
                return str.slice(1);
            } else {
                return str;
            }
        }
    },
    async mounted() {
        await this.getRoiPillarData();
        await this.getRoiData();
        this.scrollToTop();
        if(this.roiData && this.roiData.scoreData && this.roiData.scoreData[0]) {
            this.disabledNextPevButton();
        }
        this.$store.commit("setBreadcrumbs", [
            {
                name: "Gender ROI™",
                url: "/roi",
            },
            {
                name: "Gender ROI™ Assessment",
                url: "/roi/roiAssessment",
            },
        ]);
        this.endofcreated = true;
        if (this.roiPageIntro && this.roiPageIntro.mostRecentSurveyData) {
            this.mostRecentModal = true;
        }
    },
    methods: {
        async calculateThreshold() {
            const thresholdsUrl = env.apiUrl + "/thresholds2x/all";
            const res = await axios.get(thresholdsUrl, {
                headers: {
                    Authorization: "Bearer " + Cookies.get("session"),
                },
            });
            this.thresholds = res.data.data.filter((item) => item.threshold != "");
            await this.getThresholdsData(
                this.investorData?.ISO[0],
                this.investorData.sector
            );
            const workforce = this.thresholds.filter(
                (item) =>
                    item.uniqueId.includes(this.investorData?.countryHqText) &&
                    item.indicator == "Employees" &&
                    (item.industry == this.investorData?.sector ||
                        item.industry == "Overall")
            );
            const srManager = this.thresholds.filter(
                (item) =>
                    item.uniqueId.includes(this.investorData?.countryHqText) &&
                    item.indicator == "Senior Manager" &&
                    (item.industry == this.investorData?.sector ||
                        item.industry == "Overall")
            );
            const board = this.thresholds.filter(
                (item) =>
                    item.uniqueId.includes(this.investorData?.countryHqText) &&
                    item.indicator == "Board Member" &&
                    (item.industry == this.investorData?.sector ||
                        item.industry == "Overall")
            );

            this.workforceThreshold =
                workforce.length == 1
                    ? workforce[0].threshold
                    : workforce.filter(
                        (item) => item.industry == this.investorData?.sector
                    )[0].threshold;
            this.srManagerThreshold =
                srManager.length == 1
                    ? srManager[0].threshold
                    : srManager.filter(
                        (item) => item.industry == this.investorData?.sector
                    )[0].threshold;
            this.boardThreshold =
                board.length == 1
                    ? board[0].threshold
                    : board.filter(
                        (item) => item.industry == this.investorData?.sector
                    )[0].threshold;
        },
        async getThresholdsData(recordId, sector) {
            await apiService.fetchThresholdsData(recordId, sector)
                .then(async (response) => {
                    this.thresholdsData = await response.data.data;
                });
        },
        changeIndex(array, currentIndex, newIndex) {
            let objectToMove = array.splice(currentIndex, 1)[0];
            array.splice(newIndex, 0, objectToMove);
            return array;
        },
        closeMostRecentSurveyMessageSection() {
            document.getElementById('mostRecentMessageSection').style.display = 'none';
        },
        scrollToTop() {
            window.scrollTo(0,0);
          },
        backToAssess() {
            if(this.investorData?.organizationName.trim() == this.user?.organization?.name.trim()) {
                this.$router.push({path: "/roi/roiAssessment"});
            } else {
                this.$router.push({path: "/roi/roiAssessment", query: {recordId: this.$route.query.recordId}});
            }
        },
        ec(r, c) {
            return XLSX.utils.encode_cell({ r: r, c: c });
        },
        deleteRow(ws, row_index) {
            var variable = XLSX.utils.decode_range(ws["!ref"]);
            for (var R = row_index; R < variable.e.r; ++R) {
                for (var C = variable.s.c; C <= variable.e.c; ++C) {
                    ws[this.ec(R, C)] = ws[this.ec(R + 1, C)];
                }
            }
            variable.e.r--;
            ws["!ref"] = XLSX.utils.encode_range(variable.s, variable.e);
        },
        onexport() {
            if (this.csvData.roiSurveyData) {
                var roiSurveyArray = [];
                Object.entries(this.csvData.roiSurveyData).forEach((data) => {
                    if (data && data[1]) {
                        roiSurveyArray.push(data[1]);
                    }
                });
            }

            if (this.csvData.scoringData) {
                var scoringArray = [];
                Object.entries(this.csvData.scoringData).forEach((data) => {
                    if (data && data[1]) {
                        scoringArray.push(data[1]);
                    }
                });
            }

            if (this.csvData.portfolioData) {
                var portfolioArray = [];
                Object.entries(this.csvData.portfolioData).forEach((data) => {
                    if (data && data[1]) {
                        portfolioArray.push(data[1]);
                    }
                });
            }

            if (this.csvData.roiSurveyData) {
                var roiSurvey = XLSX.utils.json_to_sheet(roiSurveyArray);
                this.deleteRow(roiSurvey, 0);
            }
            if (this.csvData.scoringData) {
                var scoring = XLSX.utils.json_to_sheet(scoringArray);
                this.deleteRow(scoring, 0);
            }
            if (this.csvData.portfolioData) {
                var portfolio = XLSX.utils.json_to_sheet(portfolioArray);
                this.deleteRow(portfolio, 0);
            }

            var wb = XLSX.utils.book_new(); // make Workbook of Excel

            if (this.csvData.roiSurveyData) {
                XLSX.utils.book_append_sheet(wb, roiSurvey, "My Survey"); // sheetAName is name of Worksheet
            }
            if (this.csvData.scoringData) {
                XLSX.utils.book_append_sheet(wb, scoring, "My Scores");
            }
            if (this.csvData.portfolioData) {
                XLSX.utils.book_append_sheet(wb, portfolio, "Portfolio Survey");
            }

            // export Excel file
            XLSX.writeFile(wb, this.roiPillarData.organizationName + ".XLSX"); // name of the file is 'book.XLSX'
        },

        fillMyself() {
            this.redirectToSurvey(this.selectedSurvey);
        },
        isMyselfChecked() {
            if (document.getElementById("myself").checked == true) {
                this.isShareVisible = false;
                this.isMyselfRadioChecked = true;
            }
            if (document.getElementById("other").checked == true) {
                this.isOtherRadioChecked = true;
                this.isShareVisible = true;
            }
        },
        moduleClicked(event) {
            let modVal = event.target.alt.split("/")[1];
            if (event.target.alt.split("/")[0] == "edit-image-icon") {
                this.selectedSurvey = [];
                this.modules.forEach((data) => {
                    if (data.module == modVal) {
                        this.selectedSurvey = data;
                    }
                });
                if (this.roiPillarData.shareAccess) {
                    Object.entries(this.roiPillarData).forEach((item) => {
                        if (item[0] == this.selectedSurvey.var) {
                            // if (item[1] == true || item[1] == "Yes") {
                            //   this.redirectToSurvey(this.selectedSurvey);
                            // }
                            if (item[1] == false) {
                                this.completeSurveyModal = true;
                            } else {
                                this.redirectToSurvey(this.selectedSurvey);
                            }
                        }
                    });
                } else {
                    this.redirectToSurvey(this.selectedSurvey);
                }
            }
        },
        redirectToSurvey(data) {
            this.closePopup();
            let routeData = this.$router.resolve({
                name: "roiSurvey",
                params: { surveyId: data.module },
            });
            window.open(routeData.href, "_blank");
        },
        closePopup: function () {
            this.learnMoreAboutModal = false;
            this.completeSurveyModal = false;
            this.isShareVisible = false;
        },
        pageReload() {
            window.location.reload();
        },
        validEmail(email) {
            var re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (re.test(email)) {
                return true;
            } else {
                return false;
            }
        },
        async onInviteClick() {
            let validate = this.validEmail(this.invitedUserEmail);
            if (validate) {
                let body = {
                    email: this.invitedUserEmail,
                    surveyName: this.selectedSurvey.name,
                    surveyUrl: "roi/survey/" + this.selectedSurvey.module,
                };
                let getInvitedUserData = await this.sendInviteToUser(body);
                if (getInvitedUserData.status) {
                    this.closePopup();
                    this.$swal.fire({
                        icon: "success",
                        text: getInvitedUserData.message,
                        showConfirmButton: true,
                    });
                } else {
                    this.$swal.fire({
                        icon: "error",
                        text: getInvitedUserData.message,
                        showConfirmButton: true,
                    });
                }
                this.invitedUserEmail = "";
            } else {
                alert("Please enter valid email address!");
            }
        },
        sendInviteToUser(body) {
            return new Promise(function (resolve) {
                axios
                    .post(env.apiUrl + "/roi/shared-assessments", body, {
                        headers: {
                            Authorization: "Bearer " + Cookies.get("session"),
                        },
                    })
                    .then(function (res) {
                        resolve(res.data.data);
                    });
            });
        },
        onlyLettersSpacesDots(str) {
            return /^[a-zA-Z\s.,]+$/.test(str);
        },
        getRoiPillarData() {
            let body = {
                "title": this.$route.query.survey,
                "recordId": this.$route.query.recordId
            }

            if (this.$route.query.roiPortfolio) {
                body = {
                    "title": this.$route.query.survey,
                    "recordId": this.$route.query.recordId,
                    "roiPortfolio": true
                }
            }
            return axios
                .post(env.apiUrl + "/roi/subpage-roi", body, {
                    headers: {
                        Authorization: "Bearer " + Cookies.get("session"),
                    },
                })
                .then(async (res) => {
                    this.roiPillarData = res.data.data;
                    this.roiPageIntro = res.data.data.RoiPageIntro;
                    this.getScoredataLength = res.data.data.Scoredata.length;
                    this.roiScoreData = res.data.data.Scoredata[0];
                    this.roiScoreData1 = res.data.data.Scoredata[1];
                    this.investorData = res.data.data.parameter[0];
                    this.financialYear = res.data.data.financialReportingYearData;
                    if(this.$route?.query?.survey == "Financial Processes, Products, & Services") {
                        await this.calculateThreshold();
                        this.roiScoreData.forEach(item => {
                            item?.subpageData.forEach(subpageItem => {
                                if (subpageItem?.surveyid === "Finance127" || subpageItem?.surveyid === "Finance128") {
                                    if(subpageItem?.score) {
                                        subpageItem.score = subpageItem?.score.map(scoreItem => {
                                            if(scoreItem.includes("[Varies]%")) {
                                                if (scoreItem.includes("women in senior management")) {
                                                    return scoreItem.replace("[Varies]%", this.srManagerThreshold);
                                                }
                                                if (scoreItem.includes("women on IC")) {
                                                    return scoreItem.replace("[Varies]%", this.boardThreshold);
                                                }
                                                if (scoreItem.includes("women in workforce")) {
                                                    return scoreItem.replace("[Varies]%", this.workforceThreshold);
                                                }
                                            }
                                            return scoreItem;
                                        });
                                    }
                                }
                            });
                        });
                    }
                    if (this.roiScoreData) {
                        this.roiScoreData.map(data => {
                            data.subpageData.forEach(item => {
                                if(item?.surveyQType == 'Multiple choice' ||
                                item?.surveyQType == 'Multiple countries' ||
                                item?.surveyQType == 'Multiple select drop-down') {
                                    item['chartType'] = "Array";
                                }
                                if(item?.surveyQType == 'Open text' ||
                                item?.surveyQType == 'Country' ||
                                item?.surveyQType == 'Phone number' ||
                                item?.surveyQType == 'Intro-Text' ||
                                item?.surveyQType == 'Single select drop-down' ||
                                item?.surveyQType == 'Yes/ No') {
                                    item['chartType'] = "String";
                                }
                                if(item?.surveyQType == 'Number') {
                                    item['chartType'] = "Number";
                                }
                                if(item?.surveyQType == 'Currency') {
                                    item['chartType'] = "Currency";
                                }
                            })
                        })
                    }
                    // "Middle Managers" and "Senior Managers" data if the user is a Companies/On-Lending, and we should show data about "Partners" and "Managing Partners" for Funds/Investors
                    // if(this.roiPageIntro?.typeFlag == 'Fund') {
                    //     this.roiScoreData = this.roiScoreData.map(obj => {
                    //         obj.subpageData = obj.subpageData.filter(item => item.surveyid !== "SeniorRep1");
                    //         obj.subpageData = obj.subpageData.filter(item => item.surveyid !== "SeniorRep2");
                    //         obj.subpageData = obj.subpageData.filter(item => item.surveyid !== "SeniorRep3");

                    //         obj.subpageData = obj.subpageData.filter(item => item.surveyid !== "MiddleRep1");
                    //         obj.subpageData = obj.subpageData.filter(item => item.surveyid !== "MiddleRep2");
                    //         obj.subpageData = obj.subpageData.filter(item => item.surveyid !== "MiddleRep3");
                    //         return obj;
                    //     });
                    // } else {
                    //     this.roiScoreData = this.roiScoreData.map(obj => {
                    //         obj.subpageData = obj.subpageData.filter(item => item.surveyid !== "SeniorRep4");
                    //         obj.subpageData = obj.subpageData.filter(item => item.surveyid !== "SeniorRep5");
                    //         obj.subpageData = obj.subpageData.filter(item => item.surveyid !== "SeniorRep6");

                    //         obj.subpageData = obj.subpageData.filter(item => item.surveyid !== "MiddleRep4");
                    //         obj.subpageData = obj.subpageData.filter(item => item.surveyid !== "MiddleRep5");
                    //         obj.subpageData = obj.subpageData.filter(item => item.surveyid !== "MiddleRep6");
                    //         return obj;
                    //     });
                    // }
                    // Removed empty subPageData array
                    // if (this.roiScoreData1) {
                    //     this.roiScoreData1 = this.roiScoreData1.filter(obj => obj.subpageData && obj.subpageData.length > 0)
                    // }
                    if(this.roiScoreData1) {
                        this.roiScoreData.map((item, index) => {
                            item.subpageData.forEach((object, i) => {
                                object.Oldscore = this.roiScoreData1[index].subpageData[i]?.score;
                            });
                        })
                    }
                    if (this.roiScoreData && this.roiScoreData[0] && this.roiScoreData[0].dimension) {
                        this.roiScoreData.filter((data) => {
                            if (data.dimension && data.domain && data.pillar && data.subpageData?.[0] && data.subpageData?.[1]) {
                                let hoverLable1 = data.subpageData[0].surveyQuestion.replace('What is the total', 'Total');
                                hoverLable1 = hoverLable1.replace('?', '');
                                let hoverLable2 = data.subpageData[1].surveyQuestion.replace('What is the total', 'Total');
                                hoverLable2 = hoverLable2.replace('?', '');
                                data.subpageData[1]['chartHoverLabels'] = [hoverLable2];
                                data.subpageData[1].chartHoverLabels.push(hoverLable1);
                            }
                        });
                    }
                    if (this.roiScoreData && this.roiScoreData[0] && this.roiScoreData[0].subpageTitle && this.roiScoreData[0].subpageTitle == 'Gendered approach to value chains') {
                        this.roiScoreData.filter((data) => {
                                let hoverLable1 = data.subpageData[0].surveyQuestion.replace('How many', '');
                                hoverLable1 = hoverLable1.replace('do you have?', '');
                                let hoverLable2 = data.subpageData[1].surveyQuestion.replace('How many', '');
                                hoverLable2 = hoverLable2.replace('?', '');
                                data.subpageData[1]['chartHoverLabels'] = [hoverLable2];
                                data.subpageData[1].chartHoverLabels.push(hoverLable1);

                                let hoverLable3 = data.subpageData[0].surveyQuestion.replace('How many', '');
                                hoverLable3 = hoverLable3.replace('?', '');
                                let hoverLable4 = data.subpageData[1].surveyQuestion.replace('How many', '');
                                hoverLable4 = hoverLable4.replace('?', '');
                                data.subpageData[1]['chartHoverLabels'] = [hoverLable4];
                                data.subpageData[1].chartHoverLabels.push(hoverLable3);
                        });
                    }
                    for (let index = 0; index < this.roiScoreData.length; index++) {
                        if (index > 0 && this.roiScoreData[index].subpageTitle !== this.roiScoreData[index - 1].subpageTitle) {
                            this.roiScoreData[index - 1]['showBottomRadius'] = true;
                        }
                        this.roiScoreData[this.roiScoreData.length - 1]['showBottomRadius'] = true;
                    }
                    this.roiScoreData.filter(data => {
                        data.subpageData.forEach(item => {
                            if (data.subpageTitle != `Women's turnover ratio` && (item.fieldTypeForFrontendCharts == 'Women Percentage' || item.surveyQuestion.includes('Formula (Hidden) Field')) && item?.score) {
                                item.score = item?.score > 1 ? (Number(item?.score)/100).toFixed(2) : item?.score; // if value is more than 1 then convert it in ratio
                                let percent = Math.round(Number(item?.score) * 100) > 100 ? 100 : Math.round(Number(item?.score) * 100);
                                data['currentPercent'] = percent.toString();
                                data['femaleIcon'] = Math.round((percent * 10) / 100);
                                let maleValue = (((100 - percent) * 10) / 100);
                                data['maleIcon'] = maleValue % 1 == 0.5 ? Math.floor(maleValue) : Math.round(maleValue);
                                let ans = item.surveyQuestion.split('Formula (Hidden) Field:')[1];
                                data['currentAnswer'] = ans;
                            }
                            if (data.subpageTitle == `Women's turnover ratio` && item.surveyid == 'Turnover1') {
                                let percent = Math.round(Number(item?.score) * 100) > 100 ? 100 : Math.round(Number(item?.score) * 100);
                                data['currentPercent'] = percent.toString();
                                data['femaleIcon'] = Math.round((percent * 10) / 100);
                                let maleValue = (((100 - percent) * 10) / 100);
                                data['maleIcon'] = maleValue % 1 == 0.5 ? Math.floor(maleValue) : Math.round(maleValue);
                            }
                            if (data.subpageTitle == `Women's turnover ratio` && item.surveyid == 'Turnover11') {
                                let percent3 = Math.round(Number(item?.score) * 100) > 100 ? 100 : Math.round(Number(item?.score) * 100);
                                data['currentPercent3'] = percent3.toString();
                                data['femaleIcon3'] = Math.round((percent3 * 10) / 100);
                                let maleValue = (((100 - percent3) * 10) / 100);
                                data['maleIcon3'] = maleValue % 1 == 0.5 ? Math.floor(maleValue) : Math.round(maleValue);
                            }
                        })
                    })
                    // Change index to make chart visible for BusOwn
                    this.roiScoreData = this.roiScoreData.map(obj => {
                        obj.subpageData.filter((item, i) => {
                            if ((item.fieldTypeForFrontendCharts == "Total Number" || item.fieldTypeForFrontendCharts == "Total Number of Men") && obj.subpageTitle !== "Women's turnover ratio") {
                                obj.subpageData = this.changeIndex(obj.subpageData, i, 0);
                            }
                        });
                        return obj;
                    })
                    this.roiScoreData = this.roiScoreData.map(obj => {
                        obj.subpageData.filter((item, i) => {
                            if (item.fieldTypeForFrontendCharts == "Total Number of Women" && obj.subpageTitle !== "Women's turnover ratio") {
                                obj.subpageData = this.changeIndex(obj.subpageData, i, 1);
                            }
                        });
                        return obj;
                    })
                    if (this.roiScoreData1 && this.roiScoreData1[0] && this.roiScoreData1[0].dimension && this.roiScoreData1[0].uiDisplay == 'Percentage') {
                        this.roiScoreData1.filter((data) => {
                            if (data.dimension && data.domain && data.pillar && data.subpageData?.[0] && data.subpageData?.[1]) {
                                let hoverLable1 = data.subpageData[0].surveyQuestion.replace('What is the total', 'Total');
                                hoverLable1 = hoverLable1.replace('?', '');
                                let hoverLable2 = data.subpageData[1].surveyQuestion.replace('What is the total', 'Total');
                                hoverLable2 = hoverLable2.replace('?', '');
                                data.subpageData[1]['chartHoverLabels'] = [hoverLable2];
                                data.subpageData[1].chartHoverLabels.push(hoverLable1);
                            }
                        })
                    }
                    if (this.roiScoreData1 && this.roiScoreData1[0] && this.roiScoreData1[0].subpageTitle && this.roiScoreData[0].subpageTitle == 'Gendered approach to value chains') {
                        this.roiScoreData1.filter((data) => {
                                let hoverLable1 = data.subpageData[0].surveyQuestion.replace('How many', '');
                                hoverLable1 = hoverLable1.replace('?', '');
                                let hoverLable2 = data.subpageData[1].surveyQuestion.replace('How many', '');
                                hoverLable2 = hoverLable2.replace('?', '');
                                data.subpageData[1]['chartHoverLabels'] = [hoverLable2];
                                data.subpageData[1].chartHoverLabels.push(hoverLable1);

                                let hoverLable3 = data.subpageData[0].surveyQuestion.replace('How many', '');
                                hoverLable3 = hoverLable3.replace('?', '');
                                let hoverLable4 = data.subpageData[1].surveyQuestion.replace('How many', '');
                                hoverLable4 = hoverLable4.replace('?', '');
                                data.subpageData[1]['chartHoverLabels'] = [hoverLable4];
                                data.subpageData[1].chartHoverLabels.push(hoverLable3);
                        })
                    }
                    if (this.roiScoreData1) {
                        for (let index = 0; index < this.roiScoreData1.length; index++) {
                            if (index > 0 && this.roiScoreData1[index].subpageTitle !== this.roiScoreData1[index - 1].subpageTitle) {
                                this.roiScoreData1[index - 1]['showBottomRadius'] = true;
                            }
                            this.roiScoreData1[this.roiScoreData1.length - 1]['showBottomRadius'] = true;
                        }
                        this.roiScoreData1.filter(data => {
                            data.subpageData.forEach(item => {
                                if (data.subpageTitle != `Women's turnover ratio` && (item.fieldTypeForFrontendCharts == 'Women Percentage' || item.surveyQuestion.includes('Formula (Hidden) Field')) && item?.score) {
                                    item.score = item?.score > 1 ? (Number(item?.score)/100).toFixed(2) : item?.score; // if value is more than 1 then convert it in ratio
                                    let percent = Math.round(Number(item?.score) * 100);
                                    data['currentPercent'] = percent.toString();
                                    data['femaleIcon'] = Math.round((percent * 10) / 100);
                                    let maleValue = (((100 - percent) * 10) / 100);
                                    data['maleIcon'] = maleValue % 1 == 0.5 ? Math.floor(maleValue) : Math.round(maleValue);
                                    let ans = item.surveyQuestion.split('Formula (Hidden) Field:')[1];
                                    data['currentAnswer'] = ans;
                                }
                                if (data.subpageTitle == `Women's turnover ratio` && item.surveyid == 'Turnover1') {
                                    let percent = Math.round(Number(item?.score) * 100) > 100 ? 100 : Math.round(Number(item?.score) * 100);
                                    data['currentPercent'] = percent.toString();
                                    data['femaleIcon'] = Math.round((percent * 10) / 100);
                                    let maleValue = (((100 - percent) * 10) / 100);
                                    data['maleIcon'] = maleValue % 1 == 0.5 ? Math.floor(maleValue) : Math.round(maleValue);
                                }
                                if (data.subpageTitle == `Women's turnover ratio` && item.surveyid == 'Turnover11') {
                                    let percent3 = Math.round(Number(item?.score) * 100) > 100 ? 100 : Math.round(Number(item?.score) * 100);
                                    data['currentPercent3'] = percent3.toString();
                                    data['femaleIcon3'] = Math.round((percent3 * 10) / 100);
                                    let maleValue = (((100 - percent3) * 10) / 100);
                                    data['maleIcon3'] = maleValue % 1 == 0.5 ? Math.floor(maleValue) : Math.round(maleValue);
                                }
                            })
                        })
                        this.roiScoreData.map((item, index) => {
                            item.subpageData.forEach((object, i) => {
                                object.Oldscore = (this.roiScoreData1[index].subpageData[i]?.score && this.roiScoreData1[index].subpageData[i]?.score !== '') ? this.roiScoreData1[index].subpageData[i]?.score : '';
                            });
                            item.OldcurrentPercent = this.roiScoreData1[index]?.currentPercent;
                            item.OldfemaleIcon = this.roiScoreData1[index]?.femaleIcon;
                            item.OldmaleIcon = this.roiScoreData1[index]?.maleIcon;
                            item.OldcurrentPercent3 = this.roiScoreData1[index]?.currentPercent3;
                            item.OldfemaleIcon3 = this.roiScoreData1[index]?.femaleIcon3;
                            item.OldmaleIcon3 = this.roiScoreData1[index]?.maleIcon3;
                        })
                    }
                    // Change index to make chart visible for BusOwn
                    if (this.roiScoreData1) {
                        this.roiScoreData1 = this.roiScoreData1.map(obj => {
                            obj.subpageData.filter((item, i) => {
                                if ((item.fieldTypeForFrontendCharts == "Total Number" || item.fieldTypeForFrontendCharts == "Total Number of Men") && obj.subpageTitle !== "Women's turnover ratio") {
                                    obj.subpageData = this.changeIndex(obj.subpageData, i, 0);
                                }
                            });
                            return obj;
                        })
                        this.roiScoreData1 = this.roiScoreData1.map(obj => {
                            obj.subpageData.filter((item, i) => {
                                if (item.fieldTypeForFrontendCharts == "Total Number of Women" && obj.subpageTitle !== "Women's turnover ratio") {
                                    obj.subpageData = this.changeIndex(obj.subpageData, i, 1);
                                }
                            });
                            return obj;
                        })
                    }
                    this.roiScoreData.map((data) => {
                        data["isNotApplicable"] = false;
                        let menTotal;
                        data.subpageData.forEach(item => {
                            if(item?.fieldTypeForFrontendCharts == 'Total Number' && (item.score == '0' || item.score == '0.0' || item.Oldscore == '0' || item.Oldscore == '0.0')) {
                                data.isNotApplicable = true;
                            }
                            if(item?.fieldTypeForFrontendCharts == 'Total Number of Men' && (item.score == '0' || item.score == '0.0' || item.Oldscore == '0' || item.Oldscore == '0.0')) {
                                menTotal = true;
                            }
                            if(item?.fieldTypeForFrontendCharts == 'Total Number of Women' && menTotal && (item.score == '0' || item.score == '0.0' || item.Oldscore == '0' || item.Oldscore == '0.0')) {
                                data.isNotApplicable = true;
                            }
                        })
                    })
                    this.roiScoreData.map((data, d) => {
                        if (data.subpageData?.[1]?.surveyid == 'Turnover2' ||
                            data.subpageData?.[1]?.surveyid == 'TopTenPaid2' ||
                            data.subpageData?.[1]?.surveyid == 'WageEquityPT2' ||
                            data.subpageData?.[1]?.surveyid == 'WageEquity2') {
                            if (data.subpageData?.[1]?.score != '' && data.subpageData?.[2]?.score != '') {
                                data.subpageData[1].score = String(Math.round(Number(data.subpageData[1]?.score) + Number(data.subpageData[2]?.score))) + '.0';
                                if (this.roiScoreData1) {
                                    data.subpageData[1].Oldscore = String(Math.round(Number(this.roiScoreData1[d].subpageData[1]?.score) + Number(this.roiScoreData1[d].subpageData[2]?.score))) + '.0';
                                }
                            }
                        }
                    })
                    console.log("this.roiScoreData ", this.roiScoreData)
                })
                .catch(() => {
                    this.endofcreated = true;
                });
        },
        
        getRoiData() {
          return axios
            .get(env.apiUrl + '/roi/get-parameter-data', {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            })
            .then((res) => {
                this.roiData = res.data;
                this.roiData.scoreData[0].Resilience.map((subItem)=>{
                    if (this.roiPageIntro.typeFlag !== 'Fund') {
                        if (subItem.chartTitle !== 'Managing Partners' && subItem.chartTitle !== 'Partners') {
                            this.listOfParameters.push(subItem);
                        }
                    }
                    else {
                        if (subItem.chartTitle !== 'Senior Managers' && subItem.chartTitle !== 'Middle Managers') {
                            this.listOfParameters.push(subItem);
                        }
                    }
                });
                this.roiData.scoreData[0].Opportunities.map((subItem)=>{
                    this.listOfParameters.push(subItem);
                });
                this.roiData.scoreData[0].Inclusion.map((subItem)=>{
                    this.listOfParameters.push(subItem);
                });
            })
            .catch(() => {
              this.endofcreated = true;
            });
        },
        
       disabledNextPevButton(){
            let surveyNameIs = this.$route.query.survey
            this.roiData.scoreData[0].Inclusion.map((subItem,index)=>{
                if((subItem.parameter == surveyNameIs) && (index == this.roiData.scoreData[0].Inclusion.length - 1)){
                    this.disabledNextClassIs = false
                }
            });
       },
        
        async gotoNextParameter(){
            let roiScoreData = this.roiPillarData.Scoredata[0];
            let selectedParameters = '';
            let surveyNameIs = this.$route.query.survey
            
            if(roiScoreData.length > 1){
                roiScoreData = roiScoreData.filter(function( obj ) {
                    return obj.subpageTitle !== surveyNameIs;
                });
                
                // this.listOfParameters = this.listOfParameters.filter(function(obj) {
                //     return !this.has(obj.parameter);
                // }, new Set(roiScoreData.map(obj => obj.subpageTitle)));
            }           
                   
            this.listOfParameters.map((item,index)=>{
                if(item.parameter == surveyNameIs){
                    this.currentIndex = index;
                }
            });
            
            if(this.currentIndex < this.listOfParameters.length){
                ++this.currentIndex
            }
            
            if(this.currentIndex == (this.listOfParameters.length - 1)){
                this.disabledNextClassIs = false
            }else{
                this.disabledNextClassIs = true
            }
            
            selectedParameters = this.listOfParameters[this.currentIndex];
                        
            let queryParam = {
                survey: selectedParameters.parameter,
                recordId: this.$route.query.recordId
            }
    
            if (this.$route.query.roiPortfolio) {
                queryParam = {
                  survey: selectedParameters.parameter,
                  recordId: this.$route.query.recordId,
                  roiPortfolio: true
                }
            }
    
            this.$store.state.citationIds = [];
            if(this.currentIndex < this.listOfParameters.length){
                await this.$router.replace({
                    name: "roiPillar",
                    query: queryParam
                })                
            }
            await this.getRoiPillarData();
        },
    },
    computed: {
        genderRoiScore() {
            let val = "";
            if (this.roiPillarData.genderRoiScore) {
                val = Number(this.roiPillarData.genderRoiScore).toFixed(2);
            }
            if (
                this.roiPillarData.genderRoiScore == 0 &&
                this.roiPillarData.genderRoiScore !== null
            ) {
                val = 0;
            }
            if (
                (this.roiPillarData.genderRoiScore == null ||
                    this.roiPillarData.genderRoiScore == "") &&
                this.roiPillarData.genderRoiScore !== 0
            ) {
                val = "";
            }
            return val;
        },
        companySize() {
            let state = "";
            let val = Math.round(Number(this.roiPillarData.womworkforceTwo));
            if (val <= 10) {
                state = "Micro";
            } else if (val > 10 && val <= 250) {
                state = "SME";
            } else if (val > 250) {
                state = "Large";
            }
            return state;
        },
    },
};
</script>
<style lang="scss" scoped>
.close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    top: -8px;
    position: relative;
  }
  .Modal_Readmorestyle {
    padding: 45px 0 45px 45px;
    position: relative;
    display: flex;
  
    .ModalInnerStyle {
      max-height: 630px;
      overflow: auto;
      background: #fff;
      padding-right: 30px;
  
      h1 {
        font-weight: bold;
        line-height: 16px;
        color: #1b1b1b;
        margin-bottom: 5px;
        text-align: center;
        padding-bottom: 30px;
      }
  
      p {
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        text-align: left;
        line-height: 18px;
        color: #1b1b1b;
      }
  
      button {
        margin: 30px auto;
        order: none;
        padding: 15px 32px;
        font-size: 16px;
      }
    }
  
    .cross_Icon {
      position: absolute;
      top: 15px;
      right: 25px;
  
      img {
        width: 15px;
        height: 15px;
        object-fit: contain;
        margin: 0 !important;
        cursor: pointer;
      }
    }
  }
.spacebetween{
    justify-content: space-between;
}
.next-parameter{
    height:40px;
    background-color: #201747; 
    color: white !important; 
    cursor: pointer;
}
.prev-parameter{
    height:40px;
    background-color: #201747;
    margin-right:130px; 
    color: white !important; 
    cursor: pointer;
}

.DownloadBtn {
    p {
        display: flex;
        align-items: center;
        border: 1px solid #1b1b1b;
        border-radius: 20px;
        color: #1b1b1b;
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        padding: 11px 17px;
        transition: 0.3s ease;

        img {
            margin-right: 5px;
            width: 15px;
            height: 15px;
        }

        &:hover {
            background: rgb(32, 23, 71);
            color: #fff;
            border-color: rgb(32, 23, 71);
        }
    }
}

.k-progress {
    margin: 0 !important;
}

.grey-dot {
    width: 10px !important;
    height: 10px;
    background-color: #9d9d9c;
    border-radius: 10px;
    top: 6px;
    position: relative;
}

.orange-dot {
    width: 10px !important;
    height: 10px;
    background-color: #fe5000;
    border-radius: 10px;
    top: 6px;
    position: relative;
}

.blue-dot {
    width: 10px !important;
    height: 10px;
    background-color: #201747;
    border-radius: 10px;
    top: 6px;
    position: relative;
}

.yellow-dot {
    width: 10px !important;
    height: 10px;
    background-color: #fae053;
    border-radius: 10px;
    top: 6px;
    position: relative;
    cursor: pointer;
}

.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .bonus_score_tooltiptext {
    font-family: "Montserrat", arial;
    padding: 20px;
    font-size: medium;
    letter-spacing: normal;
    visibility: hidden;
    width: 600px;
    background-color: #201747;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    left: -250px;
    top: 20px;
}

.tooltip:hover .bonus_score_tooltiptext {
    visibility: visible;
}

.tooltip .bonus_score_tooltiptext::after {
    right: 95%;
}

#style-1::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #ffff;
    border-radius: 10px;
    background-color: #ffff;
}

#style-1::-webkit-scrollbar {
    width: 6px;
    background-color: #ffff;
}

#style-1::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #c0c0c1;
    background-color: #c0c0c1;
}

.pointer_event_none {
    pointer-events: none;
}

.opacity_light {
    opacity: 0.5;
}

.learn_more_modal_text_container {
    h2 {
        width: 60%;
    }

    .close {
        color: #aaaaaa;
        float: right;
        font-size: 28px;
        font-weight: bold;
        top: -8px;
        position: relative;
        width: 50%;
        text-align: end;
        cursor: pointer;
    }

    .learn_more_modal_text {
        height: 68vh;

        ::-webkit-scrollbar {
            width: 14px;
        }

        input {
            accent-color: black;
        }
    }
}

.assementPageStyle {
    background: #f5f6f9;
    margin-top: 65px;

    .EmailInput {
        padding: auto 0;
        // border: 1px solid gainsboro;
        border-radius: 5px;
        color: black;

        input {
            background: #fff;
            border: 0.9px solid #d0d0d0;
            padding: 10px 14px;
            border-radius: 4px;
            color: #1b1b1b;
            font-size: 13px;
            font-weight: 500;
            width: 240px;
            height: 36px;
            margin-right: 10px;
            height: 100%;

            ::placeholder {
                color: #d0d0d0;
                font-size: 13px;
                font-weight: 500;
            }
        }
    }

    .container {
        margin: 0 auto;

        .modal {
            display: none;
            /* Hidden by default */
            position: fixed;
            /* Stay in place */
            z-index: 1;
            /* Sit on top */
            padding-top: 100px;
            /* Location of the box */
            left: 0;
            top: 0;
            width: 100%;
            /* Full width */
            height: 100%;
            /* Full height */
            overflow: auto;
            /* Enable scroll if needed */
            background-color: rgb(0, 0, 0);
            /* Fallback color */
            background-color: rgba(0, 0, 0, 0.4);
            /* Black w/ opacity */
        }

        /* Modal Content */
        .modal-content {
            background-color: #fefefe;
            margin: auto;
            padding: 20px;
            border: 1px solid #888;
            width: 40%;
            border-radius: 20px;

            .selected-class {
                background-color: green;
            }
        }

        /* The Close Button */
        .close {
            color: #aaaaaa;
            float: right;
            font-size: 28px;
            font-weight: bold;
            top: -8px;
            position: relative;
        }

        .close:hover,
        .close:focus {
            color: #000;
            text-decoration: none;
            cursor: pointer;
        }

        .save-opportunity-button {
            text-align: right;
        }

        .save-opportunity-button button {
            background: #201747;
            color: #fff;
            border-color: #201747;
            border: 1px solid #1b1b1b;
            height: 36px;
            border-radius: 20px;
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            padding: 9px 11px;
            -webkit-transition: 0.3s ease;
            transition: 0.3s ease;
        }
    }

    .PoweredbyTitle_logo {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-top: 30px;

        .PoweredbyTitleWrepper {
            max-width: 847px;
            widows: 100%;
            display: flex;
            align-items: center;

            img {
                width: 100px;
                height: 100px !important;
                object-fit: contain;
                // display: none;
            }

            h1 {
                font-family: Montserrat;
                font-style: normal;
                font-weight: 700;
                font-size: 50px;
                line-height: 61px;
                color: #000;
            }

            h2 {
                font-family: Montserrat;
                font-style: normal;
                font-weight: 600;
                font-size: 22px;
                line-height: 61px;
                color: #000;
            }
        }

        .Poweredby_logoImg {
            display: flex;
            justify-content: flex-end;

            .powerd-logo {
                font-size: 12px;
                color: #000;
                margin-right: 50px;
            }

            img {
                max-width: 100px;
                height: 33px;
                object-fit: contain;
                margin-right: 35px;
                max-width: 100px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .invite_members {
            input {
                background: #fff;
                border: 1px solid #d0d0d0;
                height: 36px;
                border-radius: 4px;
                padding: 5px;

                .invite-member-btn {}

                &::placeholder {
                    font-size: 13px;
                    color: #d0d0d0;
                }
            }
        }

        .EmailInvitemember {
            display: flex;
            margin-top: 33px;

            .EmailInput {
                padding: auto 0;
                border: 1px solid gainsboro;
                border-radius: 5px;

                input {
                    background: #fff;
                    border: 0.9px solid #d0d0d0;
                    padding: 10px 14px;
                    border-radius: 4px;
                    color: #1b1b1b;
                    font-size: 13px;
                    font-weight: 500;
                    width: 240px;
                    height: 36px;
                    margin-right: 10px;
                    height: 100%;

                    ::placeholder {
                        color: #d0d0d0;
                        font-size: 13px;
                        font-weight: 500;
                    }
                }
            }

            .InviteBtn {
                button {
                    border: 1px solid #1b1b1b;
                    height: 36px;
                    color: #1b1b1b;
                    border-radius: 20px;
                    font-weight: bold;
                    font-size: 13px;
                    line-height: 16px;
                    padding: 9px 11px;
                    transition: 0.3s ease;

                    &:hover {
                        background: rgb(32, 23, 71);
                        color: #fff;
                        border-color: rgb(32, 23, 71);
                    }
                }
            }
        }
    }

    .InviteBtn {
        button {
            border: 1px solid #1b1b1b;
            height: 36px;
            color: #1b1b1b;
            border-radius: 20px;
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            padding: 9px 11px;
            transition: 0.3s ease;
            opacity: 0.7;

            &:hover {
                background: rgb(32, 23, 71);
                color: #fff;
                border-color: rgb(32, 23, 71);
            }
        }

        .tooltip {
            position: relative;
            display: inline-block;
        }

        .tooltip .tooltiptext {
            font-family: "Montserrat", arial;
            padding: 20px;
            font-size: medium;
            letter-spacing: normal;
            visibility: hidden;
            width: 600px;
            background-color: #201747;
            color: #fff;
            text-align: center;
            border-radius: 6px;
            /* Position the tooltip */
            position: absolute;
            z-index: 1;
            left: 20px;
            top: -160px;
        }

        .tooltip:hover .tooltiptext {
            visibility: visible;
        }

        .tooltip .tooltiptext::after {
            right: 95%;
        }
    }

    .fav-image {
        margin: auto !important;
        margin-right: 15px !important;
        padding: 0;
        cursor: pointer;
    }

    .AssementPageWrepper {
        margin-top: 30px;
        padding-bottom: 60px;
        .most_recent_message_section {
            background-color: #ffffff;
            border-radius: 10px;
            padding: 40px;
            margin-bottom: 40px;
        }
        .clear:after {
            clear: both;
            content: "";
            display: table;
        }
        .OverviewBlock {

            // background: #ffffff;
            // border-radius: 10px;
            // padding: 0 40px 40px;
            // padding-top: 40px;
            // margin-bottom: 18px;
            .OverviewBlock_left_side {
                display: inline-block;
                width: 40%;
                background: #ffffff;
                border-radius: 10px;
                padding: 30px;
                height: 100%;
                float: left;

                .equal_strength {
                    border-bottom: 2px solid #d0d0d0;
                    padding-bottom: 2rem;

                    .overview_intro_text {
                        word-break: break-word;
                    }

                    li {
                        margin: 0;
                        padding: 5px 0;
                    }
                }

                .pillar_domain {
                    padding: 2rem 0;

                    h2 {
                        font-size: 13px;
                        font-weight: 700;
                        padding: 1rem 0;
                    }

                    .pillar_container {
                        width: 50%;
                    }

                    .domain_container {
                        width: 50%;
                    }
                }
            }

            .OverviewBlock_right_side {
                width: 58%;
                height: 100%;
                display: block;
                margin-left: 42%;

                .pillar_domain {
                    padding: 2rem 0;
                    // border-bottom: 2px solid #d0d0d0;

                    h2 {
                        font-size: 13px;
                        font-weight: 700;
                        padding: 1rem 0;
                    }

                    .pillar_container {
                        width: 50%;
                    }

                    .domain_container {
                        width: 50%;
                    }
                }

                .gender_roi_score {
                    // background: white;
                    // border-radius: 10px;
                    padding: 30px;
                    // margin-bottom: 2rem;

                    .dimensions-container {
                        padding: 2rem 0;

                        .dimension_percentage_comparison {
                            .male_female_icons {
                                width: 40%;

                                img {
                                    width: 25px;
                                }
                            }

                            .comparison_text {
                                width: 60%;
                            }
                        }

                        .dimension_inner_section {
                            .dot_with_text {
                                padding: 0 15px;
                                margin: auto 0 auto auto;
                            }

                            .dimension_inner_name {
                                .dimension_inner_left_side {
                                    width: 100%;

                                    .dimension_survey_question {
                                        font-weight: 700;
                                        color: black;
                                        word-break: break-word;
                                        min-height: 70px;

                                        .survey_question_inner {
                                            width: 60%;
                                        }

                                        .barchart_inner {
                                            width: 40%;
                                        }

                                        .multi_values_inner {
                                            width: 30%;
                                            margin-left: 40px;
                                            font-weight: 500;
                                            font-size: 1rem;
                                        }
                                    }
                                }

                                .dimension_inner_right_side {
                                    // width: 40%;
                                }
                            }
                        }
                    }

                    .gender_roi_score_chart {
                        width: 100%;
                    }
                }
            }

            .take_survey_container {
                .take_survey_inner {
                    h2 {
                        display: flex;

                        img {
                            width: 24px;
                            height: 24px;
                            margin-right: 8px;
                            object-fit: contain;
                        }
                    }

                    width: 50%;
                    padding: 5px;

                    ul {
                        margin-top: 20px;

                        li {
                            display: flex;
                            font-size: 13px;
                            font-weight: 700;
                            line-height: 16px;
                            letter-spacing: 0px;
                            text-align: left;
                            margin-left: 0;
                            padding-bottom: 10px;

                            .edit_button {
                                width: 16px;
                                height: 16px;
                            }

                            span {
                                width: 70%;
                            }

                            img {
                                height: 20px;
                                padding-left: 40px;
                            }
                        }
                    }
                }
            }

            .grey_border {
                display: inline-block;
                border-bottom: 2px solid #d0d0d0;
                width: 100%;
                height: 2px;
                margin: 35px 0;
            }

            .progress_bar_container {
                h2 {
                    margin: auto 20px auto 0;
                    width: 40%;
                }

                .span_text {
                    padding-left: 68px;
                    width: 100%;
                    font-size: 15px;
                    font-weight: 500;
                    line-height: 18px;
                    letter-spacing: 0px;
                    text-align: right;
                }
            }

            .OverviewTitle {
                display: flex;
                align-items: center;

                h2 {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 25px;
                    line-height: 30px;
                    color: #1b1b1b;
                    margin-right: 17px;
                    position: relative;
                }

                span {
                    display: inline-block;
                    border-bottom: 2px solid #1b1b1b;
                    width: 100%;
                    height: 2px;
                }
            }

            .spiderchartEligibleWrepper {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;

                .Xeligibleconten {
                    width: 50%;

                    .EligibleListWrepper {
                        display: flex;
                        align-items: center;
                        margin-bottom: 15px;

                        .EligibleListImg {
                            position: relative;
                            z-index: 1;

                            &::after {
                                content: "";
                                display: inline-block;
                                border: 1px solid #d0d0d0;
                                position: absolute;
                                height: 30px;
                                top: 18px;
                                left: 8px;
                                z-index: -1;
                            }

                            img {
                                border-radius: 50%;
                                object-fit: contain;
                                margin: 0 !important;
                                margin-right: 15px !important;
                            }
                        }

                        .bigImg {
                            margin-left: -10px;

                            &::after {
                                top: 40px;
                                left: 18px;
                                height: 28px;
                            }

                            img {
                                width: 40px !important;
                                height: 40px;
                            }
                        }

                        .lastAffter {
                            &::after {
                                content: none;
                            }
                        }

                        .EligibleTitle {
                            display: flex;
                            align-items: center;

                            img {
                                width: 20px;
                                height: 20px;
                                margin-right: 10px;
                                object-fit: contain;
                            }

                            p {
                                font-style: normal;
                                font-weight: bold;
                                font-size: 18px;
                                line-height: 22px;
                                color: #1b1b1b;
                            }

                            h3 {
                                font-size: 25px;
                                font-style: normal;
                                font-weight: bold;
                                line-height: 30px;
                                color: #1b1b1b;
                            }
                        }
                    }

                    .bigMargin {
                        margin-bottom: 25px;
                    }

                    .BorderLine {
                        border: 1px solid #d0d0d0;
                        max-width: 375px;
                        margin: 30px 0;
                    }

                    .PartyValidatedToolTip {
                        display: flex;
                        align-items: center;
                        margin-bottom: 20px;

                        .PartyTooltipImg {
                            img {
                                border-radius: 50%;
                            }
                        }

                        .TooltipImgText {
                            display: flex;
                            align-items: center;

                            img {
                                width: 15px;
                                height: 15px;
                                margin-left: 5px;
                            }

                            p {
                                font-style: normal;
                                font-weight: bold;
                                font-size: 18px;
                                line-height: 22px;
                                color: #1b1b1b;
                            }

                            .tooltiptext {
                                max-width: 400px;
                                top: 20px;
                            }
                        }
                    }

                    .DownloadBtn {
                        p {
                            display: flex;
                            align-items: center;
                            border: 1px solid #1b1b1b;
                            border-radius: 20px;
                            color: #1b1b1b;
                            font-weight: bold;
                            font-size: 13px;
                            line-height: 16px;
                            padding: 11px 17px;
                            transition: 0.3s ease;

                            img {
                                margin-right: 5px;
                                width: 15px;
                                height: 15px;
                            }

                            &:hover {
                                background: rgb(32, 23, 71);
                                color: #fff;
                                border-color: rgb(32, 23, 71);
                            }
                        }
                    }
                }
            }
        }

        .spiderchart_main_container {
            background: #ffffff;
            border-radius: 10px;
            padding: 40px 40px 80px 40px;

            .spiderchart_content {
                .left_side {
                    width: 25%;
                }

                .right_side {
                    width: 100%;

                    .spiderchartContent {
                        // padding: 0 20px;
                        width: 85%;

                        .chartjs-render-monitor {
                            // margin-left: -33px;
                            // height: 530px !important;
                        }

                        .resilince-icon {
                            max-width: 200px;
                            left: 50p;
                            top: 0;
                        }

                        .opportunity-icon {
                            max-width: 200px;
                            top: 0;
                            right: 50px;
                        }

                        .inclusion-icon {
                            max-width: 200px;
                            right: 47%;
                            bottom: -40px;
                        }

                        .spiderchartContent {
                            width: 60%;
                        }

                        .Ind_Inv_Icon {
                            // z-index: 9999;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            position: absolute;
                            cursor: pointer !important;

                            img {
                                // width: 30px !important;
                                // height: 30px;
                                object-fit: contain;
                                margin-right: 0 !important;
                                margin-top: 30px !important;
                            }

                            span {
                                font-weight: bold;
                                font-size: 13px;
                                line-height: 16px;
                                text-align: center;
                                margin-top: 6px;
                                // margin-left: 20px !important;
                            }
                        }

                        .Indirect_Investment {
                            top: 0;
                            left: 0;
                        }
                    }
                }
            }
        }

        .parameters_block {
            background: #ffff;
            padding: 40px;

            .parameters_section_first {
                padding: 0 10px 60px 10px;
            }

            .parameters_section {
                padding: 40px 10px 60px 10px;
                border-top: 1px solid #d0d0d0;
            }

            .inner_parameter_block {
                .score_results_section {
                    .parameter_percent {
                        h3 {
                            margin: auto 0;
                            padding: 0 20px;
                        }

                        .right_side_flex {
                            margin: auto 0 auto auto;

                            .dot_with_text {
                                padding: 0 15px;
                                margin: auto 0 auto auto;
                            }
                        }
                    }
                }

                .progress_bar_section {
                    padding: 2rem 0;

                    .left_side_progress_bar {
                        width: 50%;
                    }

                    .right_side_progress_bar {
                        width: 50%;
                    }

                    .roi-progress-content {
                        display: flex;
                        margin-top: 30px;
                        justify-content: space-between;

                        &--left,
                        &--right {
                            font-size: 13px;
                            width: 45%;
                            font-weight: 600;
                        }

                        .roi-progress-content--left {
                            color: #1b1b1b;
                        }

                        .roi-progress-content--right {
                            width: 60%;
                            line-height: 1;

                            .bar-div {
                                span {
                                    color: #646363;
                                    width: 50px;
                                    margin-right: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .OverviewTitle {
            display: flex;
            align-items: center;
            margin-bottom: 30px;

            h2 {
                font-style: normal;
                font-weight: bold;
                font-size: 25px;
                line-height: 30px;
                color: #1b1b1b;
                margin-right: 10px;
                position: relative;
                width: 320px;
            }

            span {
                display: inline-block;
                border-bottom: 2px solid #1b1b1b;
                width: 100%;
                height: 2px;
            }
        }

        .CountryLine {
            align-items: flex-end;

            h2 {
                width: auto;
            }

            span {
                width: 110px;
            }
        }
    }

    .download-csv {
        button {
            border: 1px solid #1b1b1b;
            height: 36px;
            color: #1b1b1b;
            border-radius: 20px;
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            padding: 9px 11px;
            -webkit-transition: 0.3s ease;
            transition: 0.3s ease;

            &:first-child {
                margin-right: 10px;
            }

            &:hover {
                background: rgb(32, 23, 71);
                color: #fff;
                border-color: rgb(32, 23, 71);

                img {
                    filter: invert(1);
                }
            }
        }
    }
}

.pointer-event-none {
    pointer-events: none;
}

.title {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #1b1b1b;
}</style>
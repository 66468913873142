import BaseAPI from "@/api/index";

class SubscriptionAPIs {
  constructor() {
    this.axiosInstance = BaseAPI.getInstance();
  }

  getPurchaseHistory(type, teamId) {
    return this.axiosInstance.get(`/subscriptions/purchase-history?type=${type}&teamId=${teamId}`);
  }
}

export default SubscriptionAPIs;
<template>
  <div class="static">
    <div class="main-container pt-16 m-auto">
      <div class="top-heading-section">
        <div class="inner-top-heading-section flex justify-between">
          <h1 class="heading">Your IMM settings</h1>
          <p class="step-status">{{ step }}/4</p>
        </div>
      </div>
      <div class="settings-field-section">
        <component
          :is="'Step' + step"
          :fieldValue="currentStepData.fieldValue"
          :portfolioName="portfolioName"
          @input-typed="handleInput"
        />
      </div>
    </div>
    <div class="bottom-step-section flex justify-between">
      <div class="next-step-button">
        <button @click="previousStep()">Back</button>
      </div>
      <div class="flex gap-4">
        <div class="label-text">
          <p v-if="step == 4">
            Are you ready to check out your portfolio company's aggregated
            assessment results in your customized dashboard?
          </p>
          <p v-else>You can change your preferences at any time</p>
        </div>
        <div class="next-step-button">
        <button v-if="step == 4" @click="saveIMMPreferences">Finish</button>
        <button
          :class="{ 'in-active-button': !isNextButtonActive }"
          v-else
          @click="nextStep()"
        >
          Next Step
        </button>
      </div>
      </div>
    </div>
  </div>
</template>
<script>
import Step1 from "@/components/imm-components/preferences-steps/step1.vue";
import Step2 from "@/components/imm-components/preferences-steps/step2.vue";
import Step3 from "@/components/imm-components/preferences-steps/step3.vue";
import Step4 from "@/components/imm-components/preferences-steps/step4.vue";
import auth from "@/helpers/auth";
import axios from "axios";
import env from "@/../env";
import Cookies from "@/helpers/cookies";

export default {
  name: "SetUpPreferences",
  data() {
    return {
      step: 1,
      isNextButtonActive: false,
      activeStep: "Step1",
      user: null,
      portfolioName: null,
      preferencesArray: [],
      selectedLevel: null,
      fieldValues: [
        { sectionName: "step 1", fieldValue: "" },
        { sectionName: "step 2", fieldValue: "" },
        { sectionName: "step 3", fieldValue: "" },
        { sectionName: "step 4", fieldValue: "", portfolioName: "" },
      ],
    };
  },
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
  },
  computed: {
    currentStepData() {
      const stepData = this.fieldValues.find(
        (data) => data.sectionName === `step ${this.step}`
      );
      if (this.step === 4) {
        stepData.portfolioName = this.portfolioName;
      }
      return stepData;
    },
  },
  methods: {
    previousStep() {
      // First step, back to the landing page
      if (this.step === 1) {
        this.$router.push({ path: '/imm/imm-landing-page/investor' });
        return;
      }
      this.step--;
      this.isNextButtonActive = true;
    },
    nextStep() {
      this.step++;
      this.isNextButtonActive = false;
    },
    handleInput(value) {
      this.currentStepData.fieldValue = value;
      switch (this.step) {
        case 1:
          this.portfolioName = value.portfolioName;
          break;
        case 2:
          this.preferencesArray = value.preferencesArray;
          break;
        case 3:
          this.selectedLevel = value.selectedLevel;
          break;
      }

      this.isNextButtonActive = value.isStepActive;
    },
    async saveIMMPreferences() {
      const immInvestorPreference = {
        portfolioName: this.portfolioName,
        preferences: this.preferencesArray,
        level: this.selectedLevel,
      };
      const { status } = await axios.post(
        `${env.apiUrl}/imm-preferences`,
        immInvestorPreference,
        {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        }
      );
      if (status === 200) {
        this.$router.push("/imm/imm-landing-page/investor");
      }
    },
  },
  async mounted() {
    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });
    console.log(this.user);
  },
};
</script>
<style lang="scss" scoped>
.main-container {
  width: 616px;
  gap: 44px;

  .top-heading-section {
    margin-top: 128px;

    .inner-top-heading-section {
      .heading {
        font-family: Montserrat;
        font-size: 36px;
        font-weight: 700;
        line-height: 45px;
        letter-spacing: 0em;
        text-align: left;
        color: #2c2e35;
      }

      .step-status {
        font-family: Arial;
        font-size: 20px;
        font-weight: 700;
        line-height: 54px;
        letter-spacing: 0em;
        text-align: left;
        color: #9ea4ba;
      }
    }
  }

  .settings-field-section {
    margin-top: 16px;
  }
}

.bottom-step-section {
  width: 100%;
  height: 120px;
  // position: absolute;
  // bottom: 72px;
  padding: 36px 80px 36px 80px;
  border-top: 1px solid #e1e3e9;
  box-shadow: 0px -2px 20px 0px #636e801a;

  .label-text {
    max-width: 595px;
    font-family: Arial,serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #717686;
    text-wrap: wrap;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .next-step-button {
    button {
      width: 252px;
      height: 48px;
      padding: 12px 24px 12px 24px;
      border-radius: 8px;
      gap: 8px;
      background: #191b45;
      color: #ffffff;
    }

    .in-active-button {
      background: #e1e3e9;
      cursor: auto;
      pointer-events: none;
    }
  }
}
</style>

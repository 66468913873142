<template>
  <div class="container mx-auto p-4">
    <div v-if="files.length" class="rounded-lg border border-gray-200 relative overflow-x-auto">
      <table class="min-w-full bg-white">
        <thead>
          <tr>
            <th v-for="header in tableHeaders" :key="header" class="px-6 py-4 bg-gradient-to-b from-gray-100 to-gray-200 text-left text-xs font-extrabold text-gray-700 uppercase tracking-wider border-b border-gray-300 whitespace-nowrap">
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <tr v-for="file in files" :key="file.id" class="hover:bg-gray-100">
            <td class="px-6 py-4 text-sm text-gray-900 max-w-[300px] whitespace-nowrap overflow-hidden text-ellipsis" :title="file.name">{{ file.name }}</td>
            <td class="px-6 py-4 text-sm text-gray-900">{{ file.type }}</td>
            <td class="px-6 py-4 text-sm text-gray-900">{{ `${file?.uploadedBy?.organization?.name ? file?.uploadedBy?.organization?.name : '' }`}}</td>
            <td class="px-6 py-4 text-sm text-gray-900">{{ formatDate(file.createdAt) }}</td>
            <td class="px-6 py-4 text-sm text-gray-900">{{ `${file?.uploadedBy?.firstname ? file?.uploadedBy?.firstname : '' } ${file?.uploadedBy?.lastname ? file?.uploadedBy?.lastname : ''}` }}</td>
            <td class="px-6 py-4 text-sm text-gray-900">
              <button @click="viewFile(file)" class="px-3 py-1 bg-[#201747] text-white rounded-md hover:bg-[#201747] hover:opacity-80 opacity-80 transition-colors duration-200">
                View
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else class="text-center py-5 italic text-gray-600">
      No documents available
    </div>
  </div>
</template>

<script>
export default {
  name: 'FileTable',
  props: {
    files: {
      type: Array,
      required: true,
    },
  },
  computed: {
    tableHeaders() {
      return ['Document name', 'Document Type','Organization','Created At', 'Uploaded By', 'Actions'];
    },
  },
  methods: {
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
      return new Date(dateString).toLocaleDateString('en-US', options);
    },
    viewFile(file) {
      window. open(file.url, '_blank');
    },
  },
};
</script>
<style scoped>
td{
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid #ccc;
}
</style>
